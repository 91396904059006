import {NativeDateAdapter} from "@angular/material/core";
import {Injectable} from "@angular/core";


/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    override parse(value: any): Date | null {

        if ((typeof value === "string") && (value.indexOf("/") > -1)) {
            const str = value.split("/");

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return (month > 11 || date > 31) ? null : new Date(year, month, date);
        }
        const timestamp = typeof value === "number" ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

}
