<h2 mat-dialog-title>Manage Fund Investments</h2>
<mat-dialog-content>
    <h3>Partnership Investments</h3>
    <table mat-table [dataSource]="fundInvestments || []">
        <ng-container matColumnDef="assetId">
            <th mat-header-cell *matHeaderCellDef>Asset ID</th>
            <td mat-cell *matCellDef="let element">{{element.assetId}}</td>
        </ng-container>

        <ng-container matColumnDef="assetName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{getAssetNameById(element.assetId) | async}}</td>
        </ng-container>

        <ng-container matColumnDef="ownershipStake">
            <th mat-header-cell *matHeaderCellDef>Ownership Stake</th>
            <td mat-cell *matCellDef="let element">
                <input matInput
                       [formControl]="getFormControl(element.assetId, 'ownershipStake')"
                       type="number"
                       id="ownershipStake-{{element.assetId}}">
            </td>
        </ng-container>

        <ng-container matColumnDef="investmentDate">
            <th mat-header-cell *matHeaderCellDef>Investment Date</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field>
                    <input matInput
                           [max]="(reportDate$ | async)?.date"
                           [matDatepicker]="picker"
                           [formControl]="getFormControl(element.assetId, 'investmentDate')"
                           placeholder="Choose a date"
                           id="investmentDate-{{element.assetId}}">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button
                    mat-icon-button
                    color="primary"
                    data-test="remove-fund-investment"
                    (click)="removeFundInvestment(element.assetId)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="partnershipInvestmentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: partnershipInvestmentColumns"></tr>
    </table>

    <br/>

    <div class="header-container">
        <h3>Selectable Assets</h3>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Search Assets</mat-label>
            <input matInput (input)="applyFilter($event)" placeholder="Search by name">
        </mat-form-field>
    </div>

    <table mat-table [dataSource]="selectableAssetDataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
            <td mat-cell *matCellDef="let element">{{element.name | traceableFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
            <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
        </ng-container>

        <ng-container matColumnDef="currencyIso">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Currency</th>
            <td mat-cell *matCellDef="let element">{{element.currencyIso.code | codeFormat: codeTableCurrency | async}}</td>
        </ng-container>

        <ng-container matColumnDef="activityDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Description</th>
            <td mat-cell *matCellDef="let element">{{element.activityDescription | traceableFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="gic">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>GIC</th>
            <td mat-cell *matCellDef="let element">{{element.gic | traceableFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="add">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="addFundInvestment(element.id)">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="selectableAssetColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: selectableAssetColumns"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-stroked-button
        color="primary"
        mat-dialog-close
        data-test="save-button-bottom"
        (click)="save()"
        [disabled]="!areAllFormsValid()">
        Save
    </button>
    <button
        mat-stroked-button mat-dialog-close
        data-test="cancel-button">
        Close
    </button>
</mat-dialog-actions>
