import {createFeatureSelector, createSelector} from "@ngrx/store";
import {FundState} from "../fund.state";
import {EMPTY_CURRENT_OR_REALIZED_INVESTMENT_RECORD, EMPTY_PARTNERSHIP_INVESTMENT_RECORD, fundStoreFeatureKey} from "../fund.reducer";
import {PartnershipInvestmentRecord} from "../../models/partnership-investment-record";
import {selectSelectedFundReport, selectSelectedFundReportId} from "../fund-report/fund-report.selectors";
import {selectSelectedFundValuation, selectSelectedFundValuationId} from "../fund-valuation/fund-valuation.selectors";
import {selectSelectedSellerPosition, selectSelectedSellerPositionCalc} from "../../../deal/store/seller-position/seller-position.selectors";
import {EMPTY_TRACEABLE} from "../../../shared/model/traceable";
import {CurrentOrRealizedInvestmentRecord} from "../../models/current-or-realized-investment-record";

export const selectFundState = createFeatureSelector<FundState>(fundStoreFeatureKey);

export const selectFundForms = createSelector(
    selectFundState,
    (state) => state.fundForms
);

export const selectIsAnyFundFormInEditMode = createSelector(
    selectFundForms,
    (fundForms) => Object.values(fundForms).some((isEditable) => isEditable)
);

export const selectFunds = createSelector(
    selectFundState,
    (state) => state.funds.data
);

export const selectSelectedFund = createSelector(
    selectFundState,
    selectFundForms,
    (fundState, fundForms) => ({
        fund: fundState.selectedFund.data,
        fundDetailIsEditable: fundForms.fundDetailIsEditable
    })
);

export const selectBaselineFund = createSelector(
    selectFundState,
    (state) => state.baselineFund
);

export const selectHasBaselineFund = createSelector(
    selectBaselineFund,
    (state) => state.status === "LOADED"
);

export const selectSelectedFundId = createSelector(
    selectSelectedFund,
    (state) => state.fund.id
);

export const selectFundName = createSelector(
    selectSelectedFund,
    selectFundForms,
    (state, fundForms) => ({
        name: state.fund.name,
        fundNameIsEditable: fundForms.fundNameIsEditable
    })
);

export const selectFundCurrency = createSelector(
    selectSelectedFund,
    (state) => state.fund.currencyIso
);

export const selectSelectedFundNotes = createSelector(
    selectFundState,
    selectSelectedFund,
    (state, fund) => {
        const baseContext = `/funds/fund_id=${fund.fund.id}/`;
        return {
            notes: state.selectedFundNotes.data,
            baseContext
        };
    }
);

export const selectParamsForPartnershipInvestment = createSelector(
    selectSelectedFundId,
    selectSelectedFundReportId,
    selectSelectedFundValuationId,
    (fundId, fundReportId, fundValuationId) =>
        ({
            fundId,
            fundReportId,
            fundValuationId
        })
);

export const selectPartnershipInvestment = createSelector(
    selectFundState,
    (state) => state.partnershipInvestments
);

export const selectSortedInvestments = createSelector(
    selectPartnershipInvestment,
    (partnershipInvestment) => {
        const sortedRecords = [...partnershipInvestment.data.partnershipInvestmentRecords, ...partnershipInvestment.data.realizedInvestmentRecords];
        return sortedRecords.sort((a, b) => (a.company.text || "").localeCompare(b.company.text || ""));
    }
);

export const selectFundPartnershipInvestment = createSelector(
    selectPartnershipInvestment,
    selectSelectedFundReport,
    selectSelectedFundValuation,
    selectFundForms,
    (partnershipInvestment, fundReport, fundValuation, fundForms) => {

        const minimalColumns = ["company", "activityIndustry", "country", "gpIndication", "investmentDate"];
        const reportColumns = [...minimalColumns, "percentageHeld", "remainingCost", "gpNAV", "unrealizedTVPI", "totalTVPI", "percentageOfTotalNAV", "stabilityOfCFs"];
        const valuationColumns = [...minimalColumns,
            "percentageHeld", "remainingCost", "gpNAV", "unrealizedTVPI", "totalTVPI", "exitDateAssumption", "valuationType", "discountRate", "selectedBid", "discountToNAV",
            "percentageOfTotalBid", "percentageOfTotalNAV", "stabilityOfCFs", "cashflowLow", "cashflowBase", "cashflowHigh"
        ];
        const partnershipInvestmentTableColumns: string[] = fundValuation.data.closingDate.date ? valuationColumns : reportColumns;
        const performanceTotalColumns = ["company", "realizedCost", "realizedGains", "totalCost", "totalRealized"];
        const currentAndRealizedInvestmentColumns = [...minimalColumns,
            "effectiveExitDate", "percentageHeld", "holdingPeriodInYears", "realizedCost", "realizedGains", "totalCost", "totalRealized"
        ];

        const currentInvestments: CurrentOrRealizedInvestmentRecord[] = [];
        partnershipInvestment.data.currentInvestmentRecords.forEach(currentInvestmentRecord => currentInvestments.push(currentInvestmentRecord));
        const realizedInvestments: CurrentOrRealizedInvestmentRecord[] = [];
        partnershipInvestment.data.realizedInvestmentRecords.forEach(realizedInvestmentRecord => realizedInvestments.push(realizedInvestmentRecord));
        const partnershipInvestments: PartnershipInvestmentRecord[] = [];
        partnershipInvestment.data.partnershipInvestmentRecords.forEach(partnershipInvestmentRecord => partnershipInvestments.push(partnershipInvestmentRecord));

        const totalOfUnrealizedAssetsRow = {...EMPTY_PARTNERSHIP_INVESTMENT_RECORD};
        totalOfUnrealizedAssetsRow.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Total Of Unrealized Assets"};
        totalOfUnrealizedAssetsRow.gpNAV = partnershipInvestment.data.totalOfUnrealizedAssets;
        partnershipInvestments.push(totalOfUnrealizedAssetsRow);

        const netCurrentAssetsRow = {...EMPTY_PARTNERSHIP_INVESTMENT_RECORD};
        netCurrentAssetsRow.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Net Current Assets"};
        netCurrentAssetsRow.gpNAV = partnershipInvestment.data.netCurrentAssets;
        netCurrentAssetsRow.cashflowLow = partnershipInvestment.data.netCurrentAssetsLow;
        netCurrentAssetsRow.cashflowBase = partnershipInvestment.data.netCurrentAssetsBase;
        netCurrentAssetsRow.cashflowHigh = partnershipInvestment.data.netCurrentAssetsHigh;
        netCurrentAssetsRow.exitDateAssumption = partnershipInvestment.data.netCurrentAssetsDate;
        partnershipInvestments.push(netCurrentAssetsRow);

        const gpCarryReserveRow = {...EMPTY_PARTNERSHIP_INVESTMENT_RECORD};
        gpCarryReserveRow.company = {...EMPTY_TRACEABLE, source: "manually added", text: "GP Carry Reserve"};
        gpCarryReserveRow.gpNAV = partnershipInvestment.data.gpCarryReserve;
        partnershipInvestments.push(gpCarryReserveRow);

        const bidPriceAdjustmentRow = {...EMPTY_PARTNERSHIP_INVESTMENT_RECORD};
        bidPriceAdjustmentRow.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Bid Price Adjustment"};
        bidPriceAdjustmentRow.selectedBid = partnershipInvestment.data.bidPriceAdjustment;
        partnershipInvestments.push(bidPriceAdjustmentRow);

        const totalRow = {...EMPTY_PARTNERSHIP_INVESTMENT_RECORD};
        totalRow.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Total"};
        totalRow.remainingCost = partnershipInvestment.data.totalRemainingCost;
        totalRow.gpNAV = partnershipInvestment.data.totalGpNAV;
        totalRow.unrealizedTVPI = partnershipInvestment.data.totalUnrealizedTVPI;
        totalRow.totalTVPI = partnershipInvestment.data.totalTVPI;
        totalRow.selectedBid = partnershipInvestment.data.totalBid;
        totalRow.discountToNAV = partnershipInvestment.data.totalDiscountToNAV;
        totalRow.percentageOfTotalBid = partnershipInvestment.data.totalBidPercentage;
        totalRow.percentageOfTotalNAV = partnershipInvestment.data.totalNavPercentage;
        totalRow.cashflowLow = partnershipInvestment.data.totalExitCashflowsLow;
        totalRow.cashflowBase = partnershipInvestment.data.totalExitCashflowsBase;
        totalRow.cashflowHigh = partnershipInvestment.data.totalExitCashflowsHigh;
        partnershipInvestments.push(totalRow);

        const performanceTotalCurrentInvestments = {...EMPTY_CURRENT_OR_REALIZED_INVESTMENT_RECORD};
        performanceTotalCurrentInvestments.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Current Investments Total"};
        performanceTotalCurrentInvestments.realizedGains = partnershipInvestment.data.currentInvestmentsTotals.totalRealizedGains;
        performanceTotalCurrentInvestments.realizedCost = partnershipInvestment.data.currentInvestmentsTotals.totalRealizedCost;
        performanceTotalCurrentInvestments.totalRealized = partnershipInvestment.data.currentInvestmentsTotals.totalRealized;
        performanceTotalCurrentInvestments.totalCost = partnershipInvestment.data.currentInvestmentsTotals.totalCost;

        const performanceTotalRealizedInvestments = {...EMPTY_CURRENT_OR_REALIZED_INVESTMENT_RECORD};
        performanceTotalRealizedInvestments.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Realized Investments Total"};
        performanceTotalRealizedInvestments.realizedGains = partnershipInvestment.data.realizedInvestmentsTotals.totalRealizedGains;
        performanceTotalRealizedInvestments.realizedCost = partnershipInvestment.data.realizedInvestmentsTotals.totalRealizedCost;
        performanceTotalRealizedInvestments.totalRealized = partnershipInvestment.data.realizedInvestmentsTotals.totalRealized;
        performanceTotalRealizedInvestments.totalCost = partnershipInvestment.data.realizedInvestmentsTotals.totalCost;

        const performanceTotalCurrentAndRealizedInvestments = {...EMPTY_CURRENT_OR_REALIZED_INVESTMENT_RECORD};
        performanceTotalCurrentAndRealizedInvestments.company = {...EMPTY_TRACEABLE, source: "manually added", text: "Total"};
        performanceTotalCurrentAndRealizedInvestments.realizedGains = partnershipInvestment.data.currentAndRealizedInvestmentsTotals.totalRealizedGains;
        performanceTotalCurrentAndRealizedInvestments.realizedCost = partnershipInvestment.data.currentAndRealizedInvestmentsTotals.totalRealizedCost;
        performanceTotalCurrentAndRealizedInvestments.totalRealized = partnershipInvestment.data.currentAndRealizedInvestmentsTotals.totalRealized;
        performanceTotalCurrentAndRealizedInvestments.totalCost = partnershipInvestment.data.currentAndRealizedInvestmentsTotals.totalCost;

        const performanceTotals: CurrentOrRealizedInvestmentRecord[] = [];
        performanceTotals.push(performanceTotalCurrentInvestments);
        performanceTotals.push(performanceTotalRealizedInvestments);

        return {
            tableDatasource: partnershipInvestments,
            tableColumns: partnershipInvestmentTableColumns,
            partnershipInvestment: partnershipInvestment.data,
            partnershipInvestmentIsEditable: fundForms.partnershipInvestmentIsEditable,
            fundReportDate: fundReport.fundReport.data.reportDate,
            fundClosingDate: fundValuation.data.closingDate,
            currentInvestments,
            realizedInvestments,
            currentAndRealizedInvestmentColumns,
            performanceTotals,
            performanceTotalColumns,
            performanceTotalCurrentAndRealizedInvestments
        };
    }
);

export const selectFundSellerPositionIsEditable = createSelector(
    selectFundForms,
    (forms) => forms.fundSellerPositionIsEditable
);

export const selectFundSellerPosition = createSelector(
    selectSelectedSellerPosition,
    selectSelectedSellerPositionCalc,
    selectFundSellerPositionIsEditable,
    (sellerPosition, sellerPositionCalc, fundSellerPositionIsEditable) => ({
        sellerPosition,
        sellerPositionCalc,
        fundSellerPositionIsEditable
    })
);

export const selectUndrawnValuationNotes = createSelector(
    selectSelectedFundNotes,
    (notes) => {
        const baseContext = notes.baseContext + "undrawnValuation/";
        return {
            notes: notes.notes.filter(n => baseContext === n.context),
            baseContext
        };
    }
);

export const selectTreemapDataFromPartnershipInvestment = createSelector(
    selectPartnershipInvestment,
    (partnershipInvestment) => partnershipInvestment.data.partnershipInvestmentRecords.map(record => ({
        assetId: record.assetId,
        fundInvestmentId: record.fundInvestmentId,
        assetValuationId: record.assetValuationId,
        name: record.company,
        navMultiple: record.navMultiple,
        percentageOfTotalNAV: record.percentageOfTotalNAV,
        gpNAV: record.gpNAV,
        remainingCost: record.remainingCost,
        realizedTVPI: record.realizedTVPI,
        unrealizedTVPI: record.unrealizedTVPI,
        discountToNAV: record.discountToNAV,
        totalTVPI: record.totalTVPI
    }))
);
