<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Financial History</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="(selectedAssetFinancialHistory$ | async) as assetHistory">
        <mat-card-subtitle>
            <valumize-info-panel *ngIf="assetHistory.status === 'ERROR'" [appearance]="'ERROR'" [message]="assetHistory.errorMessage"></valumize-info-panel>
            <valumize-info-panel *ngIf="assetHistory.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Financial History successfully saved'"></valumize-info-panel>
            <mat-progress-spinner *ngIf="assetHistory.status === 'LOADING'"></mat-progress-spinner>
        </mat-card-subtitle>

        <valumize-asset-fin-data-history-table
            [selectedAssetFinancialPerformanceData]="selectedAssetFinancialHistoryData"
            [baselineAssetFinancialPerformanceData]="baselineAssetFinancialHistoryData"
            [isEditable]="isEditable"
            [isDataset]="isDataset"
            [isEditDisabled]="(isEditDisabled$ | async)"
            (recordsToSave)="onSave($event)"
            (recordsForCalc)="onCalc($event)"
            (triggerEdit)="onEdit()"
            (triggerCancel)="onCancel()">
        </valumize-asset-fin-data-history-table>
    </ng-container>
</mat-card>
