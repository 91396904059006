import {Directive, ElementRef, HostListener, Inject, Input, OnChanges, Renderer2} from "@angular/core";
import {WINDOW} from "../../model/login/window-token";
import {AuthorizePathService} from "./authorize-path.service";

@Directive({
    selector: "[valumizePreauthorizedLink]"
})
export class PreauthorizedLinkDirective implements OnChanges {
    @Input() href?: string;

    constructor(private readonly authorizePathService: AuthorizePathService,
                private readonly elementRef: ElementRef,
                private readonly renderer: Renderer2,
                @Inject(WINDOW) private readonly window: Window) {
    }

    ngOnChanges() {
        if (this.href) {
            this.renderer.setAttribute(this.elementRef.nativeElement, "href", this.href);
        } else {
            this.renderer.removeAttribute(this.elementRef.nativeElement, "href");
        }
    }

    @HostListener("click", ["$event"])
    preauthorizeAndOpenPath(event: MouseEvent) {
        // prevent default click event on anchor
        event.preventDefault();
        // sets a cookie for the link that the browser uses
        // to open the link in a new tab/window

        if (!this.href) {
            throw new Error("valumizePreauthorizedLink directive used without a `href` attribute");
        } else {
            this.authorizePathService.signPath(this.href).subscribe(() =>
                this.window.open(this.href)?.focus()
            );
        }
    }
}
