/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {DealActions, SellerActions, SellerListActions} from "../deal.actions";
import {catchError, exhaustMap, map, of, switchMap} from "rxjs";
import {Store} from "@ngrx/store";
import {EMPTY_SELLER} from "../deal.reducer";
import {SellerService} from "../../services/seller.service";
import {Router} from "@angular/router";

export const loadSellerList = createEffect(
    (actions$ = inject(Actions), sellerService = inject(SellerService)) => {
        return actions$.pipe(
            ofType(SellerListActions.load),
            exhaustMap(() =>
                sellerService.getSellers().pipe(
                    map((sellers) => SellerListActions.loadsuccess({sellers})),
                    catchError((error: { message: string }) =>
                        of(SellerListActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadSeller = createEffect(
    (actions$ = inject(Actions), sellerService = inject(SellerService)) => {
        return actions$.pipe(
            ofType(SellerActions.load),
            exhaustMap((action) => {
                const sellerId = action.sellerId;
                return sellerService.getSeller(sellerId).pipe(
                    switchMap((seller) => of(
                        SellerActions.loadsuccess({seller}),
                        DealActions.savesellerondeal({sellerId})
                    )),
                    catchError((error: { message: string }) =>
                        of(SellerActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const createSeller = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), router = inject(Router), sellerService = inject(SellerService)) => {
        return actions$.pipe(
            ofType(SellerActions.create),
            exhaustMap((action) => {
                return sellerService.saveSeller({
                        ...EMPTY_SELLER,
                        ...{
                            name: {
                                ...EMPTY_SELLER.name,
                                text: action.sellerName
                            }
                        }
                    }
                ).pipe(
                    switchMap((seller) => of(
                        SellerActions.loadsuccess({seller}),
                        seller.id
                            ? DealActions.savesellerondeal({sellerId: seller.id})
                            : SellerActions.loaderror({errorMsg: "Seller could not be saved, please try again"})
                    )),
                    catchError((error: { message: string }) =>
                        of(SellerActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);
