import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectBaselineFund, selectIsAnyFundFormInEditMode, selectSelectedFund} from "../../store/fund/fund.selectors";
import {FundDetailActions} from "../../store/fund.actions";
import {FormBuilder} from "@angular/forms";
import {map, Subscription} from "rxjs";
import {CodeTableEnum} from "../../../shared/model/code";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {noWhitespaceValidator, numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {DateUtil} from "../../../shared/utils/date-util";
import {EMPTY_CODE, TraceableCode} from "../../../shared/model/traceable";
import {EMPTY_FUND} from "../../store/fund.reducer";
import {Fund} from "../../models/fund";
import {selectSelectedFundReportReportDate} from "../../store/fund-report/fund-report.selectors";

@Component({
    selector: "valumize-fund-details",
    templateUrl: "./fund-details.component.html",
    styleUrls: ["./fund-details.component.scss"]
})
export class FundDetailsComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    codeTableStage = CodeTableEnum.SHARED_STAGES;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;
    codeTableRegion = CodeTableEnum.SHARED_REGIONS;
    codeTableFeeCalculationMethod = CodeTableEnum.FUND_FEECALCULATIONMETHOD;
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;

    fund$ = this.store.select(selectSelectedFund);
    baselineFund: Fund = EMPTY_FUND;
    reportDate$ = this.store.select(selectSelectedFundReportReportDate);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);
    persistedRegions: TraceableCode[] = [];
    isDataset = false;

    fundDetailForm = this.formBuilder.group({
        vintageYear: this.formBuilder.control<Date | null>(null),
        purchaseYear: this.formBuilder.control<Date | null>(null),
        endYear: this.formBuilder.control<Date | null>(null),
        stage: this.formBuilder.control<string>("", {nonNullable: true}),
        regions: this.formBuilder.control<string[]>([], {nonNullable: true}),
        currencyIso: this.formBuilder.control<string>("", {nonNullable: true, validators: noWhitespaceValidator()}),
        country: this.formBuilder.control<string | null>(null),
        size: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        mainFundSize: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        managementFeeCalculation: this.formBuilder.control<string>("", {nonNullable: true}),
        managementFee: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        carry: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        hurdleRate: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        secondaryManagementFee: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        secondaryCarry: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        secondaryHurdleRate: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]})
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.subscriptions.push(this.store.select(selectSelectedFund).pipe(
            map(fund => {
                this.persistedRegions = fund.fund.regions;
                this.fundDetailForm.patchValue({
                    vintageYear: DateUtil.toJsDate(fund.fund.vintageYear.date),
                    purchaseYear: DateUtil.toJsDate(fund.fund.purchaseYear.date),
                    endYear: DateUtil.toJsDate(fund.fund.endYear.date),
                    stage: fund.fund.stage.code,
                    regions: fund.fund.regions.map(r => r.code ?? ""),
                    currencyIso: fund.fund.currencyIso.code,
                    country: fund.fund.country.code,
                    size: DecimalFormatPipe.transformFromMillionsNum(fund.fund.size.amount ?? undefined),
                    mainFundSize: DecimalFormatPipe.transformFromMillionsNum(fund.fund.mainFundSize.amount ?? undefined),
                    managementFeeCalculation: fund.fund.managementFeeCalculationMethod.code,
                    managementFee: DecimalFormatPipe.transformFractionToPercent(fund.fund.managementFee.fraction ?? undefined),
                    carry: DecimalFormatPipe.transformFractionToPercent(fund.fund.carry.fraction ?? undefined),
                    hurdleRate: DecimalFormatPipe.transformFractionToPercent(fund.fund.hurdleRate.fraction ?? undefined),
                    secondaryManagementFee: DecimalFormatPipe.transformFractionToPercent(fund.fund.secondaryManagementFeeRate.fraction ?? undefined),
                    secondaryCarry: DecimalFormatPipe.transformFractionToPercent(fund.fund.secondaryCarryRate.fraction ?? undefined),
                    secondaryHurdleRate: DecimalFormatPipe.transformFractionToPercent(fund.fund.secondaryHurdleRate.fraction ?? undefined)
                });
            })
        ).subscribe());

        this.subscriptions.push(
            this.store.select(selectBaselineFund).pipe(map((baselineFund) => {
                if (baselineFund.status === "LOADED") {
                    this.isDataset = true;
                    this.baselineFund = baselineFund.data;
                } else {
                    this.isDataset = false;
                }
            })).subscribe());
    }

    save = () => {
        if (this.fundDetailForm.get("country")?.value?.toString().trim().length === 0) {
            this.fundDetailForm.get("country")?.setValue(null);
        }

        const formValuesToSave = {...this.fundDetailForm.getRawValue()};
        const vintageYear = DateUtil.toIsoDate(formValuesToSave.vintageYear);
        const purchaseYear = DateUtil.toIsoDate(formValuesToSave.purchaseYear);
        const endYear = DateUtil.toIsoDate(formValuesToSave.endYear);
        const regions = this.updateRegionsArray();
        this.store.dispatch(FundDetailActions.save({...formValuesToSave, vintageYear, purchaseYear, endYear, regions}));
    };

    cancel = () => {
        this.ngOnInit();
        this.store.dispatch(FundDetailActions.cancel());
    };

    edit = () => this.store.dispatch(FundDetailActions.edit());

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private updateRegionsArray(): TraceableCode[] {
        return this.fundDetailForm.controls.regions.value
            .map(regionsFormValue => {
                const existingRegion = this.persistedRegions.find(region => region.code === regionsFormValue);
                return existingRegion || {...EMPTY_CODE, code: regionsFormValue};
            });
    }
}
