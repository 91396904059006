import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {selectDealCompaniesForTreemap, selectDealCompaniesOverview, selectHasDealCompanies, selectSelectedDealId, selectShowDealGraphs} from "../../store/deal/deal.selectors";
import {Store} from "@ngrx/store";
import {CodeTableEnum} from "../../../shared/model/code";
import {MatSort} from "@angular/material/sort";
import {map, Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {DealCompanyRecord} from "../../models/deal-company-record";
import {TraceableCode, TraceableDate, TraceableMoney, TraceablePercent, TraceableText} from "../../../shared/model/traceable";
import {MatPaginator} from "@angular/material/paginator";
import {TraceableFormatPipe} from "../../../shared/pipes/traceable-format/traceable-format.pipe";

@Component({
    selector: "valumize-deal-companies",
    templateUrl: "./deal-companies.component.html",
    styleUrls: ["./deal-companies.component.scss"]
})
export class DealCompaniesComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    dealId$ = this.store.select(selectSelectedDealId);
    dealCompany$ = this.store.select(selectDealCompaniesOverview);
    groupedDealCompanies$ = this.store.select(selectDealCompaniesForTreemap(this.traceableFormatPipe));
    showDealGraphs$ = this.store.select(selectShowDealGraphs);
    hasDealCompanies$ = this.store.select(selectHasDealCompanies);

    dealCompaniesDataSource: MatTableDataSource<DealCompanyRecord> = new MatTableDataSource();
    dealCompaniesColumns: string[] = [];
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableGpIndication = CodeTableEnum.ASSET_GPINDICATION;
    panelOpenState = true;
    treemapPanelOpenState = true;
    bubbleChartPanelOpenState = true;
    @ViewChild("dealCompaniesSort") dealCompaniesSort = new MatSort();
    @ViewChild("dealCompaniesPaging") dealCompaniesPaging!: MatPaginator;

    constructor(private readonly store: Store, private readonly traceableFormatPipe: TraceableFormatPipe) {
    }

    ngOnInit() {
        this.subscription = this.store.select(selectDealCompaniesOverview).pipe(
            map(dealCompanies => {
                this.dealCompaniesDataSource = new MatTableDataSource<DealCompanyRecord>(dealCompanies.tableDatasource);
                this.dealCompaniesColumns = dealCompanies.tableColumns;

                this.dealCompaniesDataSource.paginator = this.dealCompaniesPaging;
                this.dealCompaniesDataSource.sort = this.dealCompaniesSort;
                this.dealCompaniesDataSource.sortingDataAccessor = (row: DealCompanyRecord, columnName: string): string | number => {
                    if (["fundName", "company"].includes(columnName)) {
                        return (row[columnName as keyof DealCompanyRecord] as TraceableText).text as string;
                    } else if (["country", "gpIndication"].includes(columnName)) {
                        return (row[columnName as keyof DealCompanyRecord] as TraceableCode).code as string;
                    } else if (["investmentDate", "exitDateAssumption"].includes(columnName)) {
                        return (row[columnName as keyof DealCompanyRecord] as TraceableDate).date as string;
                    } else if (["remainingCost", "gpNAV", "selectedBid", "cashflowLow", "cashflowBase", "cashflowHigh"].includes(columnName)) {
                        return (row[columnName as keyof DealCompanyRecord] as TraceableMoney).amount as number;
                    } else if (["percentagOfTotalNAV", "percentagOfTotalBid", "percentageOfTotalCfLow", "percentageOfTotalCfBase", "percentageOfTotalCfHigh"]) {
                        return (row[columnName as keyof DealCompanyRecord] as TraceablePercent).fraction as number;
                    }
                    return row[columnName as keyof DealCompanyRecord] as string;
                };
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
