import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Asset} from "../models/asset/asset";
import {AssetApiService} from "../generated-sources/api";
import {FinancialPerformanceRecord} from "../models/asset/financial-performance-record";
import {Shareholder} from "../models/asset/shareholder";
import {GptApiService} from "../../import/generated-sources/api/gpt";

@Injectable({
    providedIn: "root"
})
export class AssetService {

    constructor(private readonly assetApiService: AssetApiService,
                private readonly gptApiService: GptApiService) {
    }

    getAssets(dataset?: string): Observable<Asset[]> {
        return this.assetApiService.getAssets(dataset);
    }

    getAsset(id: number): Observable<Asset> {
        return this.assetApiService.getAsset(id);
    }

    saveAsset(asset: Asset, id?: number, dataset?: string) {
        return (id) ? this.assetApiService.updateAsset(id, asset) : this.assetApiService.createAsset(asset, dataset);
    }

    getAssetFinancialHistory(assetId: number): Observable<FinancialPerformanceRecord[]> {
        return this.assetApiService.getHistoryForAsset(assetId);
    }

    saveAssetFinancialHistory(assetId: number, historyRecords: FinancialPerformanceRecord[]) {
        return this.assetApiService.updateAssetHistory(assetId, historyRecords);
    }

    calcAssetFinancialHistory(assetId: number, historyRecords: FinancialPerformanceRecord[]) {
        return this.assetApiService.calculateAssetHistory(assetId, historyRecords);
    }

    getAllShareholders(assetId: number): Observable<Shareholder[]> {
        return this.assetApiService.getAllShareholdersForAsset(assetId);
    }

    getShareholdersForDate(assetId: number, date: string): Observable<Shareholder[]> {
        return this.assetApiService.getShareholdersForAssetAndDate(assetId, date);
    }

    saveShareholdersForDate(assetId: number, date: string, shareholders: Shareholder[]) {
        return this.assetApiService.updateShareholdersForAsset(assetId, date, shareholders);
    }

    getGptCompanyDescription(companyName: string) {
        return this.gptApiService.getGPTCompanyDescription(companyName);
    }

    getGptEndMarkets(companyName: string) {
        return this.gptApiService.getGPTEndMarketsDescription(companyName);
    }

    getGptSwotAnalysis(companyName: string) {
        return this.gptApiService.getGPTSwotAnalysis(companyName);
    }
}
