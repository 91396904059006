/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {
    FundInvestmentActions,
    FundReportActions,
    FundValuationActions,
    FundValuationAggregatedCashflowAction,
    FundValuationScenarioActions,
    PartnershipInvestmentActions,
    ReturnSummaryActions
} from "../fund.actions";
import {FundInvestmentService} from "../../services/fund-investment.service";
import {Store} from "@ngrx/store";
import {FundReport} from "../../models/fund-report";
import {selectSelectedFundReport, selectSelectedFundReportId} from "../fund-report/fund-report.selectors";
import {FundReportService} from "../../services/fund-report.service";
import {selectParamsForPartnershipInvestment, selectSelectedFundId} from "../fund/fund.selectors";
import {selectSelectedFundValuation, selectSelectedFundValuationId, selectSelectedFundValuationScenarios} from "../fund-valuation/fund-valuation.selectors";
import {FundValuationService} from "../../services/fund-valuation.service";
import {FundValuation} from "../../models/fund-valuation";
import {SellerPositionActions} from "../../../deal/store/deal.actions";
import {EMPTY_SELLER_POSITION} from "../../../deal/store/deal.reducer";
import {selectSelectedDealId} from "../../../deal/store/deal/deal.selectors";


// Todo: Check
export const loadFundInvestment = createEffect(
    (actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(
                FundInvestmentActions.load,
                FundInvestmentActions.loadforasset
            ),
            exhaustMap((action) => {
                return fundInvestmentService.getFundInvestment(action.fundId, action.fundReportId, action.fundInvestmentId).pipe(
                    switchMap((fundInvestment) => of(
                        (action.type === FundInvestmentActions.load.type) ?
                            FundInvestmentActions.loadsuccess({fundInvestment}) :
                            FundInvestmentActions.loadforassetsuccess({fundInvestment}),
                    )),
                    catchError((error: { message: string }) =>
                        of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

// TODO: Why is GP VAl loaded here???
export const updateFundInvestment = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.save),
            exhaustMap((action) => {
                if (action.fundInvestment.id) {
                    return fundInvestmentService.updateFundInvestment(action.fundId, action.fundReportId, action.fundInvestment.id, action.fundInvestment).pipe(
                        switchMap((fundInvestment) => of(
                            FundInvestmentActions.savedloadsuccess({fundInvestment}),
                            // fundInvestment.id
                            //     ? GeneralPartnerValuationActions.load({fundInvestmentId: fundInvestment.id})
                            //     : GeneralPartnerValuationActions.loaderror({errorMsg: "Fund investment could not be saved, please try again"})
                        )),
                        catchError((error: { message: string }) =>
                            of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundInvestmentActions.loaderror({errorMsg: "Fund investment could not be found"}));
                }
            })
        );
    },
    {functional: true}
);


export const loadFundInvestments = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.loadall),
            exhaustMap((action) =>
                fundInvestmentService.getInvestmentsForFundReport(action.fundId, action.fundReportId).pipe(
                    map((fundInvestments) =>
                        FundInvestmentActions.loadallsuccess({fundInvestments, fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})),
                    catchError((error: { message: string }) =>
                        of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const loadPartnershipInvestmentAfterFundInvestment = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.loadallsuccess),
            map((action) =>
                (!!action.fundReportId && !!action.fundId)
                    ? PartnershipInvestmentActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                    : PartnershipInvestmentActions.loaderror({errorMsg: "Fund or Fund Report could not be found"})
            )
        );
    },
    {functional: true}
);

export const saveFundInvestments = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.saveall),
            withLatestFrom(store$.select(selectSelectedFundValuationId)),
            exhaustMap(([action, selectedFundValuationId]) => {
                if (action.fundId) {
                    return fundInvestmentService.updateFundInvestments(
                        action.fundId,
                        action.fundReportId,
                        action.fundInvestments
                    ).pipe(
                        map((fundInvestments) =>
                            FundInvestmentActions.saveallsuccess({
                                fundInvestments,
                                fundId: action.fundId,
                                fundReportId: action.fundReportId,
                                fundValuationId: selectedFundValuationId
                            })),
                        catchError((error: { message: string }) =>
                            of(FundInvestmentActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundInvestmentActions.loaderror({errorMsg: "Fund could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadPartnershipInvestmentAfterFundInvestmentSavedSuccess = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundInvestmentActions.saveallsuccess),
            map((action) =>
                (!!action.fundReportId && !!action.fundId)
                    ? PartnershipInvestmentActions.loadafteredit({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                    : PartnershipInvestmentActions.loaderror({errorMsg: "Fund or Fund Report could not be found"})
            )
        );
    },
    {functional: true}
);

export const loadPartnershipInvestment = createEffect(
    (actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(
                PartnershipInvestmentActions.load,
                PartnershipInvestmentActions.loadafteredit,
                PartnershipInvestmentActions.loadforasset
            ),
            exhaustMap((action) => {
                return fundInvestmentService.getPartnershipInvestment(action.fundId, action.fundReportId, action.fundValuationId).pipe(
                    map((partnershipInvestment) =>
                            (action.type === PartnershipInvestmentActions.load.type) ?
                                PartnershipInvestmentActions.loadsuccess({partnershipInvestment}) :
                                (action.type === PartnershipInvestmentActions.loadforasset.type) ?
                                    PartnershipInvestmentActions.loadforassetsuccess({partnershipInvestment}) :
                                    PartnershipInvestmentActions.loadaftereditsuccess({partnershipInvestment})
                    ),
                    catchError((error: { message: string }) =>
                        of(PartnershipInvestmentActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadSellerPositionAfterPartnerInvAES = createEffect(
    (store$ = inject(Store),actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.loadaftereditsuccess),
            withLatestFrom(store$.select(selectSelectedFundValuationId), store$.select(selectSelectedDealId)),
            map(([action, fundValuationId, dealId]) =>
                (!!dealId && !!fundValuationId) ?
                    SellerPositionActions.load({dealId, fundValuationId}) :
                    SellerPositionActions.loadsuccess({sellerPosition: EMPTY_SELLER_POSITION}))
        );
    },
    {functional: true}
);

export const loadAllFundCashflowsParallelAfterPartnerInvAES = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.loadaftereditsuccess),
            map(() => FundValuationAggregatedCashflowAction.loadallparallel())
        );
    },
    {functional: true}
);

export const calcReturnSummaryAfterPartnershipInvestmentLoadedAES = createEffect(
    (actions$ = inject(Actions), store$ = inject(Store)) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.loadaftereditsuccess),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            map(([action, fundId, fundValuationId]) => {
                return fundId && fundValuationId
                    ? ReturnSummaryActions.calc({ fundId, fundValuationId })
                    : ReturnSummaryActions.calcerror({ errorMsg: "Fund or Valuation ID missing" });
            })
        );
    },
    { functional: true }
);

// (!) PartnershipInvestmentActions.save is used to save on FundReport, FundValuation and FundValuationScenarios!
export const savePartnershipInvestmentOnFundReport = createEffect(
    (
        store$ = inject(Store),
        actions$ = inject(Actions),
        fundReportService = inject(FundReportService),
    ) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReport)),
            exhaustMap(([action, selectedFundId, selectedFundReport]) => {
                const fundReport: FundReport = selectedFundReport.fundReport.data;
                if (selectedFundId) {
                    return fundReportService.save(
                        selectedFundId,
                        {
                            ...fundReport,
                            ...{
                                gpCarryReserve: {
                                    ...fundReport.gpCarryReserve,
                                    amount: action.partnershipInvestment.gpCarryReserve.amount
                                },
                                netCurrentAssets: {
                                    ...fundReport.netCurrentAssets,
                                    amount: action.partnershipInvestment.netCurrentAssets.amount
                                },
                                netCurrentAssetsDate: {
                                    ...fundReport.netCurrentAssetsDate,
                                    date: action.partnershipInvestment.netCurrentAssetsDate.date
                                },
                                totalNav: {
                                    ...fundReport.totalNav,
                                    amount: action.partnershipInvestment.totalGpNAV.amount
                                },
                                totalRemainingCosts: {
                                    ...fundReport.totalRemainingCosts,
                                    amount: action.partnershipInvestment.totalRemainingCost.amount
                                },
                                totalTVPI: {
                                    ...fundReport.totalTVPI,
                                    factor: action.partnershipInvestment.totalTVPI.factor
                                },
                                unrealizedTVPI: {
                                    ...fundReport.unrealizedTVPI,
                                    factor: action.partnershipInvestment.totalUnrealizedTVPI.factor
                                },
                                totalOfUnrealizedAssets: {
                                    ...fundReport.totalOfUnrealizedAssets,
                                    amount: action.partnershipInvestment.totalOfUnrealizedAssets.amount
                                }
                            }
                        })
                        .pipe(
                            map((fundReportResponse) =>
                                    PartnershipInvestmentActions.saveandloadonfundreportsuccess({fundReport: fundReportResponse})
                            ),
                            catchError((error: { message: string }) =>
                                of(FundReportActions.loaderror({errorMsg: error.message}))
                            )
                        );
                } else {
                    return of(FundReportActions.loaderror({errorMsg: "Fund could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const savePartnershipInvestmentOnFundValuation = createEffect(
    (
        store$ = inject(Store),
        actions$ = inject(Actions),
        fundValuationService = inject(FundValuationService)
    ) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.save, ReturnSummaryActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReportId), store$.select(selectSelectedFundValuation)),
            exhaustMap(([action, selectedFundId, selectedFundReportId, selectedFundValuation]) => {
                const fundValuation: FundValuation = selectedFundValuation.data;
                if (!fundValuation?.id) {
                    return of();
                }
                if (!!selectedFundId && !!selectedFundReportId) {
                    return fundValuationService.save(
                        selectedFundId,
                        {
                            ...fundValuation,
                            ...{
                                bidPriceAdjustment: {
                                    ...fundValuation.bidPriceAdjustment,
                                    amount: action.partnershipInvestment.bidPriceAdjustment.amount
                                },
                                finalBidPrice: {
                                    ...fundValuation.finalBidPrice,
                                    amount: action.partnershipInvestment.totalBid.amount
                                }
                            }
                        })
                        .pipe(
                            map((fundValuationResponse) =>
                                    PartnershipInvestmentActions.saveandloadonfundvaluationsuccess({fundValuation: fundValuationResponse}),
                            ),
                            catchError((error: { message: string }) =>
                                of(FundValuationActions.loaderror({errorMsg: error.message}))
                            )
                        );
                } else {
                    return of(FundValuationActions.loaderror({errorMsg: "No report for this fund available, please add a report first"}));
                }
            })
        );
    },
    {functional: true}
);

export const savePartnershipInvestmentOnFundValuationScenarios = createEffect(
    (
        store$ = inject(Store),
        actions$ = inject(Actions),
        fundValuationService = inject(FundValuationService)
    ) => {
        return actions$.pipe(
            ofType(PartnershipInvestmentActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundValuationId), store$.select(selectSelectedFundValuationScenarios)),
            exhaustMap(([action, selectedFundId, selectedFundValuationId, selectedFundValuationScenarios]) => {
                if (!selectedFundId || !selectedFundValuationId) {
                    return of(FundValuationScenarioActions.loaderror({errorMsg: "No valuation for this fund available, please add a valuation first"}));
                }

                const updatedScenarios = selectedFundValuationScenarios.map(scenario => {
                    let amount = null;
                    switch (scenario.scenario.code) {
                        case "LOW":
                            amount = action.partnershipInvestment.netCurrentAssetsLow.amount;
                            break;
                        case "BASE":
                            amount = action.partnershipInvestment.netCurrentAssetsBase.amount;
                            break;
                        case "HIGH":
                            amount = action.partnershipInvestment.netCurrentAssetsHigh.amount;
                            break;
                    }

                    if (amount !== null) {
                        return {
                            ...scenario,
                            netCurrentAssets: {
                                ...scenario.netCurrentAssets,
                                amount
                            }
                        };
                    } else {
                        return scenario;
                    }
                });

                return fundValuationService.saveScenariosForFundValuation(selectedFundId, selectedFundValuationId, updatedScenarios)
                    .pipe(
                        map(scenarios => PartnershipInvestmentActions.saveandloadonallfundvaluationscenariosuccess({scenarios})),
                        catchError(error => of(FundValuationScenarioActions.loaderror({errorMsg: error.message})))
                    );
            })
        );
    },
    {functional: true}
);

// (!) PartnershipInvestmentActions.save is used to save on FundReport, FundValuation and FundValuationScenarios!
export const loadPartnershipInvestmentAfterSave = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(
                PartnershipInvestmentActions.saveandloadonfundreportsuccess,
                PartnershipInvestmentActions.saveandloadonfundvaluationsuccess,
                PartnershipInvestmentActions.saveandloadonallfundvaluationscenariosuccess
            ),
            withLatestFrom(store$.select(selectParamsForPartnershipInvestment)),
            map(([action, params]) =>
                (!!params.fundReportId && !!params.fundId)
                    ? PartnershipInvestmentActions.loadafteredit({
                        fundId: params.fundId,
                        fundReportId: params.fundReportId,
                        fundValuationId: params.fundValuationId})
                    : PartnershipInvestmentActions.loaderror({errorMsg: "Fund or Fund Report could not be found"})
            )
        );
    },
    {functional: true}
);
