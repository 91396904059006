<form [formGroup]="discountMultipleValuationForm">
    <mat-card class="detail-card">
        <valumize-info-panel *ngIf="(fundInvestmentNav$ | async) === 0 || (fundInvestmentNav$ | async) === null || (fundInvestmentNav$ | async) === undefined"
                             [appearance]="'WARNING'" [message]="'GP NAV is missing or zero. Please add a valid amount for NAV first.'"></valumize-info-panel>
        <ng-container *ngIf="(selectedAssetValuation$ | async) as selectedAssetValuation">
            <ng-container *ngIf="selectedAssetValuation.data?.id; else selectValuationFirst">
                <ng-container *ngIf="(discountMultipleValuation$ | async) as discountMultipleValuation">
                    <mat-card-subtitle>
                        <valumize-info-panel *ngIf="selectedAssetValuation.status === 'ERROR'" [appearance]="'ERROR'"
                                             [message]="selectedAssetValuation.errorMessage"></valumize-info-panel>
                        <valumize-info-panel *ngIf="selectedAssetValuation.status === 'SAVED'" [appearance]="'SAVED'"
                                             [message]="'Discount Multiple Valuation successfully saved'"></valumize-info-panel>
                        <mat-progress-spinner *ngIf="selectedAssetValuation.status === 'LOADING'"></mat-progress-spinner>
                    </mat-card-subtitle>

                    <mat-card-content class="detail-card-content">
                        <div class="key-value-pair-section">
                            <div class="key-value-pair-column">
                                <div>
                                    <div class="key-value-pair">
                                        <div class="key-value-item required-asterisk">Required gIRR</div>
                                        <div class="key-value-item">
                                            <ng-container *ngIf="isEditable">
                                                <div>{{discountMultipleValuation.persistedAssetValuation.requiredGrossIRR | traceableFormat}}</div>
                                                <div>
                                                    <input matInput
                                                           type="number"
                                                           formControlName="calculatedRequiredGrossIRR"
                                                           id="calculatedRequiredGrossIRR"
                                                           (change)="calcAndSave('calculatedRequiredGrossIRR')">
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!isEditable">
                                                {{discountMultipleValuation.persistedAssetValuation.requiredGrossIRR | traceableFormat}}
                                            </ng-container>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="isEditable">
                                        <div class="key-value-pair">
                                            <div class="key-value-item">Valuation Input</div>
                                            <div class="key-value-item">
                                                <mat-button-toggle-group style="margin: 0.5rem" #group="matButtonToggleGroup" [value]="selectedValuationInput"
                                                                         (change)="onValueChange(group.value)" id="valuationInputToggle">
                                                    <mat-button-toggle value="MULTIPLE">Multiple</mat-button-toggle>
                                                    <mat-button-toggle value="CF">CF</mat-button-toggle>
                                                </mat-button-toggle-group>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="key-value-pair-column">
                                <div>
                                    <div class="key-value-pair">
                                        <div class="key-value-item">Bid Price</div>
                                        <div class="key-value-item">
                                            <div>{{discountMultipleValuation.persistedAssetValuation.finalBidPrice | traceableFormat}}</div>
                                            <div>
                                                <ng-container *ngIf="isEditable">
                                                    {{discountMultipleValuation.calcAssetValuation.finalBidPrice | traceableFormat}}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="key-value-pair">
                                        <div class="key-value-item">Discount to GP NAV</div>
                                        <div class="key-value-item">
                                            <div>{{discountMultipleValuation.persistedAssetValuation.discountToGpNAV | traceableFormat}}</div>
                                            <div>
                                                <ng-container *ngIf="isEditable">
                                                    {{discountMultipleValuation.calcAssetValuation.discountToGpNAV | traceableFormat}}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table mat-table [dataSource]="discountMultipleValuation.tableDataSource">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    <ng-container *ngIf="isEditable">
                                        Exit Date * :
                                        <mat-form-field class="date-form-field">
                                            <input matInput
                                                   formControlName="mostRecentCashflowDate"
                                                   id="mostRecentCashflowDate"
                                                   (dateChange)="calcAndSave('mostRecentCashflowDate')"
                                                   [matDatepicker]="cashflowDatePicker">
                                            <mat-hint>dd/MM/yyyy</mat-hint>
                                            <mat-datepicker-toggle matIconSuffix [for]="cashflowDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #cashflowDatePicker></mat-datepicker>
                                        </mat-form-field>
                                        <br>
                                    </ng-container>
                                    <ng-container *ngIf="!isEditable">
                                        Exit Date * : {{discountMultipleValuation.mostRecentCashflowDate | date}}
                                    </ng-container>
                                </th>
                                <td mat-cell class="{{styleRequiredFormFieldWithAsterisk(element.definition)}}" *matCellDef="let element">{{element.name}}</td>
                            </ng-container>

                            <ng-container matColumnDef="low">
                                <th mat-header-cell *matHeaderCellDef>Low</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.low | traceableFormat}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="lowCalc">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="isEditable">
                                        <ng-container *ngIf="formControlIsEditable(element.definition)">
                                            <input matInput
                                                   type="number"
                                                   formControlName="lowCalc"
                                                   id="lowCalc"
                                                   (change)="calcAndSave('lowCalc')">
                                        </ng-container>
                                        <ng-container *ngIf="!formControlIsEditable(element.definition)">
                                            {{element.lowCalc | traceableFormat}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="base">
                                <th mat-header-cell *matHeaderCellDef>Base</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.base | traceableFormat}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="baseCalc">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="isEditable">
                                        <ng-container *ngIf="formControlIsEditable(element.definition)">
                                            <input matInput
                                                   type="number"
                                                   formControlName="baseCalc"
                                                   id="baseCalc"
                                                   (change)="calcAndSave('baseCalc')">
                                        </ng-container>
                                        <ng-container *ngIf="!formControlIsEditable(element.definition)">
                                            {{element.baseCalc | traceableFormat}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="high">
                                <th mat-header-cell *matHeaderCellDef>High</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.high | traceableFormat}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="highCalc">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="isEditable">
                                        <ng-container *ngIf="formControlIsEditable(element.definition)">
                                            <input matInput
                                                   type="number"
                                                   formControlName="highCalc"
                                                   id="highCalc"
                                                   (change)="calcAndSave('highCalc')">
                                        </ng-container>
                                        <ng-container *ngIf="!formControlIsEditable(element.definition)">
                                            {{element.highCalc | traceableFormat}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="discountMultipleValuation.tableColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: discountMultipleValuation.tableColumns;" class="{{styleRowImportant(row)}} {{styleRowBorderTop(row)}}"></tr>
                        </table>
                    </mat-card-content>
                    <mat-card-actions class="detail-card-actions">
                        <div class="last-modified" data-test="modification-date"
                             [hidden]="(fundInvestmentNav$ | async) === 0 || (fundInvestmentNav$ | async) === null || (fundInvestmentNav$ | async) === undefined">
                            Last modified: {{discountMultipleValuation.persistedAssetValuation.finalBidPrice.modDate | timestamp}}
                        </div>
                        <button (click)="editMode()"
                                *ngIf="!isEditable"
                                [disabled]="(isEditDisabled$ | async)!"
                                [hidden]="(fundInvestmentNav$ | async) === 0 || (fundInvestmentNav$ | async) === null || (fundInvestmentNav$ | async) === undefined"
                                color="primary"
                                data-test="edit-button"
                                mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="calcAndSave('', true)"
                                *ngIf="isEditable"
                                [disabled]="discountMultipleValuationForm.invalid"
                                color="primary"
                                data-test="save-button"
                                mat-icon-button>
                            <mat-icon>save</mat-icon>
                        </button>
                        <button (click)="cancel()"
                                *ngIf="isEditable"
                                color="primary"
                                data-test="cancel-button"
                                mat-icon-button>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-card-actions>
                    <valumize-asset-valuation-rationale
                        [assetValuation]="discountMultipleValuation.persistedAssetValuation"
                        [rationaleIsEditable]="discountMultipleValuation.rationaleIsEditable"
                        (saveRationale)="onSaveRationale($event)"
                        (editRationale)="onEditRationale()"
                        (cancelRationale)="onCancelRationale()">
                    </valumize-asset-valuation-rationale>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #selectValuationFirst>
            <mat-card-content class="detail-card-content" style="color: grey">
                Please select valuation first!
            </mat-card-content>
        </ng-template>
    </mat-card>
</form>
