<h2 mat-dialog-title>Generate Project Report</h2>
<mat-dialog-content>
    <form [formGroup]="projectReportForm">
        <div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Project Name</mat-label>
                    <input matInput
                           formControlName="projectName"
                           data-test="project-name-input"
                           placeholder="Project Name">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Project Closing Date</mat-label>
                    <input matInput
                           formControlName="projectClosingDate"
                           id="projectClosingDate"
                           [matDatepicker]="projectClosingDatePicker">
                    <mat-hint>dd/MM/yyyy</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="projectClosingDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #projectClosingDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>First Offers Deadline</mat-label>
                    <input matInput
                           formControlName="firstRoundOffersDeadline"
                           id="firstRoundOffersDeadline"
                           [matDatepicker]="firstRoundOffersDeadlineDatePicker">
                    <mat-hint>dd/MM/yyyy</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="firstRoundOffersDeadlineDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #firstRoundOffersDeadlineDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <h3 class="deal-selection">Selected deals</h3>
            <ng-container *ngIf="selectedDeals.length !== 0; else selectDealsToGenerateReport">
                <table mat-table [dataSource]="selectedDeals">
                    <ng-container matColumnDef="dealName">
                        <th mat-header-cell *matHeaderCellDef>Deal</th>
                        <td mat-cell *matCellDef="let element">{{element.projectName | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="remove">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                mat-icon-button
                                color="primary"
                                data-test="remove-deal"
                                (click)="removeDealFromSelection(element.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="selectedDealsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: selectedDealsColumns"></tr>
                </table>
            </ng-container>
            <ng-template #selectDealsToGenerateReport style="color: grey">
                <div>Select deals to generate report!</div>
            </ng-template>

            <h3 style="margin-top: 1rem">Select deals</h3>
            <ng-container *ngIf="filterNotSelected((deals$ | async) || []).length !== 0; else noDeals">
                <table mat-table [dataSource]="filterNotSelected((deals$ | async) || [])">
                    <ng-container matColumnDef="dealName">
                        <th mat-header-cell *matHeaderCellDef>Deal</th>
                        <td mat-cell *matCellDef="let element">{{element.projectName | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="add" sticky>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                mat-icon-button
                                color="primary"
                                data-test="add-deal"
                                (click)="addDealToSelection(element)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="selectableDealsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: selectableDealsColumns"></tr>
                </table>
            </ng-container>
            <ng-template #noDeals style="color: grey">
                <div>No deals found!</div>
            </ng-template>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close data-test="generate-button" [disabled]="!projectReportForm.valid" (click)="generateReport()">Generate</button>
    <button mat-stroked-button mat-dialog-close data-test="cancel-button">Cancel</button>
</mat-dialog-actions>
