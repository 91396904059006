import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export const numberNotBiggerThan190000m = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === undefined || value === null || (!isNaN(value) && Number(value) <= 190000)) {
        // If the value is undefined or a number not bigger than 190000 Millions, return no error (valid)
        return null;
    } else {
        return {numberNotBiggerThan19000: true};
    }
};

export const noWhitespaceValidator = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control?.value?.toString() || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
};

