import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {ProjectReportActions} from "../../store/deal.actions";
import {selectReports} from "../../store/report/report.selectors";
import {ApiUtil} from "../../../shared/utils/api-util";
import {DocumentMetadata} from "../../models/document-metadata";

@Component({
    selector: "valumize-previous-reports",
    templateUrl: "./previous-reports.component.html",
    styleUrls: ["./previous-reports.component.scss"]
})
export class PreviousReportsComponent {

    columns = ["name", "creationDate"];
    reports$ = this.store.select(selectReports);

    constructor(private readonly store: Store) {
        this.store.dispatch(ProjectReportActions.loadall());
    }

    documentUrl(document: DocumentMetadata): string {
        return ApiUtil.toApiPath(`/documents/${document.id}/data`);
    }
}
