import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AssetActions} from "../../../../asset/store/asset.actions";
import {Store} from "@ngrx/store";
import {selectAssetName, selectIsAnyAssetFormInEditMode} from "../../../../asset/store/asset/asset.selectors";
import {selectSelectedSourceDataset} from "../../../../import/store/import.selectors";
import {MatDialogRef} from "@angular/material/dialog";
import {map, Subscription} from "rxjs";
import {Router} from "@angular/router";
import {ValuationTypeEnum} from "../../../../asset/models/asset-valuation/valuation-type.enum";

export enum assetDialogExpandType {
    GENERAL = "general",
    GPVALUATION = "gpvaluation",
    EXITINFO = "exitinfo",
    VALUATION = "valuation"
}

@Component({
    selector: "valumize-asset-dmv-valuation-dialog",
    templateUrl: "./asset-details-dialog.component.html",
    styleUrls: ["./asset-details-dialog.component.scss"]
})
export class AssetDetailsDialogComponent implements OnInit, OnDestroy {

    @Input() assetId?: number;
    @Input() fundId?: number;
    @Input() fundReportId?: number;
    @Input() fundValuationId?: number;
    @Input() assetValuationId?: number;
    @Input() preferredValuationType = ValuationTypeEnum.DiscountMultipleValuation;
    @Input() fundInvestmentId?: number;
    @Input() dealId?: number;
    @Input() expanded: assetDialogExpandType = assetDialogExpandType.VALUATION;

    protected readonly assetDialogExpandType = assetDialogExpandType;

    subscription?: Subscription;

    assetName$ = this.store.select(selectAssetName);
    sourceDataset$ = this.store.select(selectSelectedSourceDataset);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    isEditDisabled = false;
    isCloseDisabled = false;
    isAssetDetailsDisabledInfo = false;

    protected readonly valuationTypeEnum = ValuationTypeEnum;

    constructor(public dialogRef: MatDialogRef<AssetDetailsDialogComponent>,
                private readonly store: Store,
                private readonly router: Router) {
        this.dialogRef.disableClose = true; // Disable default close behavior
    }

    ngOnInit(): void {
        if (this.assetId) {
            this.store.dispatch(AssetActions.opendetailsdialog({
                assetId: this.assetId,
                fundId: this.fundId,
                fundReportId: this.fundReportId,
                fundValuationId: this.fundValuationId,
                fundInvestmentId: this.fundInvestmentId,
                assetValuationId: this.assetValuationId,
                valuationType: this.preferredValuationType,
                dealId: this.dealId
            }));
        }
        this.subscription = this.isEditDisabled$.pipe(
            map((isDisabled) => {
                this.dialogRef.disableClose = isDisabled;
                this.isEditDisabled = isDisabled;
                if (!isDisabled) {
                    this.isCloseDisabled = false;
                    this.isAssetDetailsDisabledInfo = false;
                }
            })).subscribe();
    }

    closeDialog(): void {
        if (!this.isEditDisabled) {
            this.dialogRef.close();
        } else {
            this.isCloseDisabled = true;
        }
    }

    openAssetDetails(): void {
        if (!this.isEditDisabled) {
            this.dialogRef.close();
            this.router.navigate(["../../assets/", this.assetId], {
                queryParams: {
                    fundId: this.fundId,
                    fundReportId: this.fundReportId,
                    fundValuationId: this.fundValuationId,
                    fundInvestmentId: this.fundInvestmentId,
                    assetValuationId: this.assetValuationId,
                    dealId: this.dealId
                }
            });
        } else {
            this.isAssetDetailsDisabledInfo = true;
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
