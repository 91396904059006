import {Component, Input, OnInit} from "@angular/core";
import {map, Observable} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Seller} from "../models/seller";
import {selectSellers} from "../store/seller/seller.selectors";
import {DealActions, SellerActions, SellerListActions} from "../store/deal.actions";
import {noWhitespaceValidator} from "../../shared/utils/form-validators";

@Component({
    selector: "valumize-seller-new-dialog",
    templateUrl: "./seller-new-dialog.component.html",
    styleUrls: ["./seller-new-dialog.component.scss"]
})
export class SellerNewDialogComponent implements OnInit {

    @Input() dealId?: number;

    filteredSellers$?: Observable<Seller[]>;
    sellers$ = this.store.select(selectSellers);
    columns = ["name"];

    sellerForm = this.formBuilder.group({
        sellerName: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, Validators.maxLength(100), noWhitespaceValidator()]})
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder) {
        this.store.dispatch(SellerListActions.load());
    }

    ngOnInit(): void {
        this.filteredSellers$ = this.sellers$;
    }

    onSearch() {
        if (this.sellerForm.value.sellerName) {
            this.filteredSellers$ = this.sellers$.pipe(
                map((sellers) => sellers
                    .filter((seller) => !!seller.name.text
                        ? seller.name.text.toLowerCase().includes(this.sellerForm.getRawValue().sellerName.toLowerCase())
                        : false
                    )));
        } else {
            this.filteredSellers$ = this.sellers$;
        }
    }

    createSeller() {
        if (this.sellerForm.valid) {
            this.store.dispatch(SellerActions.create({sellerName: this.sellerForm.getRawValue().sellerName}));
        }
    }

    saveSellerOnDeal(sellerId: number) {
        this.store.dispatch(DealActions.savesellerondeal({sellerId}));
    }
}
