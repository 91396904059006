/* eslint-disable arrow-body-style */
import {inject} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {NoteService} from "src/app/shared/services/note/note.service";
import {Asset} from "../../models/asset/asset";
import {AssetService} from "../../services/asset.service";
import {
    AssetActions,
    AssetDescriptionActions,
    AssetFinancialHistoryActions,
    AssetGeneralInformationActions,
    AssetListActions,
    AssetNameActions,
    AssetNoteActions,
    AssetSwotActions,
    AssetValuationActions,
    EndMarketsCustomersActions,
    GeneralPartnerValuationActions,
    ShareholderActions
} from "../asset.actions";
import {
    selectAssetFiscalYearEnd,
    selectAssetName,
    selectAssetState,
    selectHasBaselineAsset,
    selectSelectedAsset,
    selectSelectedAssetId,
    selectSelectedAssetNotes
} from "./asset.selectors";
import {EMPTY_ASSET} from "../asset.reducer";
import {FundInvestmentService} from "../../../fund/services/fund-investment.service";
import {selectSelectedFundId} from "../../../fund/store/fund/fund.selectors";
import {selectSelectedFundReportId} from "../../../fund/store/fund-report/fund-report.selectors";
import {FundActions, FundInvestmentActions, FundReportActions, FundValuationActions, PartnershipInvestmentActions} from "../../../fund/store/fund.actions";
import {selectSelectedFundInvestment} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {selectSelectedSourceDataset} from "../../../import/store/import.selectors";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {DealActions} from "../../../deal/store/deal.actions";
import {PeriodUtil} from "../../../shared/utils/period-util";

export const initAssetOverview = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetListActions.open),
            map(() => AssetListActions.load())
        );
    },
    {functional: true}
);

export const loadAssetList = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetListActions.load),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) =>
                assetService.getAssets(dataset).pipe(
                    map((assets) => AssetListActions.loadsuccess({assets})),
                    catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const createAsset = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.create),
            withLatestFrom(store$.select(selectSelectedSourceDataset)),
            exhaustMap(([action, dataset]) => {
                return assetService.saveAsset({
                    ...EMPTY_ASSET,
                    ...{
                        name: {
                            ...EMPTY_ASSET.name,
                            text: action.assetName
                        }
                    }
                }, undefined, dataset).pipe(
                    map((asset) =>
                        AssetActions.createsuccess({asset})),
                    catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const navigateToAssetDetailsPageAfterCreate = createEffect(
    (actions$ = inject(Actions), router$ = inject(Router)) => {
        return actions$.pipe(
            ofType(AssetActions.createsuccess),
            tap((action) => {
                router$.navigate(["/assets", action.asset.id]);
            }),
            map(() => AssetActions.navigatetoassetsuccess())
        );
    },
    {functional: true}
);

export const loadAssetAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetActions.opendetailsdialog),
            map((action) => AssetActions.load({assetId: action.assetId})));
    },
    {functional: true}
);

export const loadFundAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open),
            map((action) => !!action.fundId && !!action.fundReportId
                ? FundActions.loadforasset({fundId: action.fundId})
                : FundActions.loadforassetdonothing()
            ));
    },
    {functional: true}
);

export const loadFundReportAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open),
            map((action) => !!action.fundId && !!action.fundReportId
                ? FundReportActions.loadforasset({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                : FundReportActions.loadforassetdonothing()
            ));
    },
    {functional: true}
);

export const loadGeneralPartnerValuationAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetActions.opendetailsdialog),
            map((action) => !!action.fundId && !!action.fundReportId && !!action.fundInvestmentId
                ? GeneralPartnerValuationActions.load({fundId: action.fundId, fundReportId: action.fundReportId, fundInvestmentId: action.fundInvestmentId})
                : GeneralPartnerValuationActions.donothing()
            ));
    },
    {functional: true}
);

export const loadFundInvestmentAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetActions.opendetailsdialog),
            map((action) => !!action.fundId && !!action.fundReportId && !!action.fundInvestmentId
                ? FundInvestmentActions.loadforasset({fundId: action.fundId, fundReportId: action.fundReportId, fundInvestmentId: action.fundInvestmentId})
                : FundInvestmentActions.loadforassetdonothing()
            ));
    },
    {functional: true}
);

export const loadPartnershipInvestmentAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open),
            map((action) => !!action.fundId && !!action.fundReportId
                ? PartnershipInvestmentActions.loadforasset({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                : PartnershipInvestmentActions.loadforassetdonothing()
            ));
    },
    {functional: true}
);

export const loadFundValuationAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open),
            map((action) => !!action.fundId && !!action.fundReportId && !!action.fundValuationId
                ? FundValuationActions.loadforasset({fundId: action.fundId, fundReportId: action.fundReportId, fundValuationId: action.fundValuationId})
                : FundValuationActions.loadforassetdonothing()
            ));
    },
    {functional: true}
);

export const loadAssetValuationAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetActions.opendetailsdialog),
            map((action) => !!action.assetValuationId && !!action.valuationType && !!action.fundValuationId
                ? AssetValuationActions.load({assetId: action.assetId, assetValuationId: action.assetValuationId, valuationType: action.valuationType})
                : AssetValuationActions.donothing()
            ));
    },
    {functional: true}
);

export const loadAllAssetValuationAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetActions.opendetailsdialog),
            map((action) => !!action.fundReportId && !!action.fundInvestmentId
                ? AssetValuationActions.loadall({assetId: action.assetId})
                : AssetValuationActions.donothing()
            ));
    },
    {functional: true}
);

export const loadFinancialHistoryAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetActions.opendetailsdialog),
            map((action) => AssetFinancialHistoryActions.load({assetId: action.assetId})));
    },
    {functional: true}
);

export const loadDealAfterAssetOpen = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.open),
            map((action) => action.dealId
                ? DealActions.loaddealforasset({dealId: action.dealId})
                : DealActions.nodealforasset()
            ));
    },
    {functional: true}
);

export const loadAsset = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.load),
            withLatestFrom(store$.select(selectHasBaselineAsset)),
            exhaustMap(([action, hasBaselineAsset]) =>
                assetService.getAsset(action.assetId).pipe(
                    switchMap((asset) => {
                        return (asset.mergeTargetId && !hasBaselineAsset)
                            ? of(
                                AssetActions.loadsuccess({asset}),
                                AssetActions.loadbaseline({assetId: asset.mergeTargetId}),
                                AssetFinancialHistoryActions.loadbaseline({assetId: asset.mergeTargetId}))

                            : of(AssetActions.loadsuccess({asset}));
                    }),
                    catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const loadBaselineAsset = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.loadbaseline),
            exhaustMap((action) =>
                assetService.getAsset(action.assetId).pipe(
                    map((asset) => AssetActions.loadbaselinesuccess({asset})),
                    catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: "Baseline Asset" + error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const saveAssetName = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetNameActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            name: {
                                ...selectedAsset.name,
                                text: action.assetName
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.loadsuccess({asset})),
                        catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveAssetDescription = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetDescriptionActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            description: {
                                ...selectedAsset.description,
                                text: action.assetDescription
                            },
                            url: {
                                ...selectedAsset.url,
                                text: action.assetUrl
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.loadsuccess({asset})),
                        catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Asset could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const generateAssetDescription = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetDescriptionActions.gptedit),
            withLatestFrom(store$.select(selectAssetName)),
            exhaustMap(([, selectedAssetName]) => {
                const assetName = selectedAssetName.name.text;
                if (assetName) {
                    return assetService.getGptCompanyDescription(assetName).pipe(
                        map((response) => AssetDescriptionActions.generatesuccess({description: response})),
                        catchError((error: { message: string }) => of(AssetDescriptionActions.generatefailure({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetDescriptionActions.generatefailure({errorMsg: "Asset description could not be generated, please enter a valid name for the asset first"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetNotes = createEffect(
    (actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(AssetActions.open, AssetNoteActions.load),
            exhaustMap((action) => {
                const inContext = "inContext" in action
                    ? action.inContext
                    : `/assets/asset_id=${action.assetId}/`;
                return noteService.getNotes(inContext).pipe(
                    map((notes) => AssetNoteActions.loadsuccess({notes})),
                    catchError((error: { message: string }) => of(AssetNoteActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const saveAssetNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(AssetNoteActions.save),
            withLatestFrom(store$.select(selectSelectedAssetNotes)),
            exhaustMap(([action, state]) => {
                return noteService.save(action.note, action.context).pipe(
                    map(() => AssetNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) => of(AssetNoteActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const deleteAssetNote = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), noteService = inject(NoteService)) => {
        return actions$.pipe(
            ofType(AssetNoteActions.delete),
            withLatestFrom(store$.select(selectSelectedAssetNotes)),
            exhaustMap(([action, state]) => {
                if (action.note.id === undefined) {
                    return of();
                }
                return noteService.delete(action.note.id).pipe(
                    map(() => AssetNoteActions.load({inContext: state.baseContext})),
                    catchError((error: { message: string }) => of(AssetNoteActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const saveAssetGeneralInformation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetGeneralInformationActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            country: {
                                ...selectedAsset.country,
                                code: action.country ?? undefined
                            },
                            activityDescription: {
                                ...selectedAsset.activityDescription,
                                text: action.activityDescription ?? undefined
                            },
                            regions: action.regions,
                            stage: {
                                ...selectedAsset.stage,
                                code: action.stage
                            },
                            gic: {
                                ...selectedAsset.gic,
                                text: action.gic ?? undefined
                            },
                            gpIndication: {
                                ...selectedAsset.gpIndication,
                                code: action.gpIndication
                            },
                            currencyIso: {
                                ...selectedAsset.currencyIso,
                                code: action.currency
                            },
                            esg: {
                                ...selectedAsset.esg,
                                code: action.esg
                            },
                            fiscalYearEnd: {
                                ...selectedAsset.fiscalYearEnd,
                                code: action.fiscalYearEnd
                            },
                            assessment: {
                                ...selectedAsset.assessment,
                                code: action.assessment
                            },
                            publiclyListed: action.publiclyListed
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.loadsuccess({asset})),
                        catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const generatePeriodDatesAfterAssetActionLoadSuccess = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.loadsuccess),
            map(() => AssetActions.generateperioddates()));
    },
    {functional: true}
);

export const generatedPeriodDates  = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetActions.generateperioddates),
            withLatestFrom(store$.select(selectAssetFiscalYearEnd)),
            map(([action, fiscalYearEndMonth]) => {
                const availablePeriodDates = PeriodUtil.generateAvailablePeriodDates(fiscalYearEndMonth);
                return AssetActions.generateperioddatessuccess({availablePeriodDates});
            }));
    },
    {functional: true}
);

export const loadGeneralPartnerValuation = createEffect(
    (actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.load),
            exhaustMap((action) => {
                if (!!action.fundId && !!action.fundReportId && !!action.fundInvestmentId) {
                    return fundInvestmentService.getFundInvestment(action.fundId, action.fundReportId, action.fundInvestmentId).pipe(
                        map((fundInvestment) => GeneralPartnerValuationActions.loadsuccess({fundInvestment})),
                        catchError((error: { message: string }) => of(GeneralPartnerValuationActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(GeneralPartnerValuationActions.loaderror({errorMsg: "Error while loading the gp valuation, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveGeneralPartnerValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReportId)),
            exhaustMap(([action, fundId, fundReportId]) => {
                if (!!fundId && !!fundReportId && !!action.fundInvestment.id) {
                    return fundInvestmentService.updateFundInvestment(fundId, fundReportId, action.fundInvestment.id, action.fundInvestment).pipe(
                        map((fundInvestmentResponse) => GeneralPartnerValuationActions.saveloadsuccess({fundInvestment: fundInvestmentResponse})),
                        catchError((error: { message: string }) => of(GeneralPartnerValuationActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(GeneralPartnerValuationActions.loaderror({errorMsg: "Error while loading the fund investment, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadFundInvestmentAfterGpValuationCalc = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.saveloadsuccess),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReportId)),
            map(([action, fundId, fundReportId]) => fundId && fundReportId && action.fundInvestment.id
                ? FundInvestmentActions.loadforasset({fundId, fundReportId, fundInvestmentId: action.fundInvestment.id})
                : FundInvestmentActions.loaderror({errorMsg: "Error while loading the fund investment, please refresh the page"})
            )
        );
    },
    {functional: true}
);

export const calcGeneralPartnerValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundInvestmentService = inject(FundInvestmentService)) => {
        return actions$.pipe(
            ofType(GeneralPartnerValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundReportId),
                store$.select(selectSelectedFundInvestment)
            ),
            exhaustMap(([action, fundId, fundReportId, fundInvestment]) => {
                if (fundId && fundReportId && fundInvestment.data.id) {
                    return fundInvestmentService.calcFundInvestment(fundId, fundReportId, fundInvestment.data.id, {
                        ...fundInvestment.data,
                        remainingCost: {
                            ...fundInvestment.data.remainingCost,
                            amount: action.remainingCost
                        },
                        mezzanine: {
                            ...fundInvestment.data.mezzanine,
                            amount: action.mezzanine
                        },
                        preferredEquity: {
                            ...fundInvestment.data.preferredEquity,
                            amount: action.preferredEquity
                        },
                        commonEquity: {
                            ...fundInvestment.data.commonEquity,
                            amount: action.commonEquity
                        },
                        realizedCost: {
                            ...fundInvestment.data.realizedCost,
                            amount: action.realizedCost
                        },
                        realizedGains: {
                            ...fundInvestment.data.realizedGains,
                            amount: action.realizedGains
                        },
                    }).pipe(
                        map((fundInvestmentResponse) => GeneralPartnerValuationActions.calcsuccess({fundInvestment: fundInvestmentResponse})),
                        catchError((error: { message: string }) => of(GeneralPartnerValuationActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(GeneralPartnerValuationActions.loaderror({errorMsg: "Error while loading the fund investment, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveSwot = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetSwotActions.save),
            withLatestFrom(store$.select(selectAssetState)),
            exhaustMap(([action, state]) => {
                const selectedAsset: Asset = state.selectedAsset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            swot: {
                                ...selectedAsset.swot,
                                ...{
                                    swotStrengths: {
                                        ...selectedAsset.swot.swotStrengths,
                                        text: action.swotStrengths
                                    },
                                    swotWeaknesses: {
                                        ...selectedAsset.swot.swotWeaknesses,
                                        text: action.swotWeaknesses
                                    },
                                    swotOpportunities: {
                                        ...selectedAsset.swot.swotOpportunities,
                                        text: action.swotOpportunities
                                    },
                                    swotThreats: {
                                        ...selectedAsset.swot.swotThreats,
                                        text: action.swotThreats
                                    }
                                }
                            }
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.saveloadsuccess({asset})),
                        catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }

            })
        );
    },
    {functional: true}
);

export const generateSwotAnalysis = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetSwotActions.gptedit),
            withLatestFrom(store$.select(selectAssetName)),
            exhaustMap(([, selectedAssetName]) => {
                const assetName = selectedAssetName.name.text;
                if (assetName) {
                    return assetService.getGptSwotAnalysis(assetName).pipe(
                        map((response) => AssetSwotActions.generatesuccess({swot: response})),
                        catchError((error: { message: string }) => of(AssetSwotActions.generatefailure({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetSwotActions.generatefailure({errorMsg: "Asset SWOT could not be generated, please enter a valid name for the asset first"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveEndMarketsCustomers = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(EndMarketsCustomersActions.save),
            withLatestFrom(store$.select(selectSelectedAsset)),
            exhaustMap(([action, asset]) => {
                const selectedAsset: Asset = asset.data;
                if (selectedAsset.id) {
                    const assetId = selectedAsset.id;
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            endMarketsCustomers: {
                                ...selectedAsset.endMarketsCustomers,
                                ...{
                                    text: action.text
                                }
                            }
                        }
                    }, assetId).pipe(
                        map((updatedAsset) => AssetActions.saveloadsuccess({asset: updatedAsset})),
                        catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const generateEndMarketsDescription = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(EndMarketsCustomersActions.gptedit),
            withLatestFrom(store$.select(selectAssetName)),
            exhaustMap(([, selectedAssetName]) => {
                const assetName = selectedAssetName.name.text;
                if (assetName) {
                    return assetService.getGptEndMarkets(assetName).pipe(
                        map((response) => EndMarketsCustomersActions.generatesuccess({description: response})),
                        catchError((error: { message: string }) => of(EndMarketsCustomersActions.generatefailure({errorMsg: error.message})))
                    );
                } else {
                    return of(EndMarketsCustomersActions.generatefailure(
                        {errorMsg: "Asset end markets and customers could not be generated, please enter a valid name for the asset first"}
                    ));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetFinancialHistory = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.load),
            exhaustMap((action) =>
                assetService.getAssetFinancialHistory(action.assetId).pipe(
                    map((financialHistory) => AssetFinancialHistoryActions.loadsuccess({financialHistory})),
                    catchError((error: { message: string }) => of(AssetFinancialHistoryActions.loaderror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const loadFinancialHistoryAfterCancel = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.cancel),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            map(([action, assetId]) =>
                assetId
                    ? AssetFinancialHistoryActions.load({assetId})
                    : AssetFinancialHistoryActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"})
            ));
    },
    {functional: true}
);

export const loadBaselineAssetFinancialHistory = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.loadbaseline),
            exhaustMap((action) =>
                assetService.getAssetFinancialHistory(action.assetId).pipe(
                    map((financialHistory) => AssetFinancialHistoryActions.loadbaselinesuccess({financialHistory})),
                    catchError((error: { message: string }) => of(AssetFinancialHistoryActions.loaderror({errorMsg: "Baseline Asset Financial History" + error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const saveAssetFinancialHistory = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.save),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            exhaustMap(([action, assetId]) => {
                if (assetId === undefined) {
                    return of(AssetFinancialHistoryActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }
                return assetService.saveAssetFinancialHistory(assetId, action.financialHistory).pipe(
                    map((financialHistory) => AssetFinancialHistoryActions.saveloadsuccess({financialHistory})),
                    catchError((error: { message: string }) => of(AssetFinancialHistoryActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const calcAssetFinancialHistory = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetFinancialHistoryActions.calc),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            exhaustMap(([action, assetId]) => {
                if (assetId === undefined) {
                    return of(AssetFinancialHistoryActions.calcerror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }
                return assetService.calcAssetFinancialHistory(assetId, action.financialHistoryRecord).pipe(
                    map((calculatedFinancialHistory) => AssetFinancialHistoryActions.calcsuccess({financialHistory: calculatedFinancialHistory})),
                    catchError((error: { message: string }) => of(AssetFinancialHistoryActions.calcerror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const loadAllShareholders = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(ShareholderActions.load),
            exhaustMap((action) => {
                return assetService.getAllShareholders(action.assetId).pipe(
                    map((shareholders) => ShareholderActions.loadallsuccess({shareholders})),
                    catchError((error: { message: string }) => of(ShareholderActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const loadShareholdersForDate = createEffect(
    (actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(ShareholderActions.load),
            switchMap((action) => {
                return assetService.getShareholdersForDate(action.assetId, action.date).pipe(
                    map((shareholders) => ShareholderActions.loadsuccess({shareholders})),
                    catchError((error: { message: string }) => of(ShareholderActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);


export const saveShareholdersForDate = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(ShareholderActions.save),
            exhaustMap((action) => {
                if (action.assetId === undefined || !action.date) {
                    return of(ShareholderActions.saveerror({errorMsg: "Shareholder could not be saved, enter a valid date first"}));
                }
                return assetService.saveShareholdersForDate(action.assetId, action.date, action.shareholders).pipe(
                    map((shareholders) => ShareholderActions.saveloadsuccess({shareholders})),
                    catchError((error: { message: string }) => of(ShareholderActions.saveerror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const setMergeTargetId = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetService = inject(AssetService)) => {
        return actions$.pipe(
            ofType(AssetActions.setmergetargetid),
            withLatestFrom(store$.select(selectSelectedAsset)),
            exhaustMap(([action, selectedAssetData]) => {
                const selectedAsset: Asset = selectedAssetData.data;
                const assetId = selectedAsset.id;
                if (assetId) {
                    return assetService.saveAsset({
                        ...selectedAsset,
                        ...{
                            mergeTargetId: action.mergeTargetId
                        }
                    }, assetId).pipe(
                        map((asset) => AssetActions.saveloadsuccess({asset})),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) => of(AssetActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }

            })
        );
    },
    {functional: true}
);

