import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {
    selectAssetFinancialHistory,
    selectAssetFinancialHistoryEdit,
    selectBaselineAssetFinancialHistory,
    selectIsAnyAssetFormInEditMode,
    selectSelectedAssetId
} from "../../store/asset/asset.selectors";
import {map} from "rxjs/operators";
import {FinancialPerformanceRecord} from "../../models/asset/financial-performance-record";
import {Subscription} from "rxjs";
import {AssetFinancialHistoryActions} from "../../store/asset.actions";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: "valumize-asset-fin-data-history",
    templateUrl: "./asset-fin-data-history.component.html"
})
export class AssetFinDataHistoryComponent implements OnInit, OnDestroy {

    selectedAssetFinancialHistory$ = this.store.select(selectAssetFinancialHistory);
    selectedAssetId$ = this.store.select(selectSelectedAssetId);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    selectedAssetFinancialHistoryData: FinancialPerformanceRecord[] = [];
    baselineAssetFinancialHistoryData: FinancialPerformanceRecord[] = [];
    isEditable = false;
    isDataset = false;
    subscriptions: Subscription[] = [];

    constructor(private readonly store: Store, public dialog: MatDialog) {
    }

    ngOnInit(): void {

        this.subscriptions.push(
            this.selectedAssetFinancialHistory$.pipe(map((data) => {
                if (data?.data) {
                    this.selectedAssetFinancialHistoryData = [...data.data];
                }
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectAssetFinancialHistoryEdit).pipe(map((isEditable) => {
                this.isEditable = isEditable;
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectBaselineAssetFinancialHistory).pipe(map((baselineAssetFinancialHistory) => {
                if (baselineAssetFinancialHistory.status === "LOADED") {
                    this.isDataset = true;
                    this.baselineAssetFinancialHistoryData = baselineAssetFinancialHistory.data;
                } else {
                    this.isDataset = false;
                }
            })).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onSave(assetHistoryToSave: FinancialPerformanceRecord[]) {
        this.store.dispatch(AssetFinancialHistoryActions.save({financialHistory: assetHistoryToSave}));
    }

    onEdit() {
        this.store.dispatch(AssetFinancialHistoryActions.edit());
    }

    onCancel() {
        this.store.dispatch(AssetFinancialHistoryActions.cancel());
    }

    onCalc(updatedRecords: FinancialPerformanceRecord[]) {
        this.store.dispatch(AssetFinancialHistoryActions.calc({financialHistoryRecord: updatedRecords}));
    }
}
