import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AssetState} from "../asset.state";
import {assetStoreFeatureKey} from "../asset.reducer";
import {selectSelectedAssetValuation} from "../asset-valuation/asset-valuation.selectors";
import {TraceableCode, TraceableDate, TraceableMoney, TraceableMultiple, TraceablePercent} from "../../../shared/model/traceable";
import {FundInvestment} from "../../../fund/models/fund-investement";
import {selectSelectedFundValuationReportDate, selectSelectFundAssetValuations} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {Period} from "../../../shared/model/period";

export const selectAssetState = createFeatureSelector<AssetState>(assetStoreFeatureKey);


export const selectAssetForms = createSelector(
    selectAssetState,
    (state) => state.assetForms
);


export const selectIsAnyAssetFormInEditMode = createSelector(
    selectAssetForms,
    (assetForms) => {
        const checkEditMode = (value: any): boolean => {
            if (typeof value === "boolean") {
                return value;
            }

            if (typeof value === "object" && value !== null) {
                return Object.values(value).some(checkEditMode);
            }

            return false;
        };

        return checkEditMode(assetForms);
    }
);

export const selectAssets = createSelector(
    selectAssetState,
    (state) => state.assets.data
);

export const selectSelectedAsset = createSelector(
    selectAssetState,
    (state) => state.selectedAsset
);

export const selectBaselineAsset = createSelector(
    selectAssetState,
    (state) => state.baselineAsset
);

export const selectHasBaselineAsset = createSelector(
    selectBaselineAsset,
    (state) => state.status === "LOADED"
);

export const selectSelectedAssetId = createSelector(
    selectSelectedAsset,
    (state) => state.data.id
);

export const selectAssetName = createSelector(
    selectSelectedAsset,
    selectAssetForms,
    (state, assetForms) => ({
        name: state.data.name,
        assetNameIsEditable: assetForms.assetNameIsEditable
    })
);

export const selectAssetFiscalYearEnd = createSelector(
    selectSelectedAsset,
    (state) => state.data.fiscalYearEnd.code
);

export const selectGeneratedAvailablePeriodDates = createSelector(
    selectAssetState,
    (state) => state.availablePeriodDates
);

export const selectValidateAvailablePeriodDates = (date: string, type: Period["type"]) => createSelector(
    selectGeneratedAvailablePeriodDates,
    (availablePeriodDates) => {
        if(type === "FULL_FISCAL_YEAR"){
            return availablePeriodDates.FULL_FISCAL_YEAR.includes(date);
        }

        if(type === "SEMESTER"){
            return availablePeriodDates.SEMESTER.includes(date);
        }

        if(type === "QUARTER"){
            return availablePeriodDates.QUARTER.includes(date);
        }
        return false;
    }
);

export const selectAssetNameFromId = (assetId: number) => createSelector(
    selectAssets,
    (assets) => assets.find(asset => asset.id === assetId)?.name.text ?? ""
);

export const selectAssetDescription = createSelector(
    selectSelectedAsset,
    selectAssetForms,
    (state, assetForms) =>
        ({
            description: state.data.description,
            url: state.data.url,
            assetDescriptionIsEditable: assetForms.assetDescriptionIsEditable
        })
);

export const selectGeneratedAssetDescription = createSelector(
    selectAssetState,
    (state) => state.generatedAssetDescription.gptAssetDescriptionText
);

export const selectGptAssetDescriptionIsEditable = createSelector(
    selectAssetState,
    (state) => state.generatedAssetDescription.gptAssetDescriptionIsEditable
);

export const selectGeneralInformationEdit = createSelector(
    selectAssetForms,
    (state) => state.generalInformationIsEditable
);

export const selectSelectedAssetValuationsForAsset = createSelector(
    selectAssetState,
    (state) => state.selectedAssetValuationsForAsset.data
);

export const selectPreferredAssetValuationForAsset = createSelector(
    selectAssetState,
    selectSelectFundAssetValuations,
    selectSelectedFundValuationReportDate,
    (state, fundAssetValuations, reportDate) => state.selectedAssetValuationsForAsset.data
        .find(assetValuation => assetValuation.reportDate.date === reportDate.date &&
            fundAssetValuations.some(fundAssetValuation => assetValuation.id === fundAssetValuation.assetValuationId && fundAssetValuation.preferredValuation))
);

export const selectAssetValuationsForAsset = createSelector(
    selectAssetState,
    selectSelectFundAssetValuations,
    (state, fundAssetValuations) => state.selectedAssetValuationsForAsset.data
        .filter(assetValuation => fundAssetValuations.map(fav => fav.assetValuationId).includes(assetValuation.id ?? -1))
);

export const selectSelectedAssetNotes = createSelector(
    selectAssetState,
    selectSelectedAsset,
    (state, asset) => {
        const baseContext = `/assets/asset_id=${asset.data.id}/`;
        return {
            notes: state.selectedAssetNotes.data,
            baseContext
        };
    }
);

export const selectSelectedAssetNotesForValuation = createSelector(
    selectAssetState,
    selectSelectedAsset,
    selectSelectedAssetValuation,
    (state, asset, valuation) => {
        const baseContext = `/assets/asset_id=${asset.data.id}/asset_valuation_id=${valuation.data.id}/`;
        return {
            notes: state.selectedAssetNotes.data,
            baseContext
        };
    }
);

export const selectGeneralPartnerValuationEdit = createSelector(
    selectAssetForms,
    (state) => state.generalPartnerValuationIsEditable
);

export const selectGeneralPartnerValuation = createSelector(
    selectGeneralPartnerValuationEdit,
    selectAssetState,
    (gpEdit, state) => (gpEdit) ?
        state.selectedGeneralPartnerValuation.generalPartnerValuationCalc :
        state.selectedGeneralPartnerValuation.generalPartnerValuation
);

export const selectGeneralPartnerValuationTableData = createSelector(
    selectGeneralPartnerValuation,
    (state) => {
        interface Row {
            type: string;
            name: string;
            fundLevelValue: TraceableDate | TraceableCode | TraceableMoney | TraceablePercent | TraceableMultiple | undefined;
        }

        let gpValuationTableRows: Row[] = [];

        if (state.data) {
            const fundlevel: FundInvestment = state.data;
            gpValuationTableRows = [
                {type: "remainingCost", name: "Remaining Cost", fundLevelValue: fundlevel.remainingCost},
                {type: "mezzanine", name: "Mezzanine", fundLevelValue: fundlevel.mezzanine},
                {type: "preferredEquity", name: "Preferred Equity", fundLevelValue: fundlevel.preferredEquity},
                {type: "commonEquity", name: "Common Equity", fundLevelValue: fundlevel.commonEquity},
                {type: "nav", name: "NAV Equity", fundLevelValue: fundlevel.nav},
                {type: "realizedCost", name: "Realized Cost", fundLevelValue: fundlevel.realizedCost},
                {type: "realizedGains", name: "Realized Gains", fundLevelValue: fundlevel.realizedGains},
                {type: "totalRealized", name: "Total Realized", fundLevelValue: fundlevel.totalRealized},
                {type: "totalCost", name: "Total Cost", fundLevelValue: fundlevel.totalCost}
            ];
        }
        return gpValuationTableRows;
    }
);

export const selectGeneralPartnerValuationView = createSelector(
    selectGeneralPartnerValuation,
    selectGeneralPartnerValuationTableData,
    selectGeneralPartnerValuationEdit,
    (gpValuation, tableData, isEditable) => ({
        gpValuation,
        tableData,
        isEditable
    })
);

export const selectAssetSwot = createSelector(
    selectSelectedAsset,
    (state) => state.data.swot
);

export const selectAssetSwotEdit = createSelector(
    selectAssetForms,
    (state) => state.assetSwotIsEditable
);

export const selectGeneratedSwotAnalysis = createSelector(
    selectAssetState,
    (state) => state.generatedSwotAnalysis.gptSwot
);

export const selectGptSwotIsEditable = createSelector(
    selectAssetState,
    (state) => state.generatedSwotAnalysis.gptSwotIsEditable
);

export const selectEndMarketsCustomers = createSelector(
    selectSelectedAsset,
    (state) => state.data.endMarketsCustomers
);

export const selectEndMarketsCustomersEdit = createSelector(
    selectAssetForms,
    (state) => state.endMarketsCustomersIsEditable
);

export const selectGeneratedEndMarketsDescription = createSelector(
    selectAssetState,
    (state) => state.generatedEndMarketsDescription.gptEndMarketsText
);

export const selectGptEndMarketsIsEditable = createSelector(
    selectAssetState,
    (state) => state.generatedEndMarketsDescription.gptEndMarketsIsEditable
);

export const selectAssetFinancialHistory = createSelector(
    selectAssetState,
    (state) => state.selectedAssetFinancialHistory
);

export const selectBaselineAssetFinancialHistory = createSelector(
    selectAssetState,
    (state) => state.baselineAssetFinancialHistory
);

export const selectAssetFinancialHistoryEdit = createSelector(
    selectAssetForms,
    (state) => state.assetFinancialHistoryIsEditable
);

export const selectExitsEdit = createSelector(
    selectAssetForms,
    (state) => state.exitsIsEditable
);

export const selectSelectedAssetExitNotes = createSelector(
    selectSelectedAssetNotesForValuation,
    (notesForValuation) => {
        const baseContext = notesForValuation.baseContext + "exits/";
        return {
            notes: notesForValuation.notes.filter(n => n.context === baseContext),
            baseContext
        };
    }
);

export const selectShareholdersForDate = createSelector(
    selectAssetState,
    (state) => state.selectedShareholdersForDate
);

export const selectShareholders = createSelector(
    selectAssetState,
    (state) => state.selectedShareholders.data
);

export const selectShareholderStructureEdit = createSelector(
    selectAssetForms,
    (state) => state.shareholderStructureIsEditable
);
