import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {selectFundPartnershipInvestment, selectIsAnyFundFormInEditMode, selectSelectedFundId} from "../../store/fund/fund.selectors";
import {Store} from "@ngrx/store";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {FundInvestmentNewDialogComponent} from "../fund-investment-new-dialog/fund-investment-new-dialog.component";
import {CodeTableEnum} from "../../../shared/model/code";
import {selectSelectedFundReport} from "../../store/fund-report/fund-report.selectors";
import {selectSelectedFundValuationId} from "../../store/fund-valuation/fund-valuation.selectors";
import {selectSelectedFundInvestmentsForFundReport} from "../../store/fund-investment/fund-investment.selectors";
import {map, Subscription} from "rxjs";
import {PartnershipInvestmentActions} from "../../store/fund.actions";
import {FormBuilder} from "@angular/forms";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {PartnershipInvestment} from "../../models/partnership-investment";
import {EMPTY_PARTNERSHIP_INVESTMENT} from "../../store/fund.reducer";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {DateUtil} from "../../../shared/utils/date-util";
import {TraceableDate, TraceableMoney, TraceableText} from "../../../shared/model/traceable";
import {AssetDetailsDialogComponent, assetDialogExpandType} from "./asset-details-dialog/asset-details-dialog.component";
import {MatSort} from "@angular/material/sort";
import {Router} from "@angular/router";
import {AssetValuationActions} from "../../../asset/store/asset.actions";
import {ValuationTypeEnum} from "../../../asset/models/asset-valuation/valuation-type.enum";
import {selectSelectedDealId} from "../../../deal/store/deal/deal.selectors";

@Component({
    selector: "valumize-fund-partnership-investments",
    templateUrl: "./fund-partnership-investments.component.html",
    styleUrls: ["./fund-partnership-investments.component.scss"]
})
export class FundPartnershipInvestmentsComponent implements OnInit, OnDestroy {

    @ViewChild("partnershipInvestmentSort") partnershipInvestmentSort = new MatSort();

    subscriptions: Subscription[] = [];

    fundId$ = this.store.select(selectSelectedFundId);
    selectedFundReport$ = this.store.select(selectSelectedFundReport);
    partnershipInvestments$ = this.store.select(selectFundPartnershipInvestment);
    selectedFundInvestmentsForFundReport$ = this.store.select(selectSelectedFundInvestmentsForFundReport);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);

    persistedPartnershipInvestment: PartnershipInvestment = EMPTY_PARTNERSHIP_INVESTMENT;
    dealId?: number;
    fundValuationId?: number;
    fundReportDate?: TraceableDate;
    fundClosingDate?: TraceableDate;
    isEditable = false;
    panelOpenState = false;

    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableGpIndication = CodeTableEnum.ASSET_GPINDICATION;
    codeTableAssessment = CodeTableEnum.SHARED_ASSESSMENT;

    protected readonly assetDialogExpandType = assetDialogExpandType;

    partnershipInvestmentForm = this.formBuilder.group({
        netCurrentAssetsGpNAV: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        gPCarryReserveGpNAV: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        bidPriceAdjustmentSelectedBid: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        netCurrentAssetsExitDateAssumption: this.formBuilder.control<Date | null>(null),
        netCurrentAssetsCashflowLow: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        netCurrentAssetsCashflowBase: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        netCurrentAssetsCashflowHigh: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]})
    });

    constructor(
        private readonly store: Store,
        public dialog: MatDialog,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router
    ) {
    }

    ngOnInit() {
        // Todo: maybe not needed anymore
        this.subscriptions.push(this.store.select(selectSelectedFundValuationId).pipe(map(fundValuationId => {
            this.fundValuationId = fundValuationId;
        })).subscribe());

        this.subscriptions.push(this.store.select(selectSelectedDealId).pipe(map(dealId => {
            this.dealId = dealId;
        })).subscribe());

        this.subscriptions.push(this.store.select(selectFundPartnershipInvestment).pipe(map((partnershipInvestment) => {
            this.persistedPartnershipInvestment = partnershipInvestment.partnershipInvestment;
            this.fundReportDate = partnershipInvestment.fundReportDate;
            this.fundClosingDate = partnershipInvestment.fundClosingDate;
            this.isEditable = partnershipInvestment.partnershipInvestmentIsEditable;

            const netCurrentAssetsRow = partnershipInvestment.tableDatasource.find(row => row.company.text === "Net Current Assets");
            const gpCarryReserveRow = partnershipInvestment.tableDatasource.find(row => row.company.text === "GP Carry Reserve");
            const bidPriceAdjustmentRow = partnershipInvestment.tableDatasource.find(row => row.company.text === "Bid Price Adjustment");

            this.partnershipInvestmentForm.patchValue({
                netCurrentAssetsGpNAV: DecimalFormatPipe.transformFromMillionsNum(netCurrentAssetsRow?.gpNAV?.amount ?? undefined),
                gPCarryReserveGpNAV: DecimalFormatPipe.transformFromMillionsNum(gpCarryReserveRow?.gpNAV?.amount ?? undefined),
                bidPriceAdjustmentSelectedBid: DecimalFormatPipe.transformFromMillionsNum(bidPriceAdjustmentRow?.selectedBid?.amount ?? undefined),
                netCurrentAssetsExitDateAssumption: DateUtil.toJsDate(netCurrentAssetsRow?.exitDateAssumption?.date ?? undefined),
                netCurrentAssetsCashflowLow: DecimalFormatPipe.transformFromMillionsNum(netCurrentAssetsRow?.cashflowLow?.amount ?? undefined),
                netCurrentAssetsCashflowBase: DecimalFormatPipe.transformFromMillionsNum(netCurrentAssetsRow?.cashflowBase?.amount ?? undefined),
                netCurrentAssetsCashflowHigh: DecimalFormatPipe.transformFromMillionsNum(netCurrentAssetsRow?.cashflowHigh?.amount ?? undefined)
            });
        })).subscribe());
    }

    isEditableNavColumnCell(name: string): boolean {
        const editableRows = ["Net Current Assets", "GP Carry Reserve"];
        return editableRows.includes(name) && this.isEditable;
    }

    isEditableBidColumnCell(name: string): boolean {
        const editableRows = ["Bid Price Adjustment"];
        return editableRows.includes(name) && this.isEditable;
    }

    isEditableCell(name: string): boolean {
        const editableRows = ["Net Current Assets"];
        return editableRows.includes(name) && this.isEditable;
    }

    getDisplayScenarioValue(scenarioValue: TraceableMoney, ncaValue: TraceableMoney, assetId: number, rowName: TraceableText): TraceableMoney {
        if (assetId < 0 && rowName.text === "Net Current Assets") {
            return scenarioValue.amount !== null ? scenarioValue : ncaValue;
        }
        return scenarioValue;
    }

    save() {
        if (this.partnershipInvestmentForm.valid) {
            const form = this.partnershipInvestmentForm.getRawValue();
            const partnershipInvestmentToSave = {
                ...this.persistedPartnershipInvestment,
                gpCarryReserve: {
                    ...this.persistedPartnershipInvestment?.gpCarryReserve,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.gPCarryReserveGpNAV)
                },
                netCurrentAssets: {
                    ...this.persistedPartnershipInvestment?.netCurrentAssets,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.netCurrentAssetsGpNAV)
                },
                bidPriceAdjustment: {
                    ...this.persistedPartnershipInvestment?.bidPriceAdjustment,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.bidPriceAdjustmentSelectedBid)
                },
                netCurrentAssetsDate: {
                    ...this.persistedPartnershipInvestment?.netCurrentAssetsDate,
                    date: DateUtil.toIsoDate(form.netCurrentAssetsExitDateAssumption)
                },
                netCurrentAssetsLow: {
                    ...this.persistedPartnershipInvestment?.netCurrentAssetsLow,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.netCurrentAssetsCashflowLow)
                },
                netCurrentAssetsBase: {
                    ...this.persistedPartnershipInvestment?.netCurrentAssetsLow,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.netCurrentAssetsCashflowBase)
                },
                netCurrentAssetsHigh: {
                    ...this.persistedPartnershipInvestment?.netCurrentAssetsLow,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.netCurrentAssetsCashflowHigh)
                }
            };
            this.store.dispatch(PartnershipInvestmentActions.save({partnershipInvestment: partnershipInvestmentToSave}));
        }
    }

    editMode = () => this.store.dispatch(PartnershipInvestmentActions.edit());

    cancel = () => this.store.dispatch(PartnershipInvestmentActions.cancel());

    createFormControlName(name: string, columnName: string): string {
        return name
            .replace(/^\w|[A-Z]|\b\w/g, (word, index) =>
                index === 0 ? word.toLowerCase() : word.toUpperCase())
            .replace(/\s+/g, "")
            .concat(columnName);
    }

    displayValuationType(valuationType: string): string {
        if (valuationType === ValuationTypeEnum.FinancialOperationalValuation) {
            return "Operational";
        } else if (valuationType === ValuationTypeEnum.DiscountMultipleValuation) {
            return "Discount";
        }
        return "-";
    }

    styleRowImportant(row: any): string {
        const importantRowTypes = ["Total"];
        return (importantRowTypes.includes(row.company.text)) ? "row-important" : "";
    }

    styleRowBorderTop(row: any): string {
        const borderTopRowTypes = ["Total Of Unrealized Assets", "Net Current Assets"];
        return (borderTopRowTypes.includes(row.company.text)) ? "row-top-border" : "";
    }

    styleRowNotClickable(row: any): string {
        const notClickableRowTypes = ["Total Of Unrealized Assets", "Net Current Assets", "GP Carry Reserve", "Bid Price Adjustment", "Total"];
        return (notClickableRowTypes.includes(row.company.text)) ? "not-clickable" : "";
    }

    styleRowExited(row: any): string {
        const exitDateAssumption = DateUtil.fromIsoDate(row.exitDateAssumption.date);
        const fundReportDate = DateUtil.fromIsoDate(this.fundReportDate?.date);
        const fundClosingDate = DateUtil.fromIsoDate(this.fundClosingDate?.date);
        if (!fundReportDate || !fundClosingDate || !exitDateAssumption) {
            return "";
        }
        return exitDateAssumption > fundReportDate && exitDateAssumption < fundClosingDate ? "exited" : "";
    }

    setBackgroundColor(stabilityOfCFs: string): string {
        switch (stabilityOfCFs) {
            case "LOW":
                return "stability-of-cfs low";
            case "MEDIUM":
                return "stability-of-cfs medium";
            case "HIGH":
                return "stability-of-cfs high";
            default:
                return "";
        }
    }

    openManageFundInvestmentsDialog(fundId: number, fundReportId: number) {
        const dialogRef = this.dialog.open(FundInvestmentNewDialogComponent);
        dialogRef.componentInstance.fundId = fundId;
        dialogRef.componentInstance.fundReportId = fundReportId;
        dialogRef.componentInstance.fundValuationId = this.fundValuationId;
    }

    openAssetDMVValuationDialog(
        assetId: number,
        assetValuationId: number,
        preferredValuationType: string,
        fundId: number,
        fundReportId: number,
        fundValuationId: number,
        fundInvestmentId: number,
        expanded: assetDialogExpandType
    ) {
        if (!this.isEditable) {
            const valuationTypeEnum = Object.values(ValuationTypeEnum).find(enumValue => enumValue === preferredValuationType);

            const dialogConfig = new MatDialogConfig();
            dialogConfig.minWidth = "1400px";

            const dialogDMVRef = this.dialog.open(AssetDetailsDialogComponent, dialogConfig);
            dialogDMVRef.componentInstance.assetId = assetId;
            dialogDMVRef.componentInstance.fundId = fundId;
            dialogDMVRef.componentInstance.fundReportId = fundReportId;
            dialogDMVRef.componentInstance.fundValuationId = fundValuationId;
            dialogDMVRef.componentInstance.assetValuationId = assetValuationId;
            dialogDMVRef.componentInstance.preferredValuationType = valuationTypeEnum ?? ValuationTypeEnum.DiscountMultipleValuation;
            dialogDMVRef.componentInstance.fundInvestmentId = fundInvestmentId;
            dialogDMVRef.componentInstance.dealId = this.dealId;

            dialogDMVRef.componentInstance.expanded = expanded;
            dialogDMVRef.afterClosed().subscribe(() => {
                this.store.dispatch(PartnershipInvestmentActions.loadafteredit({fundId, fundReportId, fundValuationId}));
            });
        }
    }

    routeToAssetDetails(
        assetId: number,
        assetValuationId: number,
        preferredValuationType: string,
        fundId: number,
        fundReportId: number,
        fundValuationId: number,
        fundInvestmentId: number,
    ) {
        const valuationTypeEnum = Object.values(ValuationTypeEnum).find(enumValue => enumValue === preferredValuationType);
        if (valuationTypeEnum) {
            this.store.dispatch(AssetValuationActions.setpreferredvaluationtype({valuationType: valuationTypeEnum}));
        }

        this.router.navigate(["../../assets/" + assetId], {
            queryParams: {
                fundId,
                fundReportId,
                fundValuationId,
                fundInvestmentId,
                assetValuationId,
                dealId: this.dealId
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
