import {Component, Input, OnInit} from "@angular/core";
import {EMPTY_CODE, TraceableCode} from "../../model/traceable";
import {FormControl} from "@angular/forms";
import {Code, CodeTableEnum} from "../../model/code";
import {selectCodesFromTable} from "../../store/shared.selectors";
import {Store} from "@ngrx/store";
import {combineLatest, map, Observable, startWith} from "rxjs";
import {DateUtil} from "../../utils/date-util";

@Component({
    selector: "valumize-mergeable-select-code",
    templateUrl: "./mergeable-select-code.component.html"
})
export class MergeableSelectCodeComponent implements OnInit {

    @Input() label = "";
    @Input() control: FormControl<string | null> = new FormControl<string | null>(null);
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() code: TraceableCode = EMPTY_CODE;
    @Input() baselineCode: TraceableCode = {source: "", modDate: DateUtil.isoTimestamp(), refDocument: "", userId: undefined, code: "GROWTH"};
    @Input() codeTable!: CodeTableEnum;
    @Input() searchable = false;

    selectCode$: Observable<Code[]> | undefined;
    filteredCode$: Observable<Code[]> | undefined;


    constructor(private readonly store: Store) {
    }

    ngOnInit(): void {
        this.selectCode$ = this.store.select(selectCodesFromTable(this.codeTable));

        this.filteredCode$ = combineLatest([
            this.control.valueChanges.pipe(startWith("")),
            this.selectCode$
        ]).pipe(
            map(([value, codes]) =>
                value ? codes.filter(code => code.translations.en.toLowerCase().includes(value.toLowerCase())) : codes
            )
        );
    }

    overwriteValue() {
        if (this.isEditable && this.baselineCode.code) {
            this.control.setValue(this.baselineCode.code);
        }
    }

    getChipColor() {
        if (this.control.value === this.baselineCode.code && this.baselineCode.code !== this.code.code) {
            return "chip-overwritten-value";
        } else if (this.baselineCode.code === this.code.code) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }

    styleRequiredFormFieldWithAsterisk(): string {
        return this.searchable && this.label === "Currency" ? "required-asterisk" : "";
    };
}
