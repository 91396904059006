import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ImportDatasetDialogComponent} from "../import-dataset-dialog/import-dataset-dialog.component";
import {Store} from "@ngrx/store";
import {DatasetActions} from "../store/import.actions";
import {selectDatasets} from "../store/import.selectors";
import {map, Subscription} from "rxjs";
import {MatOptionSelectionChange} from "@angular/material/core";
import {Router} from "@angular/router";
import {ConfirmationComponent} from "../../shared/components/confirmation/confirmation.component";

@Component({
    selector: "valumize-import-overview-page",
    templateUrl: "./import-overview-page.component.html",
    styleUrls: ["./import-overview-page.component.scss"]
})
export class ImportOverviewPageComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    columns = ["source", "arrow", "target", "prepareForMerge", "delete"];
    datasets$ = this.store.select(selectDatasets);
    sourceTargetMap: Map<string, string> = new Map<string, string>();

    defaultTargetDataset = "master";
    panelOpenState = false;

    constructor(
        private readonly store: Store,
        private readonly dialog: MatDialog,
        private readonly router: Router
    ) {
        this.store.dispatch(DatasetActions.load());
    }

    ngOnInit() {
        this.subscription = this.store.select(selectDatasets).pipe(map((datasets) => {
            datasets.data.forEach(dataset => this.sourceTargetMap.set(dataset, this.defaultTargetDataset));
        })).subscribe();
    }

    filterMasterDataset(datasets: string[]): string[] {
        return datasets.filter(dataset => dataset !== "master");
    }

    filterSourceDataset(datasets: string[], source: string): string[] {
        return datasets.filter(dataset => dataset !== source);
    }

    updateSourceTargetMap(source: string, event: MatOptionSelectionChange<string>): void {
        if (event.isUserInput) {
            this.sourceTargetMap.set(source, event.source.value);
        }
    }

    openImportDatasetDialog(): void {
        this.dialog.open(ImportDatasetDialogComponent);
    }

    routeToMergePage(dataset: string): void {
        const target = this.sourceTargetMap.get(dataset);
        if (!target) {
            return;
        }
        this.store.dispatch(DatasetActions.setsourceandtarget({source: dataset, target}));
        this.router.navigate(["/funds"]);
    }

    deleteDataset(dataset: string): void {
        const dialogRef = this.dialog.open(ConfirmationComponent);
        dialogRef.componentInstance.confirmMessage = `Are you sure you want to delete the dataset ${dataset}?`;

        dialogRef.afterClosed().pipe(map(result =>
            !!result ? this.store.dispatch(DatasetActions.delete({dataset})) : null
        )).subscribe();
    }

    refreshDatasets(): void {
        this.store.dispatch(DatasetActions.load());
    }

    ngOnDestroy(): void {
        this.store.dispatch(DatasetActions.clearstate());
        this.subscription?.unsubscribe();
    }
}
