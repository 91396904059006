/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {ReportService} from "../../services/report.service";
import {ProjectReportActions} from "../deal.actions";
import {catchError, exhaustMap, map, of} from "rxjs";

export const generateProjectReport = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), reportService = inject(ReportService)) => {
        return actions$.pipe(
            ofType(ProjectReportActions.generate),
            exhaustMap((action) => {
                const dealIds = action.deals.map(deal => deal.id).filter(dealId => dealId !== undefined) as number[];
                return reportService.getProjectReport(action.projectName, dealIds, action.projectClosingDate, action.firstRoundOffersDeadline).pipe(
                    map(() => ProjectReportActions.generatesuccess({successMsg: "Project report generated successfully"})),
                    catchError((error: { status: number; message: string }) => {
                        if (error.status === 404) {
                            return of(ProjectReportActions.generateerror({errorMsg: "404: Template for requested report does not exist yet"}));
                        }
                        return of(ProjectReportActions.generateerror({errorMsg: `Report could not be generated. Details: ${error.message}`}));
                    })
                );
            })
        );
    },
    {functional: true}
);

export const getAllProjectReports = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), reportService = inject(ReportService)) => {
        return actions$.pipe(
            ofType(ProjectReportActions.loadall),
            exhaustMap((action) => {
                return reportService.getAllProjectReports().pipe(
                    map((documentMetadata) => ProjectReportActions.loadallsuccess({documentMetadata})),
                    catchError((error: { message: string }) =>
                        of(ProjectReportActions.loadallerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadAllReportsAfterGeneratingReport = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(ProjectReportActions.generatesuccess),
            map(() => ProjectReportActions.loadall())
        );
    },
    {functional: true}
);
