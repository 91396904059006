<ng-container *ngIf="reports$ | async as reports">
    <h3>Previous Reports</h3>
    <section class="table-container" tabindex="0">
        <table mat-table [dataSource]="reports">
            <ng-container matColumnDef="name" sticky>
                <th mat-header-cell *matHeaderCellDef>Document</th>
                <td mat-cell *matCellDef="let element">
                    <a valumizePreauthorizedLink href="{{documentUrl(element)}}">
                        <mat-icon class="icon-size">attach_file</mat-icon>
                        <span class="link-align">{{element.name}}</span>
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="creationDate" sticky>
                <th mat-header-cell *matHeaderCellDef>Creation Date</th>
                <td mat-cell *matCellDef="let element">{{element.creationDate | date}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef=" let row; columns: columns"></tr>
        </table>
    </section>
</ng-container>
