import {DateTime} from "luxon";

/**
 * Utils for handling date and time conversions.
 *
 * For most business dates, such as a report date, fund investment date, etc. the exact time of the day is normally unknown.
 * An investment on '2023-09-30' (in New York) might have occurred early in the morning or late in the evening.
 * As such, these dates don't really have a timezone.
 * These dates should always be represented as ISO 8601 dates, without time component.
 * E.g. '2023-09-30'
 *
 * Timestamps are used for auditing events.
 * These auditing timestamps are always created by the backend.
 * In the frontend, we sometimes must set these to satisfy a non-null constraint, but default/dummy values will usually suffice.
 * Timestamps are represented as ISO 8601 with a time component, e.g. '2023-10-02T10:55:23'.
 * The backend omits the timezone when sending auditing timestamps.
 * The servers configured locale (usually Europe/Zurich) is implied.
 */
export class DateUtil {

    /**
     * Converts an ISO 8601 date (or timestamp) into a JS Date object representing the date component only,
     * ignoring any time or timezone information. The resulting JS Date object will have the time set to the
     * start of the day in the browser's current default timezone.
     *
     * Converting an ISO 8601 date (without time component) produces a JS Date that can be used as input for NG Material date
     * pickers.
     *
     * @param isoString ISO 8601 compliant date or timestamp, e.g. '2023-08-28' or '2023-08-28T16:45:32' (without time zone) or '2023-08-28T16:45:32-02:00'
     */
    static toJsDate(isoString: string | null | undefined): Date | undefined {
        return DateUtil.fromIsoDate(isoString)?.toJSDate();
    }

    /**
     * Returns an ISO 8601 compliant string representation of the given date's (or timestamp's) date component.
     * That is, any time and timezone information is cut off and only the component representing the semantic date is returned.
     *
     * This method may be used to convert a JS Date with the browsers default timezone (as produces by NG Material date pickers)
     * to a semantic (timezone independent) date representation.
     *
     * @param date date or timestamp
     */
    static toIsoDate(date: Date | DateTime | null | undefined): string | undefined {
        if (!date) {
            return undefined;
        }

        if (date instanceof DateTime) {
            return date.toISODate() ?? undefined;
        }

        return DateTime.fromJSDate(date).toISODate() ?? undefined;
    }

    /**
     * Converts an ISO 8601 date into a Luxon DateTime object, semantically representing the given date in
     * the browsers local timezone. The time and timezone components will be ignored for this conversion.
     *
     * This function produces a JS Date that can be used as input for NG Material date pickers to show the semantic/logical date.
     *
     * @param isoString ISO 8601 compliant date e.g. '2023-08-28'
     */
    static fromIsoDate(isoString: string | null | undefined): DateTime | undefined {
        if (!isoString) {
            return undefined;
        }

        const isoDate = DateTime.fromISO(isoString).toISODate(); // cut-off any time or timezone component
        return !!isoDate ? DateTime.fromISO(isoDate) : undefined; // semantic representation of the date in browsers current timezone
    }

    /**
     * Converts an ISO 8601 timestamp into a Luxon DateTime object, representing the given moment in the browsers local time.
     * If the timestamp doesn't contain a timezone offset, "Europe/Zurich" is implied and converted to the browsers local time.
     *
     * @param isoString ISO 8601 compliant timestamp, e.g. '2023-08-28T16:45:32' (without time zone) or '2023-08-28T16:45:32-02:00'
     */
    static fromIsoTimestamp(isoString: string | null | undefined): DateTime | undefined {
        const dateTime = !!isoString ? DateTime.fromISO(
            isoString,
            { zone: "Europe/Zurich", setZone: true } // FIXME: make configurable, resp. ask backend which timezone/local to use by default
        ).toLocal() : undefined;

        return dateTime && dateTime.isValid ? dateTime : undefined;
    }

    /**
     * Returns the current time as ISO 8601 string
     */
    static isoTimestamp(): string {
        return DateTime.now().toISO({includeOffset: false}) as string;
    }

    /**
     * Returns the current date (without time nor timezone components) as ISO 8601 string
     */
    static isoDate(): string {
        return DateTime.now().toISODate() as string;
    }

    /**
     * Returns a JS Date object, representing the current date
     */
    static jsDate(): Date {
        return DateUtil.toJsDate(this.isoDate()) as Date;
    }

    /**
     * Returns the most recent of the given dates
     *
     * @param dates dates
     */
    static mostRecent(...dates: (DateTime | undefined)[]): DateTime | undefined {
        const filtered = dates
            .filter(date => date !== undefined)
            .filter(date => date?.isValid) as DateTime[];  // Filter out any undefined and invalid dates

        if (filtered.length === 0) {
            return undefined;
        }

        return filtered.reduce((a, b) => a > b ? a : b);
    }

}
