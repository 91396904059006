import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectAssetValuationsForAsset, selectPreferredAssetValuationForAsset} from "../../store/asset/asset.selectors";
import {AssetOperationalValuationActions, AssetValuationActions} from "../../store/asset.actions";
import {AssetValuation} from "../../models/asset-valuation/asset-valuation";
import {selectSelectedFundValuation, selectSelectedFundValuationReportDate} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {TraceableDate} from "../../../shared/model/traceable";
import {map, Subscription} from "rxjs";
import {FundValuation} from "../../../fund/models/fund-valuation";
import {FundValuationActions} from "../../../fund/store/fund.actions";
import {FundAssetValuation} from "../../../fund/models/fund-asset-valuation";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ValuationTypeEnum} from "../../models/asset-valuation/valuation-type.enum";
import {CodeTableEnum} from "../../../shared/model/code";


@Component({
    selector: "valumize-asset-valuation-forecast",
    templateUrl: "./asset-valuation-forecast.component.html",
    styleUrls: ["./asset-valuation-forecast.component.scss"]
})
export class AssetValuationForecastComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() assetId?: number;
    @Input() fundId?: number;

    subscriptions: Subscription[] = [];

    selectedAssetValuationsForAsset$ = this.store.select(selectAssetValuationsForAsset);
    selectedPreferredValuation$ = this.store.select(selectPreferredAssetValuationForAsset);

    fundValuationReportDate?: TraceableDate;
    fundValuation?: FundValuation;
    selectedIndex = 0;

    protected readonly valuationTypeEnum = ValuationTypeEnum;
    codeTableValuationType = CodeTableEnum.ASSET_VALUATIONTYPE;

    constructor(private readonly store: Store, private readonly cdr: ChangeDetectorRef) {
    }

    ngOnInit() {

        this.subscriptions.push(this.store.select(selectSelectedFundValuationReportDate).pipe(map(reportDate => {
            this.fundValuationReportDate = reportDate;
        })).subscribe());

        this.subscriptions.push(this.store.select(selectSelectedFundValuation).pipe(map(fundValuation => {
            this.fundValuation = fundValuation.data;
        })).subscribe());
    }

    ngAfterViewInit() {
        this.subscriptions.push(
            this.store.select(selectPreferredAssetValuationForAsset).pipe(map(preferredValuation => {
                if (preferredValuation) {
                    this.selectedIndex = preferredValuation.valuationType.code === ValuationTypeEnum.DiscountMultipleValuation ? 0 : 1;
                    this.cdr.detectChanges();
                }
            })).subscribe()
        );
    }

    tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
        this.selectedIndex = tabChangeEvent.index;
    };

    valuationTypeExists(valuations: AssetValuation[], valuationType: string) {
        return valuations.some(v => v.valuationType.code === valuationType);
    }

    filterExisting(valuations: AssetValuation[]) {
        return Object.values(ValuationTypeEnum).filter(type => !this.valuationTypeExists(valuations, type));
    }

    createAssetValuation(valuationType: string) {
        if (!this.assetId || !this.fundValuationReportDate?.date) {
            return;
        }
        this.store.dispatch(AssetOperationalValuationActions.create({
            assetId: this.assetId,
            reportDate: this.fundValuationReportDate.date,
            valuationType
        }));
    }

    changePreferredType(valuationType: string, assetValuations: AssetValuation[]) {
        const valuationTypeEnum = Object.values(ValuationTypeEnum).find(enumValue => enumValue === valuationType);
        if (valuationTypeEnum) {
            this.store.dispatch(AssetValuationActions.setpreferredvaluationtype({valuationType: valuationTypeEnum}));
        }

        const filteredAssetValuations = assetValuations.filter(av => av.reportDate.date === this.fundValuationReportDate?.date);
        const preferredAssetValuation = filteredAssetValuations.find(av => av.valuationType.code === valuationType);

        if (this.fundId && this.fundValuation && preferredAssetValuation) {
            const updatedFundAssetValuation = this.fundValuation.fundAssetValuations.map(fav => {
                if (filteredAssetValuations.map(av => av.id).includes(fav.assetValuationId)) {
                    const isPreferredAssetValuation = preferredAssetValuation.id === fav.assetValuationId;
                    const updatedFav: FundAssetValuation = {
                        ...fav,
                        preferredValuation: isPreferredAssetValuation
                    };
                    return updatedFav;
                }
                return fav;
            });

            const fundValuationToSave: FundValuation = {
                ...this.fundValuation,
                fundAssetValuations: updatedFundAssetValuation
            };
            this.store.dispatch(FundValuationActions.save({fundId: this.fundId, fundValuation: fundValuationToSave}));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
