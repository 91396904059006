<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title>Company Valuation (Forecast)</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <ng-container *ngIf="selectedAssetValuationsForAsset$ | async as assetValuations">
            <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
                <mat-tab tabindex="0" *ngIf="valuationTypeExists(assetValuations, valuationTypeEnum.DiscountMultipleValuation)" label="Discount Multiple Valuation"
                         data-test="tab-discount-multiple-valuation">
                    <valumize-asset-discount-multiple-valuation></valumize-asset-discount-multiple-valuation>
                </mat-tab>
                <mat-tab tabindex="1" *ngIf="valuationTypeExists(assetValuations, valuationTypeEnum.FinancialOperationalValuation)" label="Financial Operational Valuation"
                         data-test="tab-operational-valuation">
                    <valumize-asset-financial-operational-valuation [assetId]="assetId"></valumize-asset-financial-operational-valuation>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="detail-card-actions">
        <ng-container *ngIf="selectedAssetValuationsForAsset$ | async as assetValuations">
            <ng-container *ngIf="selectedPreferredValuation$ | async as preferredValuation">
                <ng-container *ngIf="assetValuations.length > 1">
                    <mat-form-field class="calculation-base-dropdown">
                        <mat-label>Calculation Base</mat-label>
                        <select #selectedElement (change)="changePreferredType(selectedElement.value, assetValuations)" matNativeControl>
                            <option *ngFor="let valuation of assetValuations"
                                    [value]="valuation.valuationType.code"
                                    [selected]="valuation.id === preferredValuation.id">
                                {{valuation.valuationType.code | codeFormat: codeTableValuationType | async}}
                            </option>
                        </select>
                    </mat-form-field>
                </ng-container>
                <button *ngIf="filterExisting(assetValuations).length !== 0" [matMenuTriggerFor]="dropdownAddValuation" mat-stroked-button color="primary"
                        data-test="new-valuation">
                    <mat-icon>add</mat-icon>
                    Valuation
                </button>
                <mat-menu #dropdownAddValuation>
                    <ng-container *ngFor="let valuationType of filterExisting(assetValuations)">
                        <button mat-menu-item (click)="createAssetValuation(valuationType)">
                            <mat-icon>add</mat-icon>
                            Add {{valuationType | codeFormat: codeTableValuationType | async}}
                        </button>
                    </ng-container>
                </mat-menu>
            </ng-container>
        </ng-container>
    </mat-card-actions>
</mat-card>
