<form [formGroup]="rationaleForm">
    <mat-card class="detail-card" style="padding: unset">
        <mat-card-header class="detail-card-header">
            <mat-card-title>Valuation Rationale</mat-card-title>
        </mat-card-header>
        <mat-card-content class="detail-card-content">
            <div *ngIf="!rationaleIsEditable && assetValuation.rationale.text; else noRationale" class="line-breaks">
                {{assetValuation.rationale.text}}
            </div>

            <ng-template #noRationale>
                <div *ngIf="!rationaleIsEditable" class="no-description" style="color: grey">
                    Please add a valuation rationale!
                </div>
            </ng-template>

            <mat-form-field appearance="fill" style="display: unset" *ngIf="rationaleIsEditable">
                                    <textarea class="line-breaks"
                                              data-test="rationale"
                                              formControlName="rationale"
                                              matInput
                                              style="height: 250px">
                                    </textarea>
            </mat-form-field>
        </mat-card-content>

        <mat-card-actions class="detail-card-actions">
            <button (click)="edit()"
                    *ngIf="!rationaleIsEditable"
                    [disabled]="(isEditDisabled$ | async)!"
                    color="primary"
                    data-test="edit-rationale-button"
                    mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="save()"
                    *ngIf="rationaleIsEditable"
                    [disabled]="rationaleForm.invalid"
                    color="primary"
                    data-test="save-rationale-button"
                    mat-icon-button>
                <mat-icon>save</mat-icon>
            </button>
            <button (click)="cancel()"
                    *ngIf="rationaleIsEditable"
                    color="primary"
                    data-test="cancel-rationale-button"
                    mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
</form>
