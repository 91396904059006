<mat-card class="detail-card" *ngIf="fund$ | async as fundDetail">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Fund Details</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <form [formGroup]="fundDetailForm">
            <div class="key-value-pair-section">
                <div class="key-value-pair-column" style="flex: 1;">
                    <div>
                        <h3 data-test="subtitle-general">General</h3>
                        <div class="key-value-pair">
                            <div class="key-value-item">Name</div>
                            <div class="key-value-item">{{fundDetail.fund.name.text}}</div>
                        </div>
                        <div class="key-value-pair" *ngIf="reportDate$ | async as reportDate">
                            <div class="key-value-item">Report date</div>
                            <div class="key-value-item">{{reportDate | traceableFormat}}</div>
                        </div>
                        <valumize-mergeable-select-code
                            [label]="'Stage'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [codeTable]=codeTableStage
                            [code]=fundDetail.fund.stage
                            [baselineCode]=baselineFund.stage
                            [control]=fundDetailForm.controls.stage>
                        </valumize-mergeable-select-code>
                        <valumize-mergeable-select-code
                            [label]="'Currency'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [codeTable]=codeTableCurrency
                            [code]=fundDetail.fund.currencyIso
                            [baselineCode]=baselineFund.currencyIso
                            [control]=fundDetailForm.controls.currencyIso
                            [searchable]="true">
                        </valumize-mergeable-select-code>
                        <valumize-mergeable-select-multi-code
                            [label]="'Region'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [codeTable]=codeTableRegion
                            [codes]=fundDetail.fund.regions
                            [comparativeCodes]=baselineFund.regions
                            [control]=fundDetailForm.controls.regions>
                        </valumize-mergeable-select-multi-code>
                        <valumize-mergeable-select-code
                            [label]="'Country'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [codeTable]=codeTableCountry
                            [code]=fundDetail.fund.country
                            [baselineCode]=baselineFund.country
                            [control]=fundDetailForm.controls.country
                            [searchable]="true">
                        </valumize-mergeable-select-code>
                        <valumize-mergeable-date
                            [label]="'Vintage year'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.vintageYear
                            [baselineValue]=baselineFund.vintageYear
                            [control]=fundDetailForm.controls.vintageYear>
                        </valumize-mergeable-date>
                        <valumize-mergeable-date
                            [label]="'Fund ends'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.endYear
                            [baselineValue]=baselineFund.endYear
                            [control]=fundDetailForm.controls.endYear>
                        </valumize-mergeable-date>
                    </div>
                </div>
                <div class="key-value-pair-column" style="flex: 1;">
                    <h3>Fund Size</h3>
                    <div>
                        <valumize-mergeable-number-input
                            [label]="'This fund'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.size
                            [baselineValue]=baselineFund.size
                            [control]=fundDetailForm.controls.size
                            [type]="'currency'">
                        </valumize-mergeable-number-input>
                        <valumize-mergeable-number-input
                            [label]="'Main fund'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.mainFundSize
                            [baselineValue]=baselineFund.mainFundSize
                            [control]=fundDetailForm.controls.mainFundSize
                            [type]="'currency'">
                        </valumize-mergeable-number-input>
                    </div>
                    <div class="spacer"></div>
                    <h3>Management fee</h3>
                    <div>
                        <valumize-mergeable-number-input
                            [label]="'Management fee'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.managementFee
                            [baselineValue]=baselineFund.managementFee
                            [control]=fundDetailForm.controls.managementFee
                            [type]="'percent'">
                        </valumize-mergeable-number-input>
                        <valumize-mergeable-select-code
                            [label]="'Management fee calculation'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [codeTable]=codeTableFeeCalculationMethod
                            [code]=fundDetail.fund.managementFeeCalculationMethod
                            [baselineCode]=baselineFund.managementFeeCalculationMethod
                            [control]=fundDetailForm.controls.managementFeeCalculation>
                        </valumize-mergeable-select-code>
                        <div class="spacer"></div>
                        <valumize-mergeable-number-input
                            [label]="'Carry'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.carry
                            [baselineValue]=baselineFund.carry
                            [control]=fundDetailForm.controls.carry
                            [type]="'percent'">
                        </valumize-mergeable-number-input>
                        <valumize-mergeable-number-input
                            [label]="'Hurdle rate'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.hurdleRate
                            [baselineValue]=baselineFund.hurdleRate
                            [control]=fundDetailForm.controls.hurdleRate
                            [type]="'percent'">
                        </valumize-mergeable-number-input>
                    </div>
                </div>
                <div class="key-value-pair-column" style="flex: 1;">
                    <h3>Secondary Rates</h3>
                    <div>
                        <valumize-mergeable-number-input
                            [label]="'Secondary Management Fee'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.secondaryManagementFeeRate
                            [baselineValue]=baselineFund.secondaryManagementFeeRate
                            [control]=fundDetailForm.controls.secondaryManagementFee
                            [type]="'percent'">
                        </valumize-mergeable-number-input>
                        <valumize-mergeable-number-input
                            [label]="'Secondary Carry'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.secondaryCarryRate
                            [baselineValue]=baselineFund.secondaryCarryRate
                            [control]=fundDetailForm.controls.secondaryCarry
                            [type]="'percent'">
                        </valumize-mergeable-number-input>
                        <valumize-mergeable-number-input
                            [label]="'Secondary Hurdle Rate'"
                            [isEditable]=fundDetail.fundDetailIsEditable
                            [isDataset]=isDataset
                            [value]=fundDetail.fund.secondaryHurdleRate
                            [baselineValue]=baselineFund.secondaryHurdleRate
                            [control]=fundDetailForm.controls.secondaryHurdleRate
                            [type]="'percent'">
                        </valumize-mergeable-number-input>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions class="detail-card-actions">
        <button (click)="edit()"
                *ngIf="!fundDetail.fundDetailIsEditable"
                [disabled]="(isEditDisabled$ | async)!"
                color="primary"
                data-test="edit-button"
                mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <button (click)="save()"
                [disabled]="fundDetailForm.invalid"
                *ngIf="fundDetail.fundDetailIsEditable"
                color="primary"
                data-test="save-button"
                mat-icon-button>
            <mat-icon>save</mat-icon>
        </button>
        <button (click)="cancel()"
                *ngIf="fundDetail.fundDetailIsEditable"
                color="primary"
                data-test="cancel-button"
                mat-icon-button>
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>

