import {Component, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {Store} from "@ngrx/store";
import {map, Subscription} from "rxjs";
import {Note} from "src/app/shared/model/note";
import {AssetNoteActions, AssetOperationalValuationActions, AssetValuationActions} from "../../store/asset.actions";
import {selectExitsEdit, selectIsAnyAssetFormInEditMode, selectSelectedAssetExitNotes, selectSelectedAssetId} from "../../store/asset/asset.selectors";
import {selectSelectedAssetValuation} from "../../store/asset-valuation/asset-valuation.selectors";
import {selectSelectedFundInvestment} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {FundInvestmentActions} from "../../../fund/store/fund.actions";
import {selectSelectedFundReportId} from "../../../fund/store/fund-report/fund-report.selectors";
import {selectSelectedFund, selectSelectedFundId} from "../../../fund/store/fund/fund.selectors";
import {FundInvestment} from "../../../fund/models/fund-investement";
import {EMPTY_FUND_INVESTMENT} from "../../../fund/store/fund.reducer";
import {DateUtil} from "../../../shared/utils/date-util";
import {ConfirmationComponent} from "../../../shared/components/confirmation/confirmation.component";
import {MatDialog} from "@angular/material/dialog";
import {AssetValuation} from "../../models/asset-valuation/asset-valuation";
import {FundValuation} from "../../../fund/models/fund-valuation";
import {selectSelectedFundValuation} from "../../../fund/store/fund-valuation/fund-valuation.selectors";

@Component({
    selector: "valumize-asset-exit-information",
    templateUrl: "./asset-exit-information.component.html",
    styleUrls: ["./asset-exit-information.component.scss"]
})
export class AssetExitInformationComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    assetValuation$ = this.store.select(selectSelectedAssetValuation);
    fundInvestment$ = this.store.select(selectSelectedFundInvestment);
    fund$ = this.store.select(selectSelectedFund);
    assetId$ = this.store.select(selectSelectedAssetId);
    fundId$ = this.store.select(selectSelectedFundId);
    fundReportId$ = this.store.select(selectSelectedFundReportId);
    notes$ = this.store.select(selectSelectedAssetExitNotes);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    persistedFundInvestment: FundInvestment = EMPTY_FUND_INVESTMENT;
    fundValuation?: FundValuation;
    exitIsEditable = false;

    mostRecentModDate?: string;
    maxExitDate: Date | undefined;

    warningMessage = "";

    exitForm = this.formBuilder.group({
        entryDate: this.formBuilder.control<Date | null>(null),
        effectiveExitDate: this.formBuilder.control<Date | null>(null),
        exitDateAssumption: this.formBuilder.control<Date | null>(null),
        isRealized: this.formBuilder.control<boolean | undefined>(undefined)
    }, {validators: this.effectiveExitDateRangeValidator()});

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        private readonly dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.store.select(selectSelectedFundInvestment).pipe(map((fundInvestment) => {
            this.persistedFundInvestment = fundInvestment.data;

            const entryDate = DateUtil.toJsDate(fundInvestment.data.investmentDate.date);
            const effectiveExitDate = DateUtil.toJsDate(fundInvestment.data.exitDate.date);
            const isRealized = fundInvestment.data.isRealized.value;
            this.exitForm.patchValue({entryDate, effectiveExitDate, isRealized});

            this.mostRecentModDate = DateUtil.toIsoDate(DateUtil.mostRecent(
                DateUtil.fromIsoTimestamp(fundInvestment.data.investmentDate.modDate),
                DateUtil.fromIsoTimestamp(fundInvestment.data.exitDate.modDate),
                DateUtil.fromIsoTimestamp(fundInvestment.data.isRealized.modDate))
            );
        })).subscribe());

        this.subscriptions.push(this.store.select(selectSelectedFundValuation).pipe(map((fundValuation) => {
            this.fundValuation = fundValuation.data;
        })).subscribe());

        this.subscriptions.push(this.store.select(selectExitsEdit).pipe(map((isEditable) => {
            this.exitIsEditable = isEditable;
            if (isEditable) {
                this.exitForm.enable();
            } else {
                this.exitForm.disable();
            }
        })).subscribe());

        this.subscriptions.push(this.fund$.pipe(map(fund =>
            this.maxExitDate = DateUtil.toJsDate(fund.fund.endYear.date)
        )).subscribe());
    }


    editMode = () => this.store.dispatch(FundInvestmentActions.edit());

    validateRealizedFlag(assetId: number, fundId: number, fundReportId: number, assetValuation: AssetValuation) {
        const isRealized = this.exitForm.getRawValue().isRealized;
        const effectiveExitDate = this.exitForm.getRawValue().effectiveExitDate;
        const assetValuationReportDate = DateUtil.toJsDate(assetValuation.reportDate.date);
        const fundValuationReportDate = DateUtil.toJsDate(this.fundValuation?.reportDate.date);
        const valuationType = assetValuation.valuationType.code;

        this.warningMessage = "";

        if (!this.exitForm.valid || !assetId || !fundId || !fundReportId) {
            return;
        }

        if (isRealized === true && assetValuationReportDate) {
            const dialogRef = this.dialog.open(ConfirmationComponent);
            dialogRef.componentInstance.confirmMessage = `Changing this to 'Realized' will delete the current valuation. Do you want to proceed?`;

            dialogRef.afterClosed().pipe(map(result => {
                if (result) {
                    this.deleteValuationAndSaveInvestment(assetId, fundId, fundReportId, assetValuation);
                }
            })).subscribe();
            return;
        }

        if (isRealized === false && !assetValuationReportDate && fundValuationReportDate && valuationType) {
            this.store.dispatch(AssetOperationalValuationActions.create({assetId, reportDate: DateUtil.toIsoDate(fundValuationReportDate) as string, valuationType}));
            this.save(fundId, fundReportId);
            return;
        }

        const reportDateToCompare = assetValuationReportDate || fundValuationReportDate;

        if (isRealized === false && effectiveExitDate && reportDateToCompare && effectiveExitDate < reportDateToCompare) {
            this.warningMessage = "Discrepancy: Company marked as unrealized but exit date is before the report date.";
        }

        if (isRealized === true && effectiveExitDate && reportDateToCompare && effectiveExitDate > reportDateToCompare) {
            this.warningMessage = "Discrepancy: Company marked as realized but exit date is after the report date.";
        }

        this.save(fundId, fundReportId);
    }

    closeWarning() {
        this.warningMessage = "";
    }

    deleteValuationAndSaveInvestment(assetId: number, fundId: number, fundReportId: number, assetValuation: AssetValuation) {
        if (assetValuation.id) {
            this.store.dispatch(AssetValuationActions.delete({assetId, assetValuationId: assetValuation.id}));

            const fundInvestmentToUpdate: FundInvestment = {
                ...this.persistedFundInvestment,
                remainingCost: {
                    ...this.persistedFundInvestment.remainingCost,
                    amount: 0
                },
                mezzanine: {
                    ...this.persistedFundInvestment.mezzanine,
                    amount: 0
                },
                preferredEquity: {
                    ...this.persistedFundInvestment.preferredEquity,
                    amount: 0
                },
                commonEquity: {
                    ...this.persistedFundInvestment.commonEquity,
                    amount: 0
                }
            };
            this.save(fundId, fundReportId, fundInvestmentToUpdate);
        }
    }

    save(fundId: number, fundReportId: number, fundInvestment?: FundInvestment) {
        const form = this.exitForm.getRawValue();
        const entryDateIso = DateUtil.toIsoDate(form.entryDate);
        const effectiveExitDateIso = DateUtil.toIsoDate(form.effectiveExitDate);

        const fundInvestmentToUpdate = fundInvestment ?? this.persistedFundInvestment;
        const fundInvestmentToSave: FundInvestment = {
            ...fundInvestmentToUpdate,
            investmentDate: {
                ...fundInvestmentToUpdate.investmentDate,
                date: entryDateIso
            },
            exitDate: {
                ...fundInvestmentToUpdate.exitDate,
                date: effectiveExitDateIso
            },
            isRealized: {
                ...fundInvestmentToUpdate.isRealized,
                value: form.isRealized ?? undefined
            }
        };
        this.store.dispatch(
            FundInvestmentActions.save({
                fundId,
                fundReportId,
                fundInvestment: fundInvestmentToSave
            })
        );
    }

    cancel = () => this.store.dispatch(FundInvestmentActions.cancel());

    saveNote(context: string, note: Note): void {
        this.store.dispatch(AssetNoteActions.save({context, note}));
    }

    deleteNote(note: Note): void {
        this.store.dispatch(AssetNoteActions.delete({note}));
    }

    effectiveExitDateRangeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const group = control as FormGroup;
            const entryDate = group.get("entryDate")?.value;
            const effectiveExitDate = group.get("effectiveExitDate")?.value;

            if (!entryDate || !effectiveExitDate || !this.maxExitDate) {
                return null;
            }

            const isBeforeMin = effectiveExitDate < entryDate;
            const isAfterMax = effectiveExitDate > this.maxExitDate;

            return isBeforeMin || isAfterMax ? {invalidDateRange: true} : null;
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
