import {createFeatureSelector, createSelector} from "@ngrx/store";
import {fundStoreFeatureKey} from "../fund.reducer";
import {FundState} from "../fund.state";
import {DateTime} from "luxon";
import {selectSelectedFundReportReportDate} from "../fund-report/fund-report.selectors";
import {FundValuationScenario} from "../../models/fund-valuation-scenario";
import {ScenarioType} from "../../../shared/model/scenario-type";
import {FundCashFlowsUtil} from "../../utils/fund-cash-flows-util";
import {DateUtil} from "../../../shared/utils/date-util";


export const selectFundState = createFeatureSelector<FundState>(fundStoreFeatureKey);

export const selectFundForms = createSelector(
    selectFundState,
    (state) => state.fundForms
);

export const selectSelectedFundValuationsForFund = createSelector(
    selectFundState,
    (state) => state.selectedFundValuationsForFund.data
);

export const selectSelectedFundValuationsForFundOnReportDate = createSelector(
    selectFundState,
    selectSelectedFundReportReportDate,
    (state, fundReportDate) => state.selectedFundValuationsForFund.data.filter(f => {
        const fundReportReportDate = DateUtil.fromIsoDate(fundReportDate.date);
        const fundValuationReportDate = DateUtil.fromIsoDate(f.reportDate.date);
        return !!fundReportReportDate && !!fundValuationReportDate && fundReportReportDate.equals(fundValuationReportDate);
    })
);

export const selectSelectedFundValuation = createSelector(
    selectFundState,
    (state) => state.selectedFundValuation
);

export const selectSelectFundAssetValuations = createSelector(
    selectSelectedFundValuation,
    (fundValuation) => fundValuation.data.fundAssetValuations
);

export const selectCalculatedFundValuation = createSelector(
    selectFundState,
    (state) => state.calculatedFundValuation
);

export const selectSelectedFundValuationId = createSelector(
    selectSelectedFundValuation,
    (fundValuation) => fundValuation.data.id
);

export const selectSelectedFundValuationIsInit = createSelector(
    selectSelectedFundValuation,
    (fundValuation) => fundValuation.status !== "INIT"
);

export const selectSelectedFundValuationReportDate = createSelector(
    selectSelectedFundValuation,
    (fundValuation) => fundValuation.data.reportDate
);

export const selectSelectedFundValuationClosingDate = createSelector(
    selectSelectedFundValuation,
    (fundValuation) => fundValuation.data.closingDate
);

export const selectSelectedFundValuationScenarios = createSelector(
    selectFundState,
    (state) => state.selectedFundValuationScenarios.data
);

export const selectCalculatedFundValuationScenarios = createSelector(
    selectFundState,
    (state) => state.calculatedFundValuationScenarios
);

export const selectFundValuationScenarioIdFromType = (scenarioType: ScenarioType) => createSelector(
    selectSelectedFundValuationScenarios,
    (state) =>
        state.find(s => s.scenario.code === scenarioType)?.id
);

export const selectSelectedFundValuationCashFlows = createSelector(
    selectFundState,
    (state) => state.selectedFundValuationCashFlows
);

export const selectSelectedFundValuationCashFlowsForScenarioType = (scenarioType: ScenarioType) => createSelector(
    selectSelectedFundValuationCashFlows,
    (cashflows) => ({
        aggregatedFundCashFlows: cashflows.find(c => c.scenarioType === scenarioType)?.aggregatedFundCashFlows
    })
);

export const selectSelectedFundValuationCashflowsGraph = createSelector(
    selectSelectedFundValuationCashFlowsForScenarioType("LOW"),
    selectSelectedFundValuationCashFlowsForScenarioType("BASE"),
    selectSelectedFundValuationCashFlowsForScenarioType("HIGH"),
    (cashflowsLow, cashflowsBase, cashflowsHigh) => ({
        labels: FundCashFlowsUtil.getCumulativeGpGross(cashflowsBase).quarters.map(dateStr => DateTime.fromISO(dateStr).toFormat("MMM yyyy")),
        datasets: [
            {
                label: "Low case",
                data: FundCashFlowsUtil.getCumulativeGpGross(cashflowsLow).cumulativeGpGross,
                borderWidth: 1,
                backgroundColor: "red",
                borderColor: "red"
            },
            {
                label: "Base case",
                data: FundCashFlowsUtil.getCumulativeGpGross(cashflowsBase).cumulativeGpGross,
                borderWidth: 1,
                backgroundColor: "orange",
                borderColor: "orange"
            },
            {
                label: "High case",
                data: FundCashFlowsUtil.getCumulativeGpGross(cashflowsHigh).cumulativeGpGross,
                borderWidth: 1,
                backgroundColor: "green",
                borderColor: "green"
            }
        ]
    })
);

export const selectLowScenarioCashflowsGraph = createSelector(
    selectSelectedFundValuationCashFlowsForScenarioType("LOW"),
    (cashflows) => FundCashFlowsUtil.createChartData(cashflows, "red")
);

export const selectBaseScenarioCashflowsGraph = createSelector(
    selectSelectedFundValuationCashFlowsForScenarioType("BASE"),
    (cashflows) => FundCashFlowsUtil.createChartData(cashflows, "orange")
);

export const selectHighScenarioCashflowsGraph = createSelector(
    selectSelectedFundValuationCashFlowsForScenarioType("HIGH"),
    (cashflows) => FundCashFlowsUtil.createChartData(cashflows, "green")
);

export const selectUndrawnValuationEdit = createSelector(
    selectFundForms,
    (state) => state.undrawnValuationIsEditable
);

export const selectUndrawnValuation = createSelector(
    selectSelectedFundValuation,
    selectSelectedFundValuationScenarios,
    selectCalculatedFundValuation,
    selectCalculatedFundValuationScenarios,
    selectUndrawnValuationEdit,
    (fundValuation, scenarios, calcFundValuation, calcScenarios, undrawnValuationIsEditable) => {
        const tableColumns: string[] = [
            "case",
            "probability",
            "undrawnMultiple",
            "grossIRR",
        ];
        const tableDatasource: FundValuationScenario[] = [];

        const lowScenario = scenarios.find(s => s.scenario.code === "LOW");
        const baseScenario = scenarios.find(s => s.scenario.code === "BASE");
        const highScenario = scenarios.find(s => s.scenario.code === "HIGH");

        if (!!lowScenario && !!baseScenario && !!highScenario) {
            tableDatasource.push(lowScenario, baseScenario, highScenario);
        }

        return {
            tableDatasource,
            tableColumns,
            persistedFundValuation: fundValuation.data,
            persistedScenarios: scenarios,
            calculatedFundValuation: calcFundValuation.data,
            calculatedScenarios: calcScenarios.data,
            undrawnValuationIsEditable
        };
    }
);

export const selectFundReturnSummary = createSelector(
    selectFundState,
    (state) => state.returnSummary.data
);


export const selectFundReturnSummaryTable = createSelector(
    selectFundReturnSummary,
    (returnSummary) => {
        const tableColumns: string[] = [
            "case",
            "probability",
            "netPerformanceTvpi",
            "netPerformanceIrr",
            "grossPerformanceTvpi",
            "grossPerformanceIrr",
            "navUplift",
            "finalFundNTvpi",
        ];
        const tableDatasource: FundValuationScenario[] = [];

        const lowScenario = returnSummary.scenarios.find(s => s.scenario.code === "LOW");
        const baseScenario = returnSummary.scenarios.find(s => s.scenario.code === "BASE");
        const highScenario = returnSummary.scenarios.find(s => s.scenario.code === "HIGH");

        if (!!lowScenario && !!baseScenario && !!highScenario) {
            tableDatasource.push(lowScenario, baseScenario, highScenario);
        }

        return {
            tableColumns,
            tableDatasource,
            cashflowWeightedCase: returnSummary.cashflowWeightedCase
        };
    }
);

export const selectPartnershipInvestmentForReturnSummary = createSelector(
    selectFundState,
    selectFundForms,
    (state, forms) => ({
        partnershipInvestment: state.partnershipInvestments,
        fundReturnSummaryIsEditable: forms.fundReturnSummaryIsEditable
    })
);

export const selectCalculatedBidPriceAdjustment = createSelector(
    selectFundState,
    (state) => state.calculatedBidPriceAdjustment
);
