import {Inject, LOCALE_ID, Pipe, PipeTransform} from "@angular/core";
import {isBoolean, isCode, isModelDate, isMoney, isMoneyWithCurrency, isMultiple, isPercent, isPeriod, isText, Traceable} from "../../model/traceable";
import {DecimalFormatPipe} from "../decimal-format/decimal-format.pipe";
import {CalculationUtil} from "../../utils/calculation-util";
import {DateTime} from "luxon";

@Pipe({
    name: "traceableFormat"
})
export class TraceableFormatPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    }

    transform(traceable?: Traceable): string {
        if (!traceable) {
            return "-";
        }
        if (isMoney(traceable)) {
            return DecimalFormatPipe.transform(CalculationUtil.toBig(traceable.amount), "currency", this.locale);
        } else if (isMoneyWithCurrency(traceable)) {
            return DecimalFormatPipe.transform(CalculationUtil.toBig(traceable.amount), "currency", this.locale, traceable.currencyIso, true);
        } else if (isPercent(traceable)) {
            return DecimalFormatPipe.transform(CalculationUtil.toBig(traceable.fraction), "percent", this.locale);
        } else if (isMultiple(traceable)) {
            return DecimalFormatPipe.transform(CalculationUtil.toBig(traceable.factor), "multiple", this.locale);
        } else if (isModelDate(traceable) && !!traceable.date) {
            return DateTime.fromISO(traceable.date).toFormat("dd/MM/yyyy");
        } else if (isCode(traceable) && !!traceable.code) {
            return traceable.code;
        } else if (isText(traceable) && !!traceable.text) {
            return traceable.text;
        } else if (isPeriod(traceable)) {
            return this.transformPeriod(traceable.keyDate, traceable.type);
        } else if (isBoolean(traceable) && !!traceable.value) {
            return String(traceable.value);
        } else {
            return "-";
        }
    }

    private transformPeriod(keyDate?: string, type?: "QUARTER" | "SEMESTER" | "FULL_FISCAL_YEAR" | undefined): string {
        if (!keyDate || !type) {
            return "-";
        }
        const date = DateTime.fromISO(keyDate).toFormat("dd LLL yyyy");

        switch (type) {
            case "FULL_FISCAL_YEAR":
                return `FY ${date}`;
            case "SEMESTER":
                return `H ${date}`;
            case "QUARTER":
                return `Q ${date}`;

        }
    }
}
