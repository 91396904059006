import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {AssetListActions} from "../store/asset.actions";
import {selectAssets} from "../store/asset/asset.selectors";
import {AssetNewDialogComponent} from "../asset-new-dialog/asset-new-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {map} from "rxjs/operators";
import {CodeTableEnum} from "../../shared/model/code";
import {Subscription} from "rxjs";

@Component({
    selector: "valumize-asset-overview-page",
    templateUrl: "./asset-overview-page.component.html",
    styleUrls: ["./asset-overview-page.component.scss"]
})
export class AssetOverviewPageComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    columns = ["name", "country", "currencyIso", "activityDescription", "gic"];
    assetDataSource: MatTableDataSource<any> = new MatTableDataSource();
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;

    @ViewChild("assetPaging") assetPaging!: MatPaginator;
    @ViewChild("assetSort") assetSort = new MatSort();

    constructor(private readonly store: Store, public dialog: MatDialog) {
        this.store.dispatch(AssetListActions.open());
    }

    ngOnInit() {
        this.subscription = this.store.select(selectAssets).pipe(
            map(assets => {
                this.assetDataSource.data = assets;
                this.assetDataSource.paginator = this.assetPaging;
                this.assetDataSource.sort = this.assetSort;
                this.assetDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case "name":
                            return item.name.text;
                        case "country":
                            return item.country.code;
                        case "currencyIso":
                            return item.currencyIso.code;
                        case "activityDescription":
                            return item.activityDescription.text;
                        case "gic":
                            return item.gic.text;
                        default:
                            return item[property];
                    }
                };
                this.assetDataSource.sort.sort({
                    id: "name",
                    start: "asc",
                    disableClear: true
                });
            })
        ).subscribe();
    }

    openNewAssetDialog() {
        this.dialog.open(AssetNewDialogComponent);
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
