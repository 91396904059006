import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DealState} from "../deal.state";
import {dealStoreFeatureKey} from "../deal.reducer";

export const selectDealState = createFeatureSelector<DealState>(dealStoreFeatureKey);

export const selectReports = createSelector(
    selectDealState,
    (state) => state.reports.data
);

export const selectReportGenerationStatus = createSelector(
    selectDealState,
    (state) => state.reportGenerationStatus
);
