import {ActionReducer} from "@ngrx/store";
import {AssetState} from "./asset.state";
import {INITIAL_STATE} from "./asset.reducer";

const PREFERRED_VALUATION_TYPE_KEY = "preferredValuationType";

export const assetHydrationMetaReducer =
    (reducer: ActionReducer<AssetState>): ActionReducer<AssetState> => (state, action) => {
        const savedPreferredValuationType = localStorage.getItem(PREFERRED_VALUATION_TYPE_KEY);

        let rehydratedState = state;
        if (savedPreferredValuationType && !state?.preferredValuationType) {
            rehydratedState = {
                ...INITIAL_STATE,
                ...state,
                preferredValuationType: JSON.parse(savedPreferredValuationType)
            };
        }

        const nextState = reducer(rehydratedState, action);
        if (nextState && nextState.preferredValuationType !== state?.preferredValuationType) {
            localStorage.setItem(PREFERRED_VALUATION_TYPE_KEY, JSON.stringify(nextState.preferredValuationType));
        }
        return nextState;
    };
