/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {
    FundDependencyAction,
    FundInvestmentActions,
    FundValuationActions,
    FundValuationAggregatedCashflowAction,
    FundValuationScenarioActions,
    ReturnSummaryActions,
    UndrawnValuationActions
} from "../fund.actions";
import {catchError, concatMap, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {FundValuationService} from "../../services/fund-valuation.service";
import {Store} from "@ngrx/store";
import {EMPTY_FUND_VALUATION} from "../fund.reducer";
import {selectFundValuationScenarioIdFromType, selectSelectedFundValuationId} from "./fund-valuation.selectors";
import {selectSelectedFundId} from "../fund/fund.selectors";
import {UndrawnValuation} from "../../models/undrawn-valuation";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {selectSelectedFundReportId} from "../fund-report/fund-report.selectors";
import {selectSelectedFundInvestmentsForFundReport} from "../fund-investment/fund-investment.selectors";

export const loadFundValuation = createEffect(
    (actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(
                FundValuationActions.load,
                FundValuationActions.loadforasset
            ),
            exhaustMap((action) => {
                return fundValuationService.getFundValuation(action.fundId, action.fundValuationId).pipe(
                    switchMap((fundValuation) =>
                        (action.type === FundValuationActions.load.type) ?
                        of(
                            FundValuationActions.loadsuccess({fundValuation}),
                            FundValuationScenarioActions.loadall({fundId: action.fundId, fundValuationId: action.fundValuationId}),
                            ReturnSummaryActions.calc({fundId: action.fundId, fundValuationId: action.fundValuationId})
                        ) :
                        of(FundValuationActions.loadforassetsuccess({fundValuation}))
                    ),
                    catchError((error: { message: string }) =>
                        of(FundValuationActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadFundValuations = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationActions.loadall),
            exhaustMap((action) =>
                fundValuationService.getValuationsForFund(action.fundId).pipe(
                    map((valuations) => FundValuationActions.loadallsuccess({fundId: action.fundId, valuations})),
                    catchError((error: { message: string }) =>
                        of(FundValuationActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const createFundValuation = createEffect(
    (actions$ = inject(Actions), router = inject(Router), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationActions.create),
            exhaustMap((action) => {
                if (action.fundId) {
                    return fundValuationService.save(
                        action.fundId,
                        {
                            ...EMPTY_FUND_VALUATION,
                            ...{
                                reportDate: {
                                    ...EMPTY_FUND_VALUATION.reportDate,
                                    date: action.reportDate
                                }
                            },
                            ...{
                                closingDate: {
                                    ...EMPTY_FUND_VALUATION.closingDate,
                                    date: action.closingDate
                                }
                            },
                            ...{
                                finalBidPrice: {
                                    ...EMPTY_FUND_VALUATION.finalBidPrice,
                                    amount: 0
                                }
                            },
                            fundAssetValuations: []
                        }
                    ).pipe(
                        map((fundValuation) => FundValuationActions.created({fundValuation})),
                        tap((loadedAction) => {
                            if (loadedAction.type === FundValuationActions.created.type) {
                                router.navigate([], {queryParams: {fundValuationId: loadedAction.fundValuation.id}});
                            }
                        }),
                        catchError((error: { message: string }) =>
                            of(FundValuationActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundValuationActions.loaderror({errorMsg: "Fund could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const updateInvestmentsAfterCreatingFundValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundValuationActions.created),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundReportId), store$.select(selectSelectedFundInvestmentsForFundReport)),
            map(([, fundId, fundReportId, fundInvestments]) => fundId && fundReportId
                ? FundInvestmentActions.saveall({fundId, fundReportId, fundInvestments: fundInvestments.data})
                : FundInvestmentActions.loaderror({errorMsg: "Error while updating the fund investments, please refresh the page"})
            )
        );
    },
    {functional: true}
);

export const loadFundValuationScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationScenarioActions.loadall),
            exhaustMap((action) => {
                if (!!action.fundId && !!action.fundValuationId) {
                    return fundValuationService.getScenariosForFundValuation(action.fundId, action.fundValuationId).pipe(
                        map((scenarios) => FundValuationScenarioActions.loadallsuccess({scenarios})),
                        catchError((error: { message: string }) =>
                            of(FundValuationScenarioActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(FundValuationScenarioActions.loaderror({errorMsg: "No valuation for this fund available, please add a valuation first"}));
                }
            })
        );
    },
    {functional: true}
);

// Todo - ReturnSummary somehow gets called here and below when UndrawnValuationActions.save is safed
export const undrawnValSaveFundValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.save, FundValuationActions.save),
            withLatestFrom(store$.select(selectSelectedFundId)),
            exhaustMap(([action, fundId]) => {
                if (fundId === undefined) {
                    return of(FundValuationActions.loaderror({errorMsg: "Fund could not be found"}));
                }
                return fundValuationService.save(fundId, action.fundValuation).pipe(
                    switchMap((fundValuation) => of(
                        FundValuationActions.saveloadsuccess({fundId, fundValuation}),
                        !!fundValuation.id && action.type === FundValuationActions.save.type
                            ? ReturnSummaryActions.calc({fundId, fundValuationId: fundValuation.id})
                            : ReturnSummaryActions.calcerror({errorMsg: "No valuation for this fund available, please add a valuation first"})
                    )),
                    catchError((error: { message: string }) =>
                        of(FundValuationActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const undrawnValSaveFundValuationScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.save),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundValuationId)),
            exhaustMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(FundValuationScenarioActions.loaderror({errorMsg: "No valuation for this fund available, please add a valuation first"}));
                }
                return fundValuationService.saveScenariosForFundValuation(fundId, fundValuationId, action.scenarios).pipe(
                    map((scenarios) => UndrawnValuationActions.savesuccess({scenarios})),
                    catchError((error: { message: string }) =>
                        of(FundValuationScenarioActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDependenciesAfterUndrawnValuationSaveSuccess = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.savesuccess),
            map(() => FundDependencyAction.loaddependencies({
                loadFundReport: false,
                calcReturnSummary: true,
                loadCashflows: true,
                loadSellerPosition: false,
            }))
        );
    },
    {functional: true}
);

export const calcUndrawnValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(UndrawnValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            exhaustMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(UndrawnValuationActions.calcerror({errorMsg: "No valuation for this fund available, please add a valuation first"}));
                }
                const undrawnValuation: UndrawnValuation = {
                    fundValuation: action.fundValuation,
                    scenarios: action.scenarios
                };
                return fundValuationService.calcUndrawnValuation(fundId, fundValuationId, undrawnValuation).pipe(
                    map((undrawn) => UndrawnValuationActions.calcsuccess({fundValuation: undrawn.fundValuation, scenarios: undrawn.scenarios})),
                    catchError((error: any) =>
                        of(UndrawnValuationActions.calcerror({errorMsg: error.error.expectedException?.message ?? error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadParralelFundValuationAggregatedCashflowLow = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundValuationAggregatedCashflowAction.loadallparallel),
            withLatestFrom(store$.select(selectFundValuationScenarioIdFromType("LOW"))),
            map(([action, scenarioId]) =>
                (!!scenarioId) ?
                    FundValuationAggregatedCashflowAction.load({scenarioId, scenarioType: "LOW"}) :
                    FundValuationAggregatedCashflowAction.loaderror({scenarioType: "LOW", errorMsg: "no Fund Scenario Found for this low case"})
            )
        );
    },
    {functional: true}
);

export const loadParralelFundValuationAggregatedCashflowBase = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundValuationAggregatedCashflowAction.loadallparallel),
            withLatestFrom(store$.select(selectFundValuationScenarioIdFromType("BASE"))),
            map(([action, scenarioId]) =>
                (!!scenarioId) ?
                    FundValuationAggregatedCashflowAction.load({scenarioId, scenarioType: "BASE"}) :
                    FundValuationAggregatedCashflowAction.loaderror({scenarioType: "BASE", errorMsg: "no Fund Scenario Found for this base case"})
            )
        );
    },
    {functional: true}
);

export const loadParralelFundValuationAggregatedCashflowHigh = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FundValuationAggregatedCashflowAction.loadallparallel),
            withLatestFrom(store$.select(selectFundValuationScenarioIdFromType("HIGH"))),
            map(([action, scenarioId]) =>
                (!!scenarioId) ?
                    FundValuationAggregatedCashflowAction.load({scenarioId, scenarioType: "HIGH"}) :
                    FundValuationAggregatedCashflowAction.loaderror({scenarioType: "HIGH", errorMsg: "no Fund Scenario Found for this high case"})
            )
        );
    },
    {functional: true}
);

export const loadFundValuationAggregatedCashflow = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(FundValuationAggregatedCashflowAction.load),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            concatMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(FundValuationAggregatedCashflowAction.loaderror({
                        scenarioType: action.scenarioType,
                        errorMsg: "No valuation for this fund available, please add a valuation first"
                    }));
                }
                return fundValuationService.getAggregatedFundCashflows(fundId, fundValuationId, action.scenarioId).pipe(
                    map((aggregatedCashflows) => FundValuationAggregatedCashflowAction.loadsuccess({aggregatedCashFlows: aggregatedCashflows, scenarioType: action.scenarioType})),
                    catchError((error: any) =>
                        of(FundValuationAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: error.error.expectedException?.message ?? error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const calcReturnSummary = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(ReturnSummaryActions.calc),
            exhaustMap((action) => {
                if (action.fundId === undefined || action.fundValuationId === undefined) {
                    return of(ReturnSummaryActions.calcerror({errorMsg: "No valuation for this fund available, please add a valuation first"}));
                }
                return fundValuationService.calcReturnSummary(action.fundId, action.fundValuationId).pipe(
                    map((returnSummary) => ReturnSummaryActions.calcsuccess({returnSummary})),
                    catchError((error: { message: string }) =>
                        of(ReturnSummaryActions.calcerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const calculateGoalSeekReturnSummary = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(ReturnSummaryActions.calculategoalseek),
            withLatestFrom(
                store$.select(selectSelectedFundId),
                store$.select(selectSelectedFundValuationId)
            ),
            exhaustMap(([action, fundId, fundValuationId]) => {
                if (fundId === undefined || fundValuationId === undefined) {
                    return of(ReturnSummaryActions.calculategoalseekerror({ errorMsg: "No valuation for this fund available, please add a valuation first" }));
                }
                return fundValuationService.calculateGoalSeekReturnSummary(fundId, fundValuationId, action.targetTvpi).pipe(
                    map((bidPriceAdjustment) =>
                        ReturnSummaryActions.calculategoalseeksuccess({ bidPriceAdjustment })
                    ),
                    catchError((error: { message: string }) =>
                        of(ReturnSummaryActions.calculategoalseekerror({ errorMsg: error.message }))
                    )
                );
            })
        );
    },
    { functional: true }
);
