/* eslint-disable arrow-body-style */
import {inject} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, concatMap, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {AssetValuationService} from "../../services/asset-valuation.service";
import {
    AssetActions,
    AssetDiscountValuationActions,
    AssetOperationalValuationActions,
    AssetValuationActions,
    AssetValuationScenarioActions,
    FinancialPerformanceRecordsActions
} from "../asset.actions";
import {Store} from "@ngrx/store";
import {selectSelectedAssetId} from "../asset/asset.selectors";
import {
    selectCalculatedValuationOperational,
    selectCalculatedValuationScenariosOperational,
    selectPreferredValuationType,
    selectSelectedAssetValuationDMV,
    selectSelectedAssetValuationOperational
} from "./asset-valuation.selectors";
import {DiscountMultipleValuation, isValidValuationInput} from "../../models/asset-valuation/discount-multiple-valuation";
import {selectSelectedFundValuation, selectSelectedFundValuationClosingDate} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {selectSelectedFundInvestment} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {selectSelectedFundId} from "../../../fund/store/fund/fund.selectors";
import {FundAssetValuation, FundValuation} from "../../../fund/generated-sources/api";
import {FundValuationActions} from "../../../fund/store/fund.actions";
import {EMPTY_ASSET_VALUATION} from "../asset.reducer";
import {FundValuationService} from "../../../fund/services/fund-valuation.service";
import {ValuationTypeEnum} from "../../models/asset-valuation/valuation-type.enum";
import {FinancialOperationalValuation} from "../../models/asset-valuation/financial-operational-valuation";
import {isOfScenarioType} from "../../../shared/model/scenario-type";
import {EMPTY_CODE} from "../../../shared/model/traceable";

export const createAssetOperationalValuation = createEffect(
    (actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetOperationalValuationActions.create),
            exhaustMap((action) => {
                if (action.assetId) {
                    return assetValuationService.save(
                        action.assetId,
                        {
                            ...EMPTY_ASSET_VALUATION,
                            ...{
                                reportDate: {
                                    ...EMPTY_ASSET_VALUATION.reportDate,
                                    date: action.reportDate
                                },
                                valuationType: {
                                    ...EMPTY_ASSET_VALUATION.valuationType,
                                    code: action.valuationType
                                }
                            }
                        }
                    ).pipe(
                        map((assetValuation) => AssetValuationActions.createoperationalsuccess({assetId: action.assetId, assetValuation})),
                        catchError((error: { message: string }) => of(AssetOperationalValuationActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetOperationalValuationActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const addAssetValuationToFundValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), fundValuationService = inject(FundValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.addtofundvaluation),
            withLatestFrom(store$.select(selectSelectedFundId), store$.select(selectSelectedFundValuation)),
            exhaustMap(([action, fundId, fundValuation]) => {
                const newFundAssetValuation: FundAssetValuation = {
                    id: undefined,
                    assetValuationId: action.assetValuationId,
                    preferredValuation: false
                };
                const updatedFundValuation: FundValuation = {
                    ...fundValuation.data,
                    fundAssetValuations: [...fundValuation.data.fundAssetValuations, newFundAssetValuation]
                };
                if (fundId) {
                    return fundValuationService.save(fundId, updatedFundValuation).pipe(
                        map((fundValuationResponse) => FundValuationActions.loadsuccess({fundValuation: fundValuationResponse})),
                        tap(() => window.location.reload()),
                        catchError((error: { message: string }) => of(FundValuationActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(FundValuationActions.loaderror({errorMsg: "Error while loading the fund, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const addCreatedAssetValuationToFundValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.createoperationalsuccess),
            map((action) => action.assetValuation.id
                ? AssetValuationActions.addtofundvaluation({assetId: action.assetId, assetValuationId: action.assetValuation.id})
                : AssetOperationalValuationActions.loaderror({errorMsg: "Asset valuation was not added correctly to the according fund valuation"})
            )
        );
    },
    {functional: true}
);

export const loadAssetValuations = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loadall),
            exhaustMap((action) =>
                assetValuationService.getValuationsForAsset(action.assetId).pipe(
                    map((valuations) => AssetValuationActions.loadallsuccess({assetId: action.assetId, valuations})),
                    catchError((error: { message: string }) => of(AssetValuationActions.loadallerror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const loadAssetValuation = createEffect(
    (actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.load),
            exhaustMap((action) =>
                assetValuationService.getAssetValuation(action.assetId, action.assetValuationId).pipe(
                    map((assetValuation) => assetValuation.valuationType.code === ValuationTypeEnum.DiscountMultipleValuation
                        ? AssetValuationActions.loaddmvsuccess({assetId: action.assetId, assetValuation})
                        : AssetValuationActions.loadoperationalsuccess({assetId: action.assetId, assetValuation})
                    ),
                    catchError((error: { message: string }) => of(AssetValuationActions.loadallerror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const loadOperationalAfterDMV = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loaddmvsuccess),
            exhaustMap((action) =>
                assetValuationService.getValuationsForAsset(action.assetId).pipe(
                    map((valuations) => AssetValuationActions.loadallsuccess({assetId: action.assetId, valuations})),
                    catchError((error: { message: string }) => of(AssetValuationActions.loadallerror({errorMsg: error.message})))
                )
            ),
            withLatestFrom(store$.select(selectSelectedAssetValuationDMV), store$.select(selectSelectedAssetValuationOperational)),
            exhaustMap(([action, dmvApi, operationalValuationApi]) => {
                if (action.type !== AssetValuationActions.loadallsuccess.type || operationalValuationApi.status === "LOADED" || operationalValuationApi.status === "ERROR") {
                    return of();
                }
                const operationalValuation = action.valuations.find(av =>
                    av.valuationType.code === ValuationTypeEnum.FinancialOperationalValuation
                    && av.reportDate.date === dmvApi.data.reportDate.date
                );
                if (operationalValuation?.id) {
                    return of(AssetValuationActions.load({
                        assetId: action.assetId,
                        assetValuationId: operationalValuation.id,
                        valuationType: ValuationTypeEnum.FinancialOperationalValuation
                    }));
                } else {
                    return of(AssetOperationalValuationActions.loaderror({errorMsg: "Operational Valuation does not exist"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadDmvAfterOperational = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loadoperationalsuccess),
            exhaustMap((action) =>
                assetValuationService.getValuationsForAsset(action.assetId).pipe(
                    map((valuations) => AssetValuationActions.loadallsuccess({assetId: action.assetId, valuations})),
                    catchError((error: { message: string }) => of(AssetValuationActions.loadallerror({errorMsg: error.message})))
                )
            ),
            withLatestFrom(store$.select(selectSelectedAssetValuationOperational), store$.select(selectSelectedAssetValuationDMV)),
            exhaustMap(([action, operationalValuationApi, dmvApi]) => {
                if (action.type !== AssetValuationActions.loadallsuccess.type || dmvApi.status === "LOADED" || dmvApi.status === "ERROR") {
                    return of();
                }
                const discountMultipleValuation = action.valuations.find(av =>
                    av.valuationType.code === ValuationTypeEnum.DiscountMultipleValuation
                    && av.reportDate.date === operationalValuationApi.data.reportDate.date
                );
                if (discountMultipleValuation?.id) {
                    return of(AssetValuationActions.load({
                        assetId: action.assetId,
                        assetValuationId: discountMultipleValuation.id,
                        valuationType: ValuationTypeEnum.DiscountMultipleValuation
                    }));
                } else {
                    return of(AssetDiscountValuationActions.loaderror({errorMsg: "Discount Multiple Valuation does not exist"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetValuationScenariosDMV = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loaddmvsuccess),
            exhaustMap((action) => {
                if (!!action.assetId && !!action.assetValuation.id) {
                    return assetValuationService.getScenariosForAssetValuation(action.assetId, action.assetValuation.id).pipe(
                        map((scenarios) => AssetValuationScenarioActions.loadalldmvsuccess({scenarios})),
                        catchError((error: { message: string }) => of(AssetValuationScenarioActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetValuationScenarioActions.loaderror({errorMsg: "Error while loading the asset valuation, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadAssetValuationScenariosOperational = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.loadoperationalsuccess),
            exhaustMap((action) => {
                if (!!action.assetId && !!action.assetValuation.id) {
                    return assetValuationService.getScenariosForAssetValuation(action.assetId, action.assetValuation.id).pipe(
                        map((scenarios) => AssetValuationScenarioActions.loadalloperationalsuccess({scenarios})),
                        catchError((error: { message: string }) => of(AssetValuationScenarioActions.loaderror({errorMsg: error.message})))
                    );
                } else {
                    return of(AssetValuationScenarioActions.loaderror({errorMsg: "Error while loading the asset valuation, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveAssetValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(
                AssetDiscountValuationActions.save, AssetDiscountValuationActions.saverationale,
                AssetOperationalValuationActions.save, AssetOperationalValuationActions.saverationale
            ),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            exhaustMap(([action, assetId]) => {
                if (assetId === undefined) {
                    return of(AssetActions.loaderror({errorMsg: "Error while loading the asset, please refresh the page"}));
                }
                return assetValuationService.save(assetId, action.assetValuation).pipe(
                    map((assetValuation) => assetValuation.valuationType.code === ValuationTypeEnum.FinancialOperationalValuation
                        ? AssetOperationalValuationActions.saveloadsuccess({assetValuation})
                        : AssetDiscountValuationActions.saveloadsuccess({assetValuation})
                    ),
                    catchError((error) => error.assetValuation.valuationType.code === ValuationTypeEnum.FinancialOperationalValuation
                        ? of(AssetOperationalValuationActions.loaderror({errorMsg: error.error.message}))
                        : of(AssetDiscountValuationActions.loaderror({errorMsg: error.error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const deleteAssetValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), router = inject(Router), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetValuationActions.delete),
            withLatestFrom(store$.select(selectPreferredValuationType)),
            exhaustMap(([action, preferredValuationType]) =>
                assetValuationService.delete(action.assetId, action.assetValuationId).pipe(
                    map(() => AssetValuationActions.deletesuccess()),
                    tap((loadedAction) => {
                        if (loadedAction.type === AssetValuationActions.deletesuccess.type) {
                            router.navigate([], {queryParams: {assetValuationId: null}, queryParamsHandling: "merge"});
                        }
                    }),
                    catchError((error: { message: string }) => preferredValuationType === ValuationTypeEnum.FinancialOperationalValuation
                        ? of(AssetOperationalValuationActions.loaderror({errorMsg: error.message}))
                        : of(AssetDiscountValuationActions.loaderror({errorMsg: error.message})))
                )
            )
        );
    },
    {functional: true}
);

export const saveAssetValuationScenariosDMV = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetDiscountValuationActions.save),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            exhaustMap(([action, assetId]) => {
                const assetValuationId = action.assetValuation.id;
                if (assetId === undefined || assetValuationId === undefined) {
                    return of(AssetValuationScenarioActions.loaderror({errorMsg: "No valuation for this asset available, please add a valuation first"}));
                }
                return assetValuationService.saveScenariosForAssetValuation(assetId, assetValuationId, action.scenarios).pipe(
                    map((scenarios) => AssetValuationScenarioActions.loadalldmvsuccess({scenarios})),
                    catchError((error: { message: string }) => of(AssetValuationScenarioActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const saveAssetValuationScenariosOperational = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetOperationalValuationActions.save),
            withLatestFrom(store$.select(selectSelectedAssetId)),
            exhaustMap(([action, assetId]) => {
                const assetValuationId = action.assetValuation.id;
                if (assetId === undefined || assetValuationId === undefined || action.scenarios === undefined) {
                    return of(AssetValuationScenarioActions.loaderror({errorMsg: "Error while loading the asset valuation scenarios, please refresh the page"}));
                }
                return assetValuationService.saveScenariosForAssetValuation(assetId, assetValuationId, action.scenarios).pipe(
                    map((scenarios) => AssetValuationScenarioActions.loadalloperationalsuccess({scenarios})),
                    catchError((error: { message: string }) => of(AssetValuationScenarioActions.loaderror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const calcDiscountMultipleValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetDiscountValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedAssetId),
                store$.select(selectSelectedFundValuationClosingDate),
                store$.select(selectSelectedFundInvestment)
            ),
            exhaustMap(([action, assetId, closingDate, fundInvestment]) => {
                const assetValuationId = action.assetValuation.id;
                if (assetId === undefined || assetValuationId === undefined) {
                    return of(AssetDiscountValuationActions.calcerror({errorMsg: "No valuation for this asset available, please add a valuation first"}));
                }
                const discountMultipleValuation: DiscountMultipleValuation = {
                    assetValuation: action.assetValuation,
                    scenarios: action.scenarios,
                    closingDate,
                    fundInvestmentData: {
                        investmentDate: fundInvestment.data.investmentDate,
                        ownershipStake: fundInvestment.data.ownershipStake,
                        nav: fundInvestment.data.nav,
                        totalCost: fundInvestment.data.totalCost,
                        totalRealized: fundInvestment.data.totalRealized
                    },
                    valuationInput: isValidValuationInput(action.valuationInput) ? action.valuationInput : "MULTIPLE"
                };
                return assetValuationService.calcDiscountMultipleValuation(assetId, assetValuationId, discountMultipleValuation).pipe(
                    map((dmv) => (action.save) ?
                        AssetDiscountValuationActions.save({assetValuation: dmv.assetValuation, scenarios: dmv.scenarios}) :
                        AssetDiscountValuationActions.calcsuccess({assetValuation: dmv.assetValuation, scenarios: dmv.scenarios})),
                    catchError((error: { message: string }) => of(AssetDiscountValuationActions.calcerror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const calcOperationalValuation = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(AssetOperationalValuationActions.calc),
            withLatestFrom(
                store$.select(selectSelectedAssetId),
                store$.select(selectCalculatedValuationScenariosOperational),
                store$.select(selectSelectedFundInvestment),
                store$.select(selectSelectedFundValuationClosingDate),
            ),
            exhaustMap(([action, assetId, scenarios, fundInvestment, closingDate]) => {
                const assetValuationId = action.assetValuation.id;
                if (assetId === undefined || assetValuationId === undefined) {
                    return of(AssetOperationalValuationActions.calcerror({errorMsg: "No valuation for this asset available, please add a valuation first"}));
                }
                const financialOperationalValuation: FinancialOperationalValuation = {
                    assetValuation: action.assetValuation,
                    scenarioTypeForCashflowCalc: {...EMPTY_CODE, code: action.scenarioType},
                    scenarios,
                    financialPerformanceRecords: action.financialPerformanceRecords,
                    closingDate,
                    fundInvestmentData: {
                        investmentDate: fundInvestment.data.investmentDate,
                        ownershipStake: fundInvestment.data.ownershipStake,
                        nav: fundInvestment.data.nav,
                        totalCost: fundInvestment.data.totalCost,
                        totalRealized: fundInvestment.data.totalRealized
                    }
                };
                return assetValuationService.calcOperationalValuation(assetId, assetValuationId, financialOperationalValuation).pipe(
                    map((operational) => {
                        if (operational.scenarioTypeForCashflowCalc.code && isOfScenarioType(operational.scenarioTypeForCashflowCalc.code)) {
                            return AssetOperationalValuationActions.calcsuccess({
                                assetValuation: operational.assetValuation,
                                scenarios: operational.scenarios,
                                financialPerformanceRecords: operational.financialPerformanceRecords,
                                scenarioType: operational.scenarioTypeForCashflowCalc.code
                            });
                        } else {
                            return AssetOperationalValuationActions.calcerror({
                                errorMsg: `Error while calculating the ${operational.scenarioTypeForCashflowCalc.code} case scenario, please refresh the page`
                            });
                        }
                    }),
                    catchError((error: { message: string }) => of(AssetOperationalValuationActions.calcerror({errorMsg: error.message})))
                );
            })
        );
    },
    {functional: true}
);

export const loadFinancialPerformance = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(FinancialPerformanceRecordsActions.load),
            concatMap((action) => {
                    return assetValuationService.getFinancialPerformanceForScenario(action.assetId, action.assetValuationId, action.scenarioId).pipe(
                        map((financialPerformanceRecords) => {
                            switch (action.scenarioType) {
                                case "LOW":
                                    return FinancialPerformanceRecordsActions.loadlowsuccess({financialPerformanceRecords, scenarioType: action.scenarioType});
                                case "HIGH":
                                    return FinancialPerformanceRecordsActions.loadhighsuccess({financialPerformanceRecords, scenarioType: action.scenarioType});
                                default:
                                    return FinancialPerformanceRecordsActions.loadbasesuccess({financialPerformanceRecords, scenarioType: action.scenarioType});
                            }
                        }),
                        catchError((error: { message: string }) => of(FinancialPerformanceRecordsActions.loaderror({errorMsg: error.message, scenarioType: action.scenarioType})))
                    );
                }
            )
        );
    },
    {functional: true}
);

export const reloadOperationalAfterLoadingPerformanceRecords = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(FinancialPerformanceRecordsActions.loadlowsuccess, FinancialPerformanceRecordsActions.loadbasesuccess, FinancialPerformanceRecordsActions.loadhighsuccess),
            withLatestFrom(store$.select(selectSelectedAssetId), store$.select(selectSelectedAssetValuationOperational)),
            map(([, assetId, assetValuationApi]) => {
                    const valuationTypeEnum = Object.values(ValuationTypeEnum).find(enumValue => enumValue === assetValuationApi.data.valuationType.code);
                    return assetId && assetValuationApi.data.id && valuationTypeEnum
                        ? AssetValuationActions.load({assetId, assetValuationId: assetValuationApi.data.id, valuationType: valuationTypeEnum})
                        : AssetOperationalValuationActions.loaderror({errorMsg: "Error while loading operational valuation, please refresh the page"});
                }
            )
        );
    },
    {functional: true}
);

export const saveFinancialPerformance = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(FinancialPerformanceRecordsActions.save),
            concatMap((action) => {
                return assetValuationService.saveFinancialPerformanceForScenario(
                    action.assetId,
                    action.assetValuationId,
                    action.scenarioId,
                    action.financialPerformanceRecords
                ).pipe(
                    map((financialPerformanceRecords) => {
                        switch (action.scenarioType) {
                            case "LOW":
                                return FinancialPerformanceRecordsActions.saveloadlowsuccess({financialPerformanceRecords, scenarioType: action.scenarioType});
                            case "HIGH":
                                return FinancialPerformanceRecordsActions.saveloadhighsuccess({financialPerformanceRecords, scenarioType: action.scenarioType});
                            default:
                                return FinancialPerformanceRecordsActions.saveloadbasesuccess({financialPerformanceRecords, scenarioType: action.scenarioType});
                        }
                    }),
                    catchError((error: { message: string }) => of(FinancialPerformanceRecordsActions.loaderror({errorMsg: error.message, scenarioType: action.scenarioType})))
                );
            })
        );
    },
    {functional: true}
);

export const saveOperationalAfterSavingPerformanceRecords = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(
                FinancialPerformanceRecordsActions.saveloadlowsuccess,
                FinancialPerformanceRecordsActions.saveloadbasesuccess,
                FinancialPerformanceRecordsActions.saveloadhighsuccess),
            withLatestFrom(store$.select(selectCalculatedValuationOperational), store$.select(selectCalculatedValuationScenariosOperational)),
            map(([, assetValuationApi, scenarios]) =>
                AssetOperationalValuationActions.save({assetValuation: assetValuationApi.data, scenarios})
            )
        );
    },
    {functional: true}
);

export const calcFinancialPerformance = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), assetValuationService = inject(AssetValuationService)) => {
        return actions$.pipe(
            ofType(FinancialPerformanceRecordsActions.calc, FinancialPerformanceRecordsActions.edit),
            concatMap((action) => {
                return assetValuationService.calculateFinancialPerformanceForScenario(
                    action.assetId,
                    action.assetValuationId,
                    action.scenarioId,
                    action.financialPerformanceRecords
                ).pipe(
                    map((financialPerformanceRecords) => {
                        switch (action.scenarioType) {
                            case "LOW":
                                return FinancialPerformanceRecordsActions.calcsuccesslow({financialPerformanceRecords, scenarioType: action.scenarioType});
                            case "HIGH":
                                return FinancialPerformanceRecordsActions.calcsuccesshigh({financialPerformanceRecords, scenarioType: action.scenarioType});
                            default:
                                return FinancialPerformanceRecordsActions.calcsuccessbase({financialPerformanceRecords, scenarioType: action.scenarioType});
                        }
                    }),
                    catchError((error: { message: string }) => of(FinancialPerformanceRecordsActions.calcerror({errorMsg: error.message, scenarioType: action.scenarioType})))
                );
            })
        );
    },
    {functional: true}
);

export const triggerOperationalCalcAfterPerformanceRecordsCalc = createEffect(
    (actions$ = inject(Actions), store$ = inject(Store)) => {
        return actions$.pipe(
            ofType(FinancialPerformanceRecordsActions.calcsuccesslow, FinancialPerformanceRecordsActions.calcsuccessbase, FinancialPerformanceRecordsActions.calcsuccesshigh),
            withLatestFrom(store$.select(selectSelectedAssetValuationOperational)),
            map(([action, assetValuationApi]) =>
                AssetOperationalValuationActions.calc({
                    assetValuation: assetValuationApi.data,
                    financialPerformanceRecords: action.financialPerformanceRecords,
                    scenarioType: action.scenarioType
                })
            )
        );
    },
    {functional: true}
);
