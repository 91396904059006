import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AssetState} from "../asset.state";
import {assetStoreFeatureKey} from "../asset.reducer";
import {
    EMPTY_MONEY_WITH_CURRENCY,
    EMPTY_MULTIPLE,
    EMPTY_PERCENT,
    Traceable,
    TraceableMoneyWithCurrency,
    TraceableMultiple,
    TraceablePercent
} from "../../../shared/model/traceable";
import {DateTime} from "luxon";
import {AssetValuationScenario} from "../../models/asset-valuation/asset-valuation-scenario";
import {DateUtil} from "../../../shared/utils/date-util";
import {selectFundCurrency} from "../../../fund/store/fund/fund.selectors";
import {ScenarioType} from "../../../shared/model/scenario-type";
import {ValuationTypeEnum} from "../../models/asset-valuation/valuation-type.enum";

export const selectAssetState = createFeatureSelector<AssetState>(assetStoreFeatureKey);

export const selectAssetForms = createSelector(
    selectAssetState,
    (state) => state.assetForms
);

export const selectPreferredValuationType = createSelector(
    selectAssetState,
    (state) => state.preferredValuationType
);

export const selectSelectedAssetValuationDMV = createSelector(
    selectAssetState,
    (state) => state.discountMultipleValuation.selectedAssetValuation
);

export const selectSelectedAssetValuationOperational = createSelector(
    selectAssetState,
    (state) => state.financialOperationalValuation.selectedAssetValuation
);

export const selectSelectedExitDateAssumptionOperational = createSelector(
    selectSelectedAssetValuationOperational,
    (valuation) => valuation.data.exitDateAssumption
);

export const selectSelectedAssetValuation = createSelector(
    selectPreferredValuationType,
    selectSelectedAssetValuationDMV,
    selectSelectedAssetValuationOperational,
    (preferredValuationType, dmv, operational) =>
        preferredValuationType === ValuationTypeEnum.DiscountMultipleValuation ? dmv : operational
);

export const selectCalculatedValuationDMV = createSelector(
    selectAssetState,
    (state) => state.discountMultipleValuation.calculatedAssetValuation.data
);

export const selectCalculatedValuationOperational = createSelector(
    selectAssetState,
    (state) => state.financialOperationalValuation.calculatedAssetValuation
);

export const selectSelectedValuationScenariosDMV = createSelector(
    selectAssetState,
    (state) => state.discountMultipleValuation.selectedAssetValuationScenarios.data
);

export const selectCalculatedValuationScenariosDMV = createSelector(
    selectAssetState,
    (state) => state.discountMultipleValuation.calculatedAssetValuationScenarios.data
);

export const selectSelectedValuationScenariosOperational = createSelector(
    selectAssetState,
    (state) => state.financialOperationalValuation.selectedAssetValuationScenarios.data
);

export const selectCalculatedValuationScenariosOperational = createSelector(
    selectAssetState,
    (state) => state.financialOperationalValuation.calculatedAssetValuationScenarios.data
);

export const selectFinancialPerformanceRecordsByScenarioType = (scenarioType: ScenarioType) => createSelector(
    selectAssetState,
    state => state.financialOperationalValuation.selectedFinancialPerformanceRecords[scenarioType]?.data ?? []
);

export const selectDiscountMultipleValuationEdit = createSelector(
    selectAssetForms,
    (state) => state.discountMultipleValuationIsEditable
);

export const selectDMVRationaleEdit = createSelector(
    selectAssetForms,
    (state) => state.dmvRationaleIsEditable
);

export const selectOperationalValuationEdit = createSelector(
    selectAssetForms,
    (state) => ({
        lowCaseIsEditable: state.financialOperationalValuationIsEditable.LOW,
        baseCaseIsEditable: state.financialOperationalValuationIsEditable.BASE,
        highCaseIsEditable: state.financialOperationalValuationIsEditable.HIGH,
        valuationIsEditable: state.financialOperationalValuationIsEditable.valuationIsEditable,
        operationalRationaleIsEditable: state.operationalRationaleIsEditable
    })
);

export const selectFinancialOperationalValuation = createSelector(
    selectSelectedAssetValuationOperational,
    selectSelectedValuationScenariosOperational,
    selectCalculatedValuationOperational,
    selectCalculatedValuationScenariosOperational,
    selectOperationalValuationEdit,
    (assetValuationApi, valuationScenarios, calcAssetValuationApi, calcValuationScenarios, isEditable) => {
        const assetValuation = isEditable.lowCaseIsEditable.recordsAreEditable || isEditable.baseCaseIsEditable.recordsAreEditable
        || isEditable.highCaseIsEditable.recordsAreEditable
            ? calcAssetValuationApi
            : assetValuationApi;

        const scenarios = isEditable.lowCaseIsEditable.recordsAreEditable || isEditable.baseCaseIsEditable.recordsAreEditable || isEditable.highCaseIsEditable.recordsAreEditable
            ? calcValuationScenarios
            : valuationScenarios;

        return {
            assetValuation,
            scenarios,
            isEditable
        };
    }
);

export const selectDiscountMultipleValuation = createSelector(
    selectSelectedAssetValuationDMV,
    selectSelectedValuationScenariosDMV,
    selectCalculatedValuationDMV,
    selectCalculatedValuationScenariosDMV,
    selectFundCurrency,
    selectDiscountMultipleValuationEdit,
    selectDMVRationaleEdit,
    (assetValuation, scenarios, calcAssetValuation, calcScenarios, fundCurrency, discountMultipleValuationEditable, rationaleIsEditable) => {
        interface DiscountValuationElement {
            name: string;
            probability: TraceablePercent;
            cashflowAmount: TraceableMoneyWithCurrency;
            navMultiple: TraceableMultiple;
            primaryTVPI: TraceableMultiple;
            secondaryTVPI: TraceableMultiple;
            secondaryIRR: TraceablePercent;
        }

        interface Row {
            definition: string;
            name: string;
            low?: Traceable;
            lowCalc?: Traceable;
            base?: Traceable;
            baseCalc?: Traceable;
            high?: Traceable;
            highCalc?: Traceable;
        }

        const tableRowDefinition = [
            {definition: "probability", name: "Probability"},
            {definition: "cashflowAmount", name: "Cashflow"},
            {definition: "navMultiple", name: "NAV Multiple"},
            {definition: "primaryTVPI", name: "Primary TVPI"},
            {definition: "secondaryTVPI", name: "Secondary TVPI"},
            {definition: "secondaryIRR", name: "Secondary IRR"},
        ];
        const tableColumns: string[] = ["name", "low", "lowCalc", "base", "baseCalc", "high", "highCalc"];
        const tableDataSource: Row[] = [];

        const columnElements: DiscountValuationElement[] = [];
        const createColumnElement = (scenario: AssetValuationScenario | undefined, column: string) => {
            const columnElement: DiscountValuationElement = {
                name: "",
                probability: EMPTY_PERCENT,
                cashflowAmount: EMPTY_MONEY_WITH_CURRENCY,
                navMultiple: EMPTY_MULTIPLE,
                primaryTVPI: EMPTY_MULTIPLE,
                secondaryTVPI: EMPTY_MULTIPLE,
                secondaryIRR: EMPTY_PERCENT,
            };

            if (scenario) {
                columnElement.name = column;
                columnElement.probability = scenario.probability;
                columnElement.cashflowAmount = scenario.cashflows.at(0)?.amount ?? EMPTY_MONEY_WITH_CURRENCY;
                columnElement.navMultiple = scenario.navMultiple;
                columnElement.primaryTVPI = scenario.primaryTVPI;
                columnElement.secondaryTVPI = scenario.secondaryTVPI;
                columnElement.secondaryIRR = scenario.secondaryIRR;
            }
            columnElements.push(columnElement);
        };

        tableColumns.filter(c => c !== "name" || !c.includes("Calc")).forEach((column) => {
            const scenario = scenarios.find(s => s.scenario.code?.toUpperCase() === column.toUpperCase());
            createColumnElement(scenario, column);
        });

        tableColumns.filter(c => c !== "name" || c.includes("Calc")).forEach((column) => {
            const calcScenario = calcScenarios.find(s => s.scenario.code?.toUpperCase() === column.replace("Calc", "").toUpperCase());
            createColumnElement(calcScenario, column);
        });

        tableRowDefinition.forEach((rowDefinition) => {
            const row: Row = {definition: "", name: "", low: undefined, base: undefined, high: undefined};
            const lowCaseScenario: { [index: string]: any } | undefined = columnElements.find(c => c.name === "low");
            const baseCaseScenario: { [index: string]: any } | undefined = columnElements.find(c => c.name === "base");
            const highCaseScenario: { [index: string]: any } | undefined = columnElements.find(c => c.name === "high");
            const lowCaseScenarioCalc: { [index: string]: any } | undefined = columnElements.find(c => c.name === "lowCalc");
            const baseCaseScenarioCalc: { [index: string]: any } | undefined = columnElements.find(c => c.name === "baseCalc");
            const highCaseScenarioCalc: { [index: string]: any } | undefined = columnElements.find(c => c.name === "highCalc");

            row.definition = rowDefinition.definition;
            row.name = rowDefinition.name;
            row.low = lowCaseScenario ? lowCaseScenario[rowDefinition.definition] : null;
            row.base = baseCaseScenario ? baseCaseScenario[rowDefinition.definition] : null;
            row.high = highCaseScenario ? highCaseScenario[rowDefinition.definition] : null;
            row.lowCalc = lowCaseScenarioCalc ? lowCaseScenarioCalc[rowDefinition.definition] : null;
            row.baseCalc = baseCaseScenarioCalc ? baseCaseScenarioCalc[rowDefinition.definition] : null;
            row.highCalc = highCaseScenarioCalc ? highCaseScenarioCalc[rowDefinition.definition] : null;

            tableDataSource.push(row);
        });

        const getMostRecentCashflowDate = (scenarioArray: AssetValuationScenario[]): DateTime | undefined => {
            const cashflowDates = scenarioArray.flatMap(s => s.cashflows.map(c => DateUtil.fromIsoDate(c.date.date)));
            return DateUtil.mostRecent(...cashflowDates);
        };
        const mostRecentCashflowDate = getMostRecentCashflowDate(scenarios)?.toJSDate();
        const mostRecentCalcCashflowDate = getMostRecentCashflowDate(calcScenarios)?.toJSDate();

        return {
            tableDataSource,
            tableColumns,
            persistedAssetValuation: assetValuation.data,
            persistedScenarios: scenarios,
            calcAssetValuation,
            calcScenarios,
            discountMultipleValuationEditable,
            rationaleIsEditable,
            mostRecentCashflowDate,
            mostRecentCalcCashflowDate,
            fundCurrency
        };
    }
);
