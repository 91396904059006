<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Treemap Actual - % of Total NAV</mat-card-title>
    </mat-card-header>

    <ng-container *ngIf="(treemapDataFromPartnershipInvestment$ | async) as treemapData">

        <mat-card-content *ngIf="treemapData.length === 0" class="detail-card-content" style="color: grey">
            No data available to display the treemap.
        </mat-card-content>

        <canvas #fundTreemap id="fund-treemap" [class.hidden]="treemapData.length === 0 || filteredData.length === 0"></canvas>

        <mat-card-content *ngIf="treemapData.length > 0 && filteredData.length === 0" class="detail-card-content" style="color: grey">
            Cannot display the treemap because all assets are missing the GP NAV.
        </mat-card-content>

        <valumize-info-panel
            *ngIf="filteredData.length > 0 && missingGpNAVAssets.length > 0"  appearance="WARNING"
            [message]="'The treemap is incomplete because the following assets are missing the GP NAV: ' + missingGpNAVAssets.join(', ')"
            [closable]="true">
        </valumize-info-panel>
    </ng-container>

    <mat-card-actions class="detail-card-actions" *ngIf="filteredData.length > 0">
        <mat-card-content class="legend-container">
            <span class="legend-title">Color by Total TVPI:</span>
            <div class="gradient-bar">
                <span class="label start">0</span>
                <span class="label midpoint">1</span>
                <span class="label end">>3</span>
            </div>
        </mat-card-content>
    </mat-card-actions>

</mat-card>
