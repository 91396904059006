import {Component} from "@angular/core";
import {selectDeals} from "../../store/deal/deal.selectors";
import {Store} from "@ngrx/store";
import {DealListActions, ProjectReportActions} from "../../store/deal.actions";
import {Deal} from "../../models/deal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {noWhitespaceValidator} from "../../../shared/utils/form-validators";
import {DateUtil} from "../../../shared/utils/date-util";

@Component({
    selector: "valumize-project-report-dialog",
    templateUrl: "./project-report-dialog.component.html",
    styleUrls: ["./project-report-dialog.component.scss"]
})
export class ProjectReportDialogComponent {

    selectableDealsColumns = ["dealName", "add"];
    selectedDealsColumns = ["dealName", "remove"];

    deals$ = this.store.select(selectDeals);

    selectedDeals: Deal[] = [];

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder) {
        this.store.dispatch(DealListActions.load());
    }

    projectReportForm: FormGroup = this.formBuilder.group({
        projectName: this.formBuilder.control<string>("", {nonNullable: true, validators: [Validators.required, noWhitespaceValidator()]}),
        projectClosingDate: this.formBuilder.control<Date | null>(null),
        firstRoundOffersDeadline: this.formBuilder.control<Date | null>(null)
    });

    addDealToSelection(dealToAdd: Deal) {
        this.selectedDeals = [...this.selectedDeals, dealToAdd];
    }

    removeDealFromSelection(dealToRemove: number) {
        this.selectedDeals = this.selectedDeals.filter(deal => deal.id !== dealToRemove);
    }

    filterNotSelected(deals: Deal[]): Deal[] {
        return deals.filter(deal => !this.selectedDeals.map(selectedDeal => selectedDeal.id).includes(deal.id ?? -1));
    }

    generateReport = () => this.store.dispatch(ProjectReportActions.generate({
        projectName: this.projectReportForm.controls["projectName"].value,
        projectClosingDate: DateUtil.toIsoDate(this.projectReportForm.controls["projectClosingDate"].value),
        firstRoundOffersDeadline: DateUtil.toIsoDate(this.projectReportForm.controls["firstRoundOffersDeadline"].value),
        deals: this.selectedDeals
    }));
}
