import {Component, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {Store} from "@ngrx/store";
import {FormBuilder, Validators} from "@angular/forms";
import {map, Subscription} from "rxjs";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {selectSelectedFundInvestment} from "../../../fund/store/fund-investment/fund-investment.selectors";
import {GeneralPartnerValuationActions} from "../../store/asset.actions";
import {FundInvestment} from "../../../fund/models/fund-investement";
import {EMPTY_FUND_INVESTMENT} from "../../../fund/store/fund.reducer";
import {
    selectGeneralPartnerValuation,
    selectGeneralPartnerValuationTableData,
    selectGeneralPartnerValuationView,
    selectIsAnyAssetFormInEditMode
} from "../../store/asset/asset.selectors";

@Component({
    selector: "valumize-asset-gp-valuation",
    templateUrl: "./asset-gp-valuation.component.html"
})
export class AssetGpValuationComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    selectedFundInvestment$ = this.store.select(selectSelectedFundInvestment);
    generalPartnerValuation$ = this.store.select(selectGeneralPartnerValuation);
    generalPartnerValuationTableData$ = this.store.select(selectGeneralPartnerValuationTableData);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    isEditable = false;
    persistedFundInvestment: FundInvestment = EMPTY_FUND_INVESTMENT;
    fundId: number | undefined;
    fundReportId: number | undefined;

    displayedColumns: string[] = ["name", "fundLevel"];

    gpValuationForm = this.formBuilder.group({
        remainingCost: this.formBuilder.control<number | null>(null, {validators: [Validators.required, numberNotBiggerThan190000m()]}),
        mezzanine: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        preferredEquity: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        commonEquity: this.formBuilder.control<number | null>(null, {validators: [Validators.required, numberNotBiggerThan190000m()]}),
        realizedCost: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        realizedGains: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
    });

    nextFocus: string | undefined;
    controlNamesForFocus = ["remainingCost", "mezzanine", "preferredEquity", "commonEquity", "realizedCost", "realizedGains"];

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder,
                private readonly renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.store.select(selectSelectedFundInvestment).pipe(map((fundInvestment) => {
            this.persistedFundInvestment = fundInvestment.data;
        })).subscribe());

        this.subscriptions.push(this.store.select(selectGeneralPartnerValuationView).pipe(map((gpValuation) => {
            this.isEditable = gpValuation.isEditable;
            const gpValuationEntry = gpValuation.gpValuation.data;
            if (gpValuationEntry) {
                this.gpValuationForm.patchValue({
                    remainingCost: DecimalFormatPipe.transformFromMillionsNum(gpValuationEntry.remainingCost?.amount ?? undefined),
                    mezzanine: DecimalFormatPipe.transformFromMillionsNum(gpValuationEntry.mezzanine?.amount ?? undefined),
                    preferredEquity: DecimalFormatPipe.transformFromMillionsNum(gpValuationEntry.preferredEquity?.amount ?? undefined),
                    commonEquity: DecimalFormatPipe.transformFromMillionsNum(gpValuationEntry.commonEquity?.amount ?? undefined),
                    realizedCost: DecimalFormatPipe.transformFromMillionsNum(gpValuationEntry.realizedCost?.amount ?? undefined),
                    realizedGains: DecimalFormatPipe.transformFromMillionsNum(gpValuationEntry.realizedGains?.amount ?? undefined),
                });
            }
            if (this.isEditable) {
                this.gpValuationForm.enable();
                // Use a setTimeout to refocus on the next Javascript event loop tick,
                // giving Angular a chance to update the DOM first
                setTimeout(() => {
                    if (this.nextFocus) {
                        this.renderer.selectRootElement("#" + this.nextFocus).focus();
                    }
                }, 0);
            } else {
                this.gpValuationForm.disable();
            }
        })).subscribe());
    }

    calc = (focus: string) => {
        const currentIndex = this.controlNamesForFocus.findIndex(control => control === focus);
        this.nextFocus = this.controlNamesForFocus[currentIndex + 1] || this.controlNamesForFocus[0];
        if (this.gpValuationForm.valid) {
            const form = this.gpValuationForm.getRawValue();
            this.store.dispatch(GeneralPartnerValuationActions.calc({
                remainingCost: DecimalFormatPipe.transformToMillionsNum(form.remainingCost),
                mezzanine: DecimalFormatPipe.transformToMillionsNum(form.mezzanine),
                preferredEquity: DecimalFormatPipe.transformToMillionsNum(form.preferredEquity),
                commonEquity: DecimalFormatPipe.transformToMillionsNum(form.commonEquity),
                realizedCost: DecimalFormatPipe.transformToMillionsNum(form.realizedCost),
                realizedGains: DecimalFormatPipe.transformToMillionsNum(form.realizedGains)
            }));
        }
    };

    save() {
        if (this.gpValuationForm.valid) {
            const form = this.gpValuationForm.getRawValue();
            const fundInvestmentToSafe: FundInvestment = {
                ...this.persistedFundInvestment,
                remainingCost: {
                    ...this.persistedFundInvestment?.remainingCost,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.remainingCost)
                },
                mezzanine: {
                    ...this.persistedFundInvestment?.mezzanine,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.mezzanine)
                },
                preferredEquity: {
                    ...this.persistedFundInvestment?.preferredEquity,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.preferredEquity)
                },
                commonEquity: {
                    ...this.persistedFundInvestment?.commonEquity,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.commonEquity)
                },
                realizedCost: {
                    ...this.persistedFundInvestment?.realizedCost,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.realizedCost)
                },
                realizedGains: {
                    ...this.persistedFundInvestment?.realizedGains,
                    amount: DecimalFormatPipe.transformToMillionsNum(form.realizedGains)
                }
            };
            this.store.dispatch(GeneralPartnerValuationActions.save({fundInvestment: fundInvestmentToSafe ?? EMPTY_FUND_INVESTMENT}));
        }
    }

    editMode = () => this.store.dispatch(GeneralPartnerValuationActions.edit());

    cancel = () => this.store.dispatch(GeneralPartnerValuationActions.cancel());

    formControlIsEditable(type: string): boolean {
        return this.isEditable && (
            type === "remainingCost" ||
            type === "mezzanine" ||
            type === "preferredEquity" ||
            type === "commonEquity" ||
            type === "realizedCost" ||
            type === "realizedGains"
        );
    }

    styleRequiredFormFieldWithAsterisk(type: string): string {
        return type === "remainingCost" || type === "commonEquity" ? "required-asterisk" : "";
    }

    styleRowImportant(row: any): string {
        const importantRowTypes = ["nav"];
        return (importantRowTypes.includes(row.type)) ? "row-important" : "";
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
