import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {selectFunds} from "../store/fund/fund.selectors";
import {FundListActions} from "../store/fund.actions";
import {FundNewDialogComponent} from "../fund-new-dialog/fund-new-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {map} from "rxjs/operators";
import {CodeTableEnum} from "../../shared/model/code";
import {Subscription} from "rxjs";

@Component({
    selector: "valumize-fund-overview-page",
    templateUrl: "./fund-overview-page.component.html",
    styleUrls: ["./fund-overview-page.component.scss"]
})
export class FundOverviewPageComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    columns = ["fundName", "country", "currencyIso", "size", "mainFundSize", "vintageYear", "endYear"];
    fundDataSource: MatTableDataSource<any> = new MatTableDataSource();
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;

    @ViewChild("fundPaging") fundPaging!: MatPaginator;
    @ViewChild("fundSort") fundSort = new MatSort();

    constructor(private readonly store: Store, public dialog: MatDialog) {
        this.store.dispatch(FundListActions.open());
    }

    ngOnInit() {
        this.subscription = this.store.select(selectFunds).pipe(
            map(funds => {
                this.fundDataSource.data = funds;
                this.fundDataSource.paginator = this.fundPaging;
                this.fundDataSource.sort = this.fundSort;
                this.fundDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case "fundName":
                            return item.name.text;
                        case "country":
                            return item.country.code;
                        case "currencyIso":
                            return item.currencyIso.code;
                        case "size":
                            return item.size.amount;
                        case "mainFundSize":
                            return item.mainFundSize.amount;
                        case "vintageYear":
                            return item.vintageYear.date;
                        case "endYear":
                            return item.endYear.date;
                        default:
                            return item[property];
                    }
                };
                this.fundDataSource.sort.sort({
                    id: "fundName",
                    start: "asc",
                    disableClear: true
                });
            })
        ).subscribe();
    }

    openNewFundDialog() {
        this.dialog.open(FundNewDialogComponent);
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
