import {AvailablePeriodDates} from "../model/period";
import {DateUtil} from "./date-util";

export class PeriodUtil {

    static fiscalYearEndMonthMap: { [key: string]: number } = {
        END_OF_JANUARY: 1,
        END_OF_FEBRUARY: 2,
        END_OF_MARCH: 3,
        END_OF_APRIL: 4,
        END_OF_MAY: 5,
        END_OF_JUNE: 6,
        END_OF_JULY: 7,
        END_OF_AUGUST: 8,
        END_OF_SEPTEMBER: 9,
        END_OF_OCTOBER: 10,
        END_OF_NOVEMBER: 11,
        END_OF_DECEMBER: 12
    };

    static getLastDayOfMonth(month: number, year: number): number {
        const lastDayMap: { [key: number]: number } = {};

        lastDayMap[1] = 31;
        lastDayMap[2] = 28;
        lastDayMap[3] = 31;
        lastDayMap[4] = 30;
        lastDayMap[5] = 31;
        lastDayMap[6] = 30;
        lastDayMap[7] = 31;
        lastDayMap[8] = 31;
        lastDayMap[9] = 30;
        lastDayMap[10] = 31;
        lastDayMap[11] = 30;
        lastDayMap[12] = 31;

        // Return 29 for February if it's a leap year
        if (month === 2 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
            return 29;
        }

        return lastDayMap[month];
    }

    static adjustFiscalYear(month: number, currentYear: number, fiscalYearEndMonth: number): number {
        return month > fiscalYearEndMonth ? currentYear - 1 : currentYear;
    }

    static generateAvailablePeriodDates(fiscalYearEndMonth: string | undefined): AvailablePeriodDates {
        const endMonth =  fiscalYearEndMonth ? this.fiscalYearEndMonthMap[fiscalYearEndMonth] || 12 : 12;
        const currentYear = new Date().getFullYear();
        const pastYears = 30;
        const futureYears = 10;

        const quarterEndDates: string[] = [];
        const semesterEndDates: string[] = [];
        const fullFiscalYearEndDates: string[] = [];

        for (let year = currentYear + futureYears; year >= currentYear - pastYears; year--) {

            const endDateOfQuarters = [
                {// Q4 (fiscal year end)
                    month: endMonth,
                    day: this.getLastDayOfMonth(endMonth, year),
                    year
                },
                { // Q3
                    month: (endMonth - 3 + 12) % 12 || 12,
                    day: this.getLastDayOfMonth((endMonth - 3 + 12) % 12 || 12, year),
                    year: this.adjustFiscalYear((endMonth - 3 + 12) % 12 || 12, year, endMonth)
                },
                { // Q2
                    month: (endMonth - 6 + 12) % 12 || 12,
                    day: this.getLastDayOfMonth((endMonth - 6 + 12) % 12 || 12, year),
                    year: this.adjustFiscalYear((endMonth - 6 + 12) % 12 || 12, year, endMonth)
                },
                {  // Q1
                    month: (endMonth - 9 + 12) % 12 || 12,
                    day: this.getLastDayOfMonth((endMonth - 9 + 12) % 12 || 12, year),
                    year: this.adjustFiscalYear((endMonth - 9 + 12) % 12 || 12, year, endMonth)
                }
            ];

            const endDateOfSemesters = [
                { // Semester 2 (fiscal year end)
                    month: endMonth,
                    day: this.getLastDayOfMonth(endMonth, year),
                    year
                },
                {  // Semester 1
                    month: (endMonth - 6 + 12) % 12 || 12,
                    day: this.getLastDayOfMonth((endMonth - 6 + 12) % 12 || 12, year),
                    year: this.adjustFiscalYear((endMonth - 6 + 12) % 12 || 12, year, endMonth)
                }
            ];

            const endDateOfYear = {month: endMonth, day: this.getLastDayOfMonth(endMonth, year), year};

            // Convert quarter end dates to ISO format
            endDateOfQuarters.forEach(quarter => {
                const dateQ = DateUtil.toIsoDate(new Date(quarter.year, quarter.month - 1, quarter.day));
                if (dateQ) {quarterEndDates.push(dateQ);}
            });

            // Convert semester end dates to ISO format
            endDateOfSemesters.forEach(semester => {
                const dateS = DateUtil.toIsoDate(new Date(semester.year, semester.month - 1, semester.day));
                if (dateS) {semesterEndDates.push(dateS);}
            });

            // Convert full fiscal year end date to ISO format
            const dateY = DateUtil.toIsoDate(new Date(endDateOfYear.year, endDateOfYear.month - 1, endDateOfYear.day));
            if (dateY) {fullFiscalYearEndDates.push(dateY);}

        }

        return {
            QUARTER: quarterEndDates,
            SEMESTER: semesterEndDates,
            FULL_FISCAL_YEAR: fullFiscalYearEndDates
        };
    }
}
