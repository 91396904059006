<ng-container *ngIf="(groupedDealCompanies$ | async) as data">
    <div class="treemap-container" *ngIf="data.length > 0; else treemapWarning">
        <canvas #dealsTreemap></canvas>
    </div>
    <ng-template #treemapWarning>
        <div class="detail-card-content" style="color: grey">
            For displaying the companies treemap, you need to add some data first.
        </div>
    </ng-template>
</ng-container>
