import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {FormBuilder} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, Subscription} from "rxjs";
import {selectBaselineFundReport, selectSelectedFundReport, selectSelectedFundReportReportDate} from "../../store/fund-report/fund-report.selectors";
import {FundReportActions} from "../../store/fund.actions";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {FundReport} from "../../models/fund-report";
import {EMPTY_FUND_REPORT} from "../../store/fund.reducer";
import {selectIsAnyFundFormInEditMode, selectSelectedFund} from "../../store/fund/fund.selectors";
import Big from "big.js";
import {TraceableMoney} from "../../../shared/model/traceable";


@Component({
    selector: "valumize-fund-report-details",
    templateUrl: "./fund-report-details.component.html",
    styleUrls: ["./fund-report-details.component.scss"]
})
export class FundReportDetailsComponent implements OnInit, OnDestroy {


    fundReport$ = this.store.select(selectSelectedFundReport);
    reportDate$ = this.store.select(selectSelectedFundReportReportDate);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);

    fundReport = EMPTY_FUND_REPORT;
    fundId?: number;
    fundSize: TraceableMoney | undefined;
    isDataset = false;
    baselineReport: FundReport = EMPTY_FUND_REPORT;

    subscriptions: Subscription[] = [];
    calculationSubscription: Subscription | undefined;

    fundReportDetailFormGroup = this.formBuilder.group({
        totalDrawDowns: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        totalDistributions: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        reportedUndrawn: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m]}),
        recallableDistributions: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]}),
        carryPaidToDate: this.formBuilder.control<number | null>(null, {validators: [numberNotBiggerThan190000m()]})
    });

    constructor(private readonly store: Store,
                private readonly formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(selectSelectedFund).pipe(map((fund) => {
                this.fundId = fund.fund.id;
                this.fundSize = fund.fund.size;
            })).subscribe());

        this.subscriptions.push(this.store.select(selectSelectedFundReport).pipe(
            map(fundReport => {
                this.fundReport = fundReport.fundReport.data;
                this.fundReportDetailFormGroup.patchValue({
                    totalDrawDowns: DecimalFormatPipe.transformFromMillionsNum(fundReport.fundReport.data.totalDrawDowns.amount ?? undefined),
                    totalDistributions: DecimalFormatPipe.transformFromMillionsNum(fundReport.fundReport.data.totalDistributions.amount ?? undefined),
                    reportedUndrawn: DecimalFormatPipe.transformFromMillionsNum(fundReport.fundReport.data.reportedUndrawn.amount ?? undefined),
                    recallableDistributions: DecimalFormatPipe.transformFromMillionsNum(fundReport.fundReport.data.recallableDistributions.amount ?? undefined),
                    carryPaidToDate: DecimalFormatPipe.transformFromMillionsNum(fundReport.fundReport.data.carryPaidToDate.amount ?? undefined)
                });
            })).subscribe());

        this.subscriptions.push(
            this.store.select(selectBaselineFundReport).pipe(map((baselineFundReport) => {
                if (baselineFundReport.status === "LOADED") {
                    this.isDataset = true;
                    this.baselineReport = baselineFundReport.data;
                } else {
                    this.isDataset = false;
                }
            })).subscribe());
    }

    edit() {
        this.store.dispatch(FundReportActions.edit());
        this.attachCalculationListeners();
    }

    save() {
        if (!!this.fundId) {
            this.store.dispatch(FundReportActions.save(
                {
                    fundId: this.fundId,
                    fundReport: {
                        ...this.fundReport,
                        ...{
                            totalDrawDowns: {
                                ...this.fundReport.totalDrawDowns,
                                amount: DecimalFormatPipe.transformToMillionsNum(this.fundReportDetailFormGroup.controls.totalDrawDowns.value ?? undefined)
                            },
                            totalDistributions: {
                                ...this.fundReport.totalDistributions,
                                amount: DecimalFormatPipe.transformToMillionsNum(this.fundReportDetailFormGroup.controls.totalDistributions.value ?? undefined)
                            },
                            reportedUndrawn: {
                                ...this.fundReport.reportedUndrawn,
                                amount: DecimalFormatPipe.transformToMillionsNum(this.fundReportDetailFormGroup.controls.reportedUndrawn.value ?? undefined)
                            },
                            recallableDistributions: {
                                ...this.fundReport.recallableDistributions,
                                amount: DecimalFormatPipe.transformToMillionsNum(this.fundReportDetailFormGroup.controls.recallableDistributions.value ?? undefined)
                            },
                            carryPaidToDate: {
                                ...this.fundReport.carryPaidToDate,
                                amount: DecimalFormatPipe.transformToMillionsNum(this.fundReportDetailFormGroup.controls.carryPaidToDate.value ?? undefined)
                            }
                        }
                    }
                }
            ));
        }
        this.calculationSubscription?.unsubscribe();
    }

    cancel() {
        this.calculationSubscription?.unsubscribe();
        this.store.dispatch(FundReportActions.cancel());
    };

    attachCalculationListeners() {
        const controlsToWatch = ["reportedUndrawn", "recallableDistributions"];

        controlsToWatch.forEach(controlName => {
            const control = this.fundReportDetailFormGroup.get(controlName);
            if (control) {
                this.calculationSubscription = control.valueChanges.pipe(
                        debounceTime(300),
                        distinctUntilChanged((prev, curr) => prev === curr),
                    ).subscribe(() => {
                        this.triggerCalculation();
                    });
            }
        });
    }

    triggerCalculation() {
        if (this.fundReportDetailFormGroup.valid) {
            const form = this.fundReportDetailFormGroup.getRawValue();
            this.store.dispatch(FundReportActions.calc({
                fundReport: {
                    ...this.fundReport,
                    totalDrawDowns: {
                        ...this.fundReport.totalDrawDowns,
                        amount: DecimalFormatPipe.transformToMillionsNum(form.totalDrawDowns)
                    },
                    totalDistributions: {
                        ...this.fundReport.totalDistributions,
                        amount: DecimalFormatPipe.transformToMillionsNum(form.totalDistributions)
                    },
                    reportedUndrawn: {
                        ...this.fundReport.reportedUndrawn,
                        amount: DecimalFormatPipe.transformToMillionsNum(form.reportedUndrawn)
                    },
                    recallableDistributions: {
                        ...this.fundReport.recallableDistributions,
                        amount: DecimalFormatPipe.transformToMillionsNum(form.recallableDistributions)
                    },
                    carryPaidToDate: {
                        ...this.fundReport.carryPaidToDate,
                        amount: DecimalFormatPipe.transformToMillionsNum(form.carryPaidToDate)
                    }
                }
            }));
        }
    }

    calculateCurrentTVPI(fundReport: FundReport): Big | undefined {
        if (!fundReport.totalDrawDowns?.amount || !fundReport.totalDistributions?.amount || !fundReport.totalNav?.amount || fundReport.totalDrawDowns?.amount === 0) {
            return undefined;
        }

        const totalDistributions = new Big(fundReport.totalDistributions.amount);
        const totalNAV = new Big(fundReport.totalNav.amount);
        const totalDrawDowns = new Big(fundReport.totalDrawDowns.amount);

        return totalDistributions.plus(totalNAV).div(totalDrawDowns);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.calculationSubscription?.unsubscribe();
    }
}
