import {createReducer, on} from "@ngrx/store";
import {AssetState} from "./asset.state";
import {Asset} from "../models/asset/asset";
import {AssetValuation} from "../models/asset-valuation/asset-valuation";
import {
    AssetActions,
    AssetDescriptionActions,
    AssetDiscountValuationActions,
    AssetFinancialHistoryActions,
    AssetGeneralInformationActions,
    AssetListActions,
    AssetNameActions,
    AssetNoteActions,
    AssetOperationalValuationActions,
    AssetSwotActions,
    AssetValuationActions,
    AssetValuationScenarioActions,
    EndMarketsCustomersActions,
    FinancialPerformanceRecordsActions,
    GeneralPartnerValuationActions,
    ShareholderActions
} from "./asset.actions";
import {EMPTY_CODE, EMPTY_MODEL_DATE, EMPTY_MONEY, EMPTY_MULTIPLE, EMPTY_PERCENT, EMPTY_PERIOD, EMPTY_TEXT} from "../../shared/model/traceable";
import {EMPTY_FUND_INVESTMENT} from "../../fund/store/fund.reducer";
import {FundInvestmentActions} from "../../fund/store/fund.actions";
import {FinancialPerformanceRecord} from "../models/asset/financial-performance-record";
import {Shareholder} from "../models/asset/shareholder";
import {Swot} from "../models/asset/swot";
import {ScenarioType} from "../../shared/model/scenario-type";
import {ValuationTypeEnum} from "../models/asset-valuation/valuation-type.enum";
import {AssetValuationScenario} from "../models/asset-valuation/asset-valuation-scenario";

export const assetStoreFeatureKey = "valumize/asset";

export const EMPTY_SWOT: Swot = {
    swotStrengths: EMPTY_TEXT,
    swotWeaknesses: EMPTY_TEXT,
    swotOpportunities: EMPTY_TEXT,
    swotThreats: EMPTY_TEXT,
};

export const EMPTY_ASSET: Asset = {
    id: undefined,
    name: EMPTY_TEXT,
    description: EMPTY_TEXT,
    url: EMPTY_TEXT,
    regions: [],
    country: EMPTY_CODE,
    activityDescription: EMPTY_TEXT,
    stage: EMPTY_CODE,
    gic: EMPTY_CODE,
    gpIndication: EMPTY_CODE,
    currencyIso: EMPTY_CODE,
    esg: EMPTY_CODE,
    assessment: EMPTY_CODE,
    fiscalYearEnd: EMPTY_CODE,
    endMarketsCustomers: EMPTY_TEXT,
    swot: EMPTY_SWOT,
    publiclyListed: false,
    dataset: "master",
    mergeTargetId: undefined
};

export const EMPTY_ASSET_VALUATION: AssetValuation = {
    id: undefined,
    reportDate: EMPTY_MODEL_DATE,
    fxRateFund: EMPTY_PERCENT,
    requiredGrossIRR: EMPTY_PERCENT,
    finalBidPrice: EMPTY_MONEY,
    exitDateAssumption: EMPTY_MODEL_DATE,
    rationale: EMPTY_TEXT,
    valuationType: EMPTY_CODE,
    earningBase: EMPTY_CODE,
    weightedHoldingPeriod: undefined,
    exitProceeds: EMPTY_MONEY,
    exitProceedsFundCurrency: EMPTY_MONEY,
    enterpriseValueOverEarningBaseMultiple: EMPTY_MULTIPLE,
    enterpriseValueOverRevenueMultiple: EMPTY_MULTIPLE,
    navMultiple: EMPTY_MULTIPLE,
    secondaryTVPI: EMPTY_MULTIPLE,
    secondaryIRR: EMPTY_PERCENT,
    discountToGpNAV: EMPTY_PERCENT
};

export const EMPTY_ASSET_VALUATION_SCENARIO: AssetValuationScenario = {
    id: undefined,
    probability: EMPTY_PERCENT,
    scenario: EMPTY_CODE,
    navMultiple: EMPTY_MULTIPLE,
    primaryTVPI: EMPTY_MULTIPLE,
    secondaryTVPI: EMPTY_MULTIPLE,
    secondaryIRR: EMPTY_PERCENT,
    enterpriseValueOverEarningBaseMultiple: EMPTY_MULTIPLE,
    revenuesUplift: EMPTY_MONEY,
    earningBaseUplift: EMPTY_MONEY,
    debtRepayment: EMPTY_MONEY,
    revenuesCAGR: EMPTY_PERCENT,
    earningBaseCAGR: EMPTY_PERCENT,
    debtRepaymentCapacity: EMPTY_PERCENT,
    cashflows: []
};

export const EMPTY_FINANCIAL_PERFORMANCE_RECORD: FinancialPerformanceRecord = {
    id: undefined,
    period: EMPTY_PERIOD,
    currencyIso: EMPTY_CODE,
    revenue: EMPTY_MONEY,
    revenueGrowth: EMPTY_PERCENT,
    debtRepaymentCapacity: EMPTY_PERCENT,
    netDebt: EMPTY_MONEY,
    enterpriseValue: EMPTY_MONEY,
    commonEquity: EMPTY_MONEY,
    ebt: EMPTY_MONEY,
    ebtMargin: EMPTY_PERCENT,
    ebtGrowth: EMPTY_PERCENT,
    ebit: EMPTY_MONEY,
    ebitMargin: EMPTY_PERCENT,
    ebitGrowth: EMPTY_PERCENT,
    ebita: EMPTY_MONEY,
    ebitaMargin: EMPTY_PERCENT,
    ebitaGrowth: EMPTY_PERCENT,
    ebitda: EMPTY_MONEY,
    ebitdaMargin: EMPTY_PERCENT,
    ebitdaGrowth: EMPTY_PERCENT,
    ebitdar: EMPTY_MONEY,
    ebitdarMargin: EMPTY_PERCENT,
    ebitdarGrowth: EMPTY_PERCENT,
    priceOverEarningsMultiple: EMPTY_MULTIPLE,
    enterpriseValueOverEbitMultiple: EMPTY_MULTIPLE,
    enterpriseValueOverEbitaMultiple: EMPTY_MULTIPLE,
    enterpriseValueOverEbitdaMultiple: EMPTY_MULTIPLE,
    enterpriseValueOverEbitdarMultiple: EMPTY_MULTIPLE,
    enterpriseValueOverRevenueMultiple: EMPTY_MULTIPLE,
};

export const EMPTY_SHAREHOLDER: Shareholder = {
    id: undefined,
    fundInvestmentId: undefined,
    date: EMPTY_MODEL_DATE,
    shareholderName: EMPTY_TEXT,
    stake: EMPTY_PERCENT,
    source: EMPTY_CODE
};

export const INITIAL_STATE: AssetState = {
    assets: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedAsset: {
        data: EMPTY_ASSET,
        status: "INIT",
        errorMessage: ""
    },
    baselineAsset: {
        data: EMPTY_ASSET,
        status: "INIT",
        errorMessage: ""
    },
    selectedAssetNotes: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedAssetValuationsForAsset: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    preferredValuationType: ValuationTypeEnum.DiscountMultipleValuation,
    discountMultipleValuation: {
        selectedAssetValuation: {
            data: EMPTY_ASSET_VALUATION,
            status: "INIT",
            errorMessage: ""
        },
        calculatedAssetValuation: {
            data: EMPTY_ASSET_VALUATION,
            status: "INIT",
            errorMessage: ""
        },
        selectedAssetValuationScenarios: {
            data: [],
            status: "INIT",
            errorMessage: ""
        },
        calculatedAssetValuationScenarios: {
            data: [],
            status: "INIT",
            errorMessage: ""
        }
    },
    financialOperationalValuation: {
        selectedAssetValuation: {
            data: EMPTY_ASSET_VALUATION,
            status: "INIT",
            errorMessage: ""
        },
        calculatedAssetValuation: {
            data: EMPTY_ASSET_VALUATION,
            status: "INIT",
            errorMessage: ""
        },
        selectedAssetValuationScenarios: {
            data: [],
            status: "INIT",
            errorMessage: ""
        },
        calculatedAssetValuationScenarios: {
            data: [],
            status: "INIT",
            errorMessage: ""
        },
        selectedFinancialPerformanceRecords: {
            LOW: {
                data: [],
                status: "INIT",
                errorMessage: ""
            },
            BASE: {
                data: [],
                status: "INIT",
                errorMessage: ""
            },
            HIGH: {
                data: [],
                status: "INIT",
                errorMessage: ""
            }
        }
    },
    selectedGeneralPartnerValuation: {
        generalPartnerValuation: {
            data: EMPTY_FUND_INVESTMENT,
            status: "INIT",
            errorMessage: ""
        },
        generalPartnerValuationCalc: {
            data: EMPTY_FUND_INVESTMENT,
            status: "INIT",
            errorMessage: ""
        }
    },
    selectedAssetFinancialHistory: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    baselineAssetFinancialHistory: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedShareholders: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    selectedShareholdersForDate: {
        data: [],
        status: "INIT",
        errorMessage: ""
    },
    generatedAssetDescription: {
        gptAssetDescriptionText: {
            data: EMPTY_TEXT,
            status: "INIT",
            errorMessage: ""
        },
        gptAssetDescriptionIsEditable: false
    },
    generatedEndMarketsDescription: {
        gptEndMarketsText: {
            data: EMPTY_TEXT,
            status: "INIT",
            errorMessage: ""
        },
        gptEndMarketsIsEditable: false
    },
    generatedSwotAnalysis: {
        gptSwot: {
            data: EMPTY_SWOT,
            status: "INIT",
            errorMessage: ""
        },
        gptSwotIsEditable: false
    },
    assetForms: {
        assetNameIsEditable: false,
        assetSwotIsEditable: false,
        assetDescriptionIsEditable: false,
        endMarketsCustomersIsEditable: false,
        exitsIsEditable: false,
        generalPartnerValuationIsEditable: false,
        discountMultipleValuationIsEditable: false,
        financialOperationalValuationIsEditable: {
            valuationIsEditable: false,
            LOW: {
                recordsAreEditable: false
            },
            BASE: {
                recordsAreEditable: false
            },
            HIGH: {
                recordsAreEditable: false
            },
        },
        dmvRationaleIsEditable: false,
        operationalRationaleIsEditable: false,
        generalInformationIsEditable: false,
        assetFinancialHistoryIsEditable: false,
        shareholderStructureIsEditable: false,
    },
    availablePeriodDates: {
        QUARTER: [],
        SEMESTER: [],
        FULL_FISCAL_YEAR: []
    }
};

export const assetReducer = createReducer(
    INITIAL_STATE,

    // Clear All Selected Asset Data
    on(
        AssetListActions.open,
        AssetActions.open,
        AssetActions.create,
        AssetActions.opendetailsdialog,
        (): AssetState => (INITIAL_STATE)),

    // Asset List
    on(AssetListActions.load, (state): AssetState => ({
        ...state,
        assets: {
            ...state.assets,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(AssetListActions.loadsuccess, (state, action): AssetState => ({
        ...state,
        assets: {
            data: action.assets,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AssetListActions.loaderror, (state, action): AssetState => ({
        ...state,
        assets: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Asset
    on(AssetActions.open, (state): AssetState => ({
        ...INITIAL_STATE,
        preferredValuationType: state.preferredValuationType
    })),
    on(AssetActions.create, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...INITIAL_STATE.selectedAsset,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(AssetActions.load, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...INITIAL_STATE.selectedAsset,
            ...{
                status: "LOADING"
            }
        },
        selectedAssetNotes: {
            ...state.selectedAssetNotes,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(
        AssetActions.loadsuccess,
        AssetActions.createsuccess
        , (state, action): AssetState => ({
        ...state,
        selectedAsset: {
            data: action.asset,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AssetActions.generateperioddatessuccess, (state, action): AssetState => ({
        ...state,
        availablePeriodDates: action.availablePeriodDates
    })),
    on(AssetActions.loadbaseline, (state): AssetState => ({
        ...state,
        baselineAsset: {
            ...INITIAL_STATE.baselineAsset,
            ...{
                status: "LOADING"
            }
        },
    })),
    on(AssetActions.loadbaselinesuccess, (state, action): AssetState => ({
        ...state,
        baselineAsset: {
            data: action.asset,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AssetActions.saveloadsuccess, (state, action): AssetState => ({
        ...state,
        selectedAsset: {
            data: action.asset,
            status: "SAVED",
            errorMessage: ""
        }
    })),
    on(AssetActions.loaderror, (state, action): AssetState => ({
        ...state,
        selectedAsset: {
            data: EMPTY_ASSET,
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),

    // Asset Notes
    on(AssetNoteActions.loadsuccess, (state, action): AssetState => ({
        ...state,
        selectedAssetNotes: {
            data: action.notes,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AssetNoteActions.loaderror, (state, action): AssetState => ({
        ...state,
        selectedAssetNotes: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(AssetNoteActions.save, (state): AssetState => ({
        ...state,
        selectedAssetNotes: {
            ...state.selectedAssetNotes,
            status: "LOADING"
        }
    })),
    on(AssetNoteActions.delete, (state): AssetState => ({
        ...state,
        selectedAssetNotes: {
            ...state.selectedAssetNotes,
            status: "LOADING"
        }
    })),

    // Asset Valuations
    on(AssetValuationActions.createoperationalsuccess, (state, action): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                data: action.assetValuation,
                status: "LOADED",
                errorMessage: ""
            },
            selectedAssetValuationScenarios: {
                ...state.financialOperationalValuation.selectedAssetValuationScenarios,
                status: "LOADING"
            },
            calculatedAssetValuation: {
                data: action.assetValuation,
                status: "LOADED",
                errorMessage: ""
            },
            calculatedAssetValuationScenarios: {
                ...state.financialOperationalValuation.selectedAssetValuationScenarios,
                status: "LOADING"
            },
        }
    })),
    on(AssetValuationActions.load, (state, {valuationType}): AssetState => {
        switch (valuationType) {
            case ValuationTypeEnum.DiscountMultipleValuation:
                return {
                    ...state,
                    discountMultipleValuation: {
                        ...state.discountMultipleValuation,
                        selectedAssetValuation: {
                            ...state.discountMultipleValuation.selectedAssetValuation,
                            status: "LOADING"
                        }
                    }
                };
            case ValuationTypeEnum.FinancialOperationalValuation:
                return {
                    ...state,
                    financialOperationalValuation: {
                        ...state.financialOperationalValuation,
                        selectedAssetValuation: {
                            ...state.financialOperationalValuation.selectedAssetValuation,
                            status: "LOADING"
                        }
                    }
                };
            default:
                return state;
        }
    }),
    on(AssetValuationActions.loaddmvsuccess, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuation: {
                data: action.assetValuation,
                status: "LOADED",
                errorMessage: ""
            },
            selectedAssetValuationScenarios: {
                ...state.discountMultipleValuation.selectedAssetValuationScenarios,
                status: "LOADING"
            },
        }
    })),
    on(AssetValuationActions.loadoperationalsuccess, (state, action): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                data: action.assetValuation,
                status: "LOADED",
                errorMessage: ""
            },
            selectedAssetValuationScenarios: {
                ...state.financialOperationalValuation.selectedAssetValuationScenarios,
                status: "LOADING"
            },
            calculatedAssetValuation: {
                data: action.assetValuation,
                status: "LOADED",
                errorMessage: ""
            },
            calculatedAssetValuationScenarios: {
                ...state.financialOperationalValuation.selectedAssetValuationScenarios,
                status: "LOADING"
            },
        }
    })),
    on(AssetValuationActions.loadall, (state): AssetState => ({
        ...state,
        selectedAssetValuationsForAsset: {
            ...state.selectedAssetValuationsForAsset,
            ...{
                status: "LOADING"
            }
        }
    })),
    on(AssetValuationActions.loadallsuccess, (state, action): AssetState => ({
        ...state,
        selectedAssetValuationsForAsset: {
            data: action.valuations,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AssetValuationActions.loadallerror, (state, action): AssetState => ({
        ...state,
        selectedAssetValuationsForAsset: {
            data: [],
            status: "ERROR",
            errorMessage: action.errorMsg
        }
    })),
    on(AssetValuationActions.setpreferredvaluationtype, (state, action): AssetState => ({
        ...state,
        preferredValuationType: action.valuationType,
    })),

    // Asset Valuation Scenarios
    on(AssetValuationScenarioActions.loadalldmvsuccess, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuationScenarios: {
                data: action.scenarios,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(AssetValuationScenarioActions.loadalloperationalsuccess, (state, action): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuationScenarios: {
                data: action.scenarios,
                status: "LOADED",
                errorMessage: ""
            },
            calculatedAssetValuationScenarios: {
                data: action.scenarios,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(AssetValuationScenarioActions.loaderror, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuationScenarios: {
                data: [],
                status: "ERROR",
                errorMessage: action.errorMsg
            }
        }
    })),

    // Asset General Partner Valuation
    on(GeneralPartnerValuationActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            generalPartnerValuationIsEditable: true
        }
    })),
    on(GeneralPartnerValuationActions.load, (state): AssetState => ({
        ...state,
        selectedGeneralPartnerValuation: {
            ...state.selectedGeneralPartnerValuation,
            generalPartnerValuation: {
                ...state.selectedGeneralPartnerValuation.generalPartnerValuation,
                status: "LOADING"
            },
            generalPartnerValuationCalc: {
                ...state.selectedGeneralPartnerValuation.generalPartnerValuationCalc,
                status: "LOADING",
            }
        }
    })),
    on(GeneralPartnerValuationActions.save, (state): AssetState => ({
        ...state,
        selectedGeneralPartnerValuation: {
            ...state.selectedGeneralPartnerValuation,
            generalPartnerValuation: {
                ...state.selectedGeneralPartnerValuation.generalPartnerValuation,
                status: "LOADING"
            },
            generalPartnerValuationCalc: {
                ...state.selectedGeneralPartnerValuation.generalPartnerValuationCalc,
                status: "LOADING",
            }
        },
        assetForms: {
            ...state.assetForms,
            generalPartnerValuationIsEditable: false
        }
    })),
    on(GeneralPartnerValuationActions.loadsuccess, (state, action): AssetState => ({
        ...state,
        selectedGeneralPartnerValuation: {
            ...state.selectedGeneralPartnerValuation,
            generalPartnerValuation: {
                data: action.fundInvestment,
                status: "LOADED",
                errorMessage: ""
            },
            generalPartnerValuationCalc: {
                data: action.fundInvestment,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(GeneralPartnerValuationActions.saveloadsuccess, (state, action): AssetState => ({
        ...state,
        selectedGeneralPartnerValuation: {
            ...state.selectedGeneralPartnerValuation,
            generalPartnerValuation: {
                data: action.fundInvestment,
                status: "SAVED",
                errorMessage: ""
            },
            generalPartnerValuationCalc: {
                data: action.fundInvestment,
                status: "SAVED",
                errorMessage: ""
            }
        }
    })),
    on(GeneralPartnerValuationActions.calcsuccess, (state, action): AssetState => ({
        ...state,
        selectedGeneralPartnerValuation: {
            ...state.selectedGeneralPartnerValuation,
            generalPartnerValuationCalc: {
                data: action.fundInvestment,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(GeneralPartnerValuationActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            generalPartnerValuationIsEditable: false
        }
    })),
    on(GeneralPartnerValuationActions.loaderror, (state, action): AssetState => ({
        ...state,
        selectedGeneralPartnerValuation: {
            ...state.selectedGeneralPartnerValuation,
            generalPartnerValuation: {
                data: EMPTY_FUND_INVESTMENT,
                status: "ERROR",
                errorMessage: action.errorMsg
            },
            generalPartnerValuationCalc: {
                data: EMPTY_FUND_INVESTMENT,
                status: "ERROR",
                errorMessage: action.errorMsg
            }
        }
    })),

    // SWOT
    on(AssetSwotActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetSwotIsEditable: true
        }
    })),
    on(AssetSwotActions.save, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...state.selectedAsset,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            assetSwotIsEditable: false
        }
    })),
    on(AssetSwotActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetSwotIsEditable: false
        }
    })),
    on(AssetSwotActions.gptedit, (state): AssetState => ({
        ...state,
        generatedSwotAnalysis: {
            ...state.generatedSwotAnalysis,
            gptSwot: {
                ...state.generatedSwotAnalysis.gptSwot,
                status: "LOADING"
            },
            gptSwotIsEditable: true
        }
    })),
    on(AssetSwotActions.gptcancel, (state): AssetState => ({
        ...state,
        generatedSwotAnalysis: {
            ...state.generatedSwotAnalysis,
            gptSwotIsEditable: false
        }
    })),
    on(AssetSwotActions.generatesuccess, (state, {swot}): AssetState => ({
        ...state,
        generatedSwotAnalysis: {
            ...state.generatedSwotAnalysis,
            gptSwot: {
                data: swot,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(AssetSwotActions.generatefailure, (state, {errorMsg}): AssetState => ({
        ...state,
        generatedSwotAnalysis: {
            ...state.generatedSwotAnalysis,
            gptSwot: {
                data: EMPTY_SWOT,
                status: "ERROR",
                errorMessage: errorMsg
            }
        }
    })),

    // Asset Name
    on(AssetNameActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetNameIsEditable: true
        }
    })),
    on(AssetNameActions.save, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...state.selectedAsset,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            assetNameIsEditable: false
        }
    })),
    on(AssetNameActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetNameIsEditable: false
        }
    })),

    // Asset Description
    on(AssetDescriptionActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetDescriptionIsEditable: true
        }
    })),
    on(AssetDescriptionActions.save, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...state.selectedAsset,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            assetDescriptionIsEditable: false
        },
        generatedAssetDescription: INITIAL_STATE.generatedAssetDescription
    })),
    on(AssetDescriptionActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetDescriptionIsEditable: false
        },
        generatedAssetDescription: INITIAL_STATE.generatedAssetDescription
    })),
    on(AssetDescriptionActions.gptedit, (state): AssetState => ({
        ...state,
        generatedAssetDescription: {
            ...state.generatedAssetDescription,
            gptAssetDescriptionText: {
                ...state.generatedAssetDescription.gptAssetDescriptionText,
                status: "LOADING"
            },
            gptAssetDescriptionIsEditable: true
        }
    })),
    on(AssetDescriptionActions.gptcancel, (state): AssetState => ({
        ...state,
        generatedAssetDescription: {
            ...state.generatedAssetDescription,
            gptAssetDescriptionIsEditable: false
        }
    })),
    on(AssetDescriptionActions.generatesuccess, (state, {description}): AssetState => ({
        ...state,
        generatedAssetDescription: {
            ...state.generatedAssetDescription,
            gptAssetDescriptionText: {
                data: description,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(AssetDescriptionActions.generatefailure, (state, {errorMsg}): AssetState => ({
        ...state,
        generatedAssetDescription: {
            ...state.generatedAssetDescription,
            gptAssetDescriptionText: {
                data: EMPTY_TEXT,
                status: "ERROR",
                errorMessage: errorMsg
            }
        }
    })),

    // End Markets / Customers
    on(EndMarketsCustomersActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            endMarketsCustomersIsEditable: true
        }
    })),
    on(EndMarketsCustomersActions.save, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...state.selectedAsset,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            endMarketsCustomersIsEditable: false
        },
        generatedEndMarketsDescription: INITIAL_STATE.generatedEndMarketsDescription
    })),
    on(EndMarketsCustomersActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            endMarketsCustomersIsEditable: false
        },
        generatedEndMarketsDescription: INITIAL_STATE.generatedEndMarketsDescription
    })),
    on(EndMarketsCustomersActions.gptedit, (state): AssetState => ({
        ...state,
        generatedEndMarketsDescription: {
            ...state.generatedEndMarketsDescription,
            gptEndMarketsText: {
                ...state.generatedEndMarketsDescription.gptEndMarketsText,
                status: "LOADING"
            },
            gptEndMarketsIsEditable: true
        }
    })),
    on(EndMarketsCustomersActions.gptcancel, (state): AssetState => ({
        ...state,
        generatedEndMarketsDescription: {
            ...state.generatedEndMarketsDescription,
            gptEndMarketsIsEditable: false
        }
    })),
    on(EndMarketsCustomersActions.generatesuccess, (state, {description}): AssetState => ({
        ...state,
        generatedEndMarketsDescription: {
            ...state.generatedEndMarketsDescription,
            gptEndMarketsText: {
                data: description,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(EndMarketsCustomersActions.generatefailure, (state, {errorMsg}): AssetState => ({
        ...state,
        generatedEndMarketsDescription: {
            ...state.generatedEndMarketsDescription,
            gptEndMarketsText: {
                data: EMPTY_TEXT,
                status: "ERROR",
                errorMessage: errorMsg
            }
        }
    })),

    // Exits
    on(FundInvestmentActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            exitsIsEditable: true
        }
    })),
    // TODO: WHY??
    on(FundInvestmentActions.save, (state): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuation: {
                ...state.discountMultipleValuation.selectedAssetValuation,
                status: "LOADING"
            }
        },
        assetForms: {
            ...state.assetForms,
            exitsIsEditable: false
        }
    })),
    on(FundInvestmentActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            exitsIsEditable: false
        }
    })),

    // Discount Multiple Valuation
    on(AssetDiscountValuationActions.edit, (state): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            calculatedAssetValuation: state.discountMultipleValuation.selectedAssetValuation,
            calculatedAssetValuationScenarios: state.discountMultipleValuation.selectedAssetValuationScenarios
        },
        assetForms: {
            ...state.assetForms,
            discountMultipleValuationIsEditable: true
        }
    })),
    on(AssetDiscountValuationActions.save, (state): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuation: {
                ...state.discountMultipleValuation.selectedAssetValuation,
                status: "LOADING"
            },
            selectedAssetValuationScenarios: {
                ...state.discountMultipleValuation.selectedAssetValuationScenarios,
                status: "LOADING"
            }
        },
        assetForms: {
            ...state.assetForms,
            discountMultipleValuationIsEditable: false
        }
    })),
    on(AssetDiscountValuationActions.saveloadsuccess, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuation: {
                data: action.assetValuation,
                status: "SAVED",
                errorMessage: ""
            }
        }
    })),
    on(AssetDiscountValuationActions.loaderror, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuation: {
                data: EMPTY_ASSET_VALUATION,
                status: "ERROR",
                errorMessage: action.errorMsg
            }
        }
    })),
    on(AssetDiscountValuationActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            discountMultipleValuationIsEditable: false
        }
    })),
    on(AssetDiscountValuationActions.calcsuccess, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            calculatedAssetValuation: {
                data: action.assetValuation,
                status: "LOADED",
                errorMessage: ""
            },
            calculatedAssetValuationScenarios: {
                data: action.scenarios,
                status: "LOADED",
                errorMessage: ""
            }
        }
    })),
    on(AssetDiscountValuationActions.calcerror, (state, action): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            calculatedAssetValuation: {
                data: EMPTY_ASSET_VALUATION,
                status: "ERROR",
                errorMessage: action.errorMsg
            },
            calculatedAssetValuationScenarios: {
                data: [],
                status: "ERROR",
                errorMessage: action.errorMsg
            }
        }
    })),

    // Rationale
    on(AssetDiscountValuationActions.editrationale, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            dmvRationaleIsEditable: true
        }
    })),
    on(AssetDiscountValuationActions.saverationale, (state): AssetState => ({
        ...state,
        discountMultipleValuation: {
            ...state.discountMultipleValuation,
            selectedAssetValuation: {
                ...state.discountMultipleValuation.selectedAssetValuation,
                status: "LOADING"
            }
        },
        assetForms: {
            ...state.assetForms,
            dmvRationaleIsEditable: false
        }
    })),
    on(AssetDiscountValuationActions.cancelrationale, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            dmvRationaleIsEditable: false
        }
    })),
    on(AssetOperationalValuationActions.editrationale, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            operationalRationaleIsEditable: true
        }
    })),
    on(AssetOperationalValuationActions.saverationale, (state): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                ...state.financialOperationalValuation.selectedAssetValuation,
                status: "LOADING"
            }
        },
        assetForms: {
            ...state.assetForms,
            operationalRationaleIsEditable: false
        }
    })),
    on(AssetOperationalValuationActions.cancelrationale, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            operationalRationaleIsEditable: false
        }
    })),

    // Asset Financial Operational Valuation
    on(AssetOperationalValuationActions.create, (state): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                ...state.financialOperationalValuation.selectedAssetValuation,
                status: "LOADING"
            }
        }
    })),
    on(AssetOperationalValuationActions.save, (state): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                ...state.financialOperationalValuation.selectedAssetValuation,
                status: "LOADING"
            }
        },
        assetForms: {
            ...state.assetForms,
            financialOperationalValuationIsEditable: {
                ...state.assetForms.financialOperationalValuationIsEditable,
                valuationIsEditable: false
            }
        }
    })),
    on(AssetOperationalValuationActions.saveloadsuccess, (state, action): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                data: action.assetValuation,
                status: "SAVED",
                errorMessage: ""
            }
        }
    })),
    on(AssetOperationalValuationActions.loaderror, (state, action): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            selectedAssetValuation: {
                data: EMPTY_ASSET_VALUATION,
                status: "ERROR",
                errorMessage: action.errorMsg
            }
        }
    })),
    on(AssetOperationalValuationActions.calcsuccess, (state, action): AssetState => {
        const updatedState: AssetState = {
            ...state,
            financialOperationalValuation: {
                ...state.financialOperationalValuation,
                calculatedAssetValuation: {
                    data: action.assetValuation,
                    status: "LOADED",
                    errorMessage: ""
                },
                calculatedAssetValuationScenarios: {
                    data: action.scenarios,
                    status: "LOADED",
                    errorMessage: ""
                }
            }
        };
        switch (action.scenarioType) {
            case "LOW":
                return loadedFinancialPerformanceRecords(updatedState, action.financialPerformanceRecords, "LOW");
            case "BASE":
                return loadedFinancialPerformanceRecords(updatedState, action.financialPerformanceRecords, "BASE");
            case "HIGH":
                return loadedFinancialPerformanceRecords(updatedState, action.financialPerformanceRecords, "HIGH");
            default:
                return updatedState;
        }
    }),
    on(AssetOperationalValuationActions.calcerror, (state, action): AssetState => ({
        ...state,
        financialOperationalValuation: {
            ...state.financialOperationalValuation,
            calculatedAssetValuation: {
                data: EMPTY_ASSET_VALUATION,
                status: "ERROR",
                errorMessage: action.errorMsg
            },
            calculatedAssetValuationScenarios: {
                data: [],
                status: "ERROR",
                errorMessage: action.errorMsg
            }
        }
    })),
    on(AssetOperationalValuationActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            financialOperationalValuationIsEditable: {
                ...state.assetForms.financialOperationalValuationIsEditable,
                valuationIsEditable: true
            }
        }
    })),
    on(AssetOperationalValuationActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            financialOperationalValuationIsEditable: {
                ...state.assetForms.financialOperationalValuationIsEditable,
                valuationIsEditable: false
            }
        }
    })),

    // Financial Performance Records (Operational Valuation)
    on(FinancialPerformanceRecordsActions.load, (state, action) =>
        loadingFinancialPerformanceRecords(state, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.loadlowsuccess, (state, action) =>
        loadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.loadbasesuccess, (state, action) =>
        loadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.loadhighsuccess, (state, action) =>
        loadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.loaderror, (state, action) =>
        errorFinancialPerformanceRecords(state, action.errorMsg, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.calcsuccesslow, (state, action) =>
        loadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.calcsuccessbase, (state, action) =>
        loadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.calcsuccesshigh, (state, action) =>
        loadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.calcerror, (state, action) =>
        calcErrorFinancialPerformanceRecords(state, action.errorMsg, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.save, (state, action) => {
        const newState = saveFinancialPerformanceRecords(state, action.scenarioType);
        return setScenarioEditability(newState, action.scenarioType, false, "recordsAreEditable");
    }),
    on(FinancialPerformanceRecordsActions.saveloadlowsuccess, (state, action) =>
        savedLoadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.saveloadbasesuccess, (state, action) =>
        savedLoadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.saveloadhighsuccess, (state, action) =>
        savedLoadedFinancialPerformanceRecords(state, action.financialPerformanceRecords, action.scenarioType)
    ),
    on(FinancialPerformanceRecordsActions.edit, (state, {scenarioType}): AssetState =>
        setScenarioEditability(state, scenarioType, true, "recordsAreEditable")
    ),
    on(FinancialPerformanceRecordsActions.cancel, (state, {scenarioType}): AssetState =>
        setScenarioEditability(state, scenarioType, false, "recordsAreEditable")
    ),

    // Asset General Information
    on(AssetGeneralInformationActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            generalInformationIsEditable: true
        }
    })),
    on(AssetGeneralInformationActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            generalInformationIsEditable: false
        }
    })),
    on(AssetGeneralInformationActions.save, (state): AssetState => ({
        ...state,
        selectedAsset: {
            ...state.selectedAsset,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            generalInformationIsEditable: false
        }
    })),

    // Asset Financial History
    on(AssetFinancialHistoryActions.load, (state): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "LOADING"
        }
    })),
    on(AssetFinancialHistoryActions.loadsuccess, (state, {financialHistory}): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "LOADED",
            data: financialHistory,
            errorMessage: ""
        }
    })),
    on(AssetFinancialHistoryActions.loaderror, (state, {errorMsg}): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "ERROR",
            errorMessage: errorMsg
        }
    })),
    on(AssetFinancialHistoryActions.loadbaseline, (state): AssetState => ({
        ...state,
        baselineAssetFinancialHistory: {
            ...INITIAL_STATE.baselineAssetFinancialHistory,
            ...{
                status: "LOADING"
            }
        },
    })),
    on(AssetFinancialHistoryActions.loadbaselinesuccess, (state, action): AssetState => ({
        ...state,
        baselineAssetFinancialHistory: {
            data: action.financialHistory,
            status: "LOADED",
            errorMessage: ""
        }
    })),
    on(AssetFinancialHistoryActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetFinancialHistoryIsEditable: true
        }
    })),
    on(AssetFinancialHistoryActions.save, (state): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            assetFinancialHistoryIsEditable: false
        }
    })),
    on(AssetFinancialHistoryActions.saveloadsuccess, (state, {financialHistory}): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "SAVED",
            data: financialHistory,
            errorMessage: ""
        }
    })),
    on(AssetFinancialHistoryActions.saveerror, (state, {errorMsg}): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "ERROR",
            errorMessage: errorMsg
        }
    })),
    on(AssetFinancialHistoryActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            assetFinancialHistoryIsEditable: false
        }
    })),
    on(AssetFinancialHistoryActions.calcsuccess, (state, {financialHistory}): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "LOADED",
            data: financialHistory,
            errorMessage: ""
        }
    })),
    on(AssetFinancialHistoryActions.calcerror, (state, {errorMsg}): AssetState => ({
        ...state,
        selectedAssetFinancialHistory: {
            ...state.selectedAssetFinancialHistory,
            status: "ERROR",
            errorMessage: errorMsg
        }
    })),

    // Shareholders
    on(ShareholderActions.load, (state): AssetState => ({
        ...state,
        selectedShareholdersForDate: {
            ...state.selectedShareholdersForDate,
            status: "LOADING"
        }
    })),
    on(ShareholderActions.loadsuccess, (state, {shareholders}): AssetState => ({
        ...state,
        selectedShareholdersForDate: {
            ...state.selectedShareholdersForDate,
            status: "LOADED",
            data: shareholders,
            errorMessage: ""
        }
    })),
    on(ShareholderActions.loaderror, (state, {errorMsg}): AssetState => ({
        ...state,
        selectedShareholdersForDate: {
            ...state.selectedShareholdersForDate,
            status: "ERROR",
            errorMessage: errorMsg
        }
    })),
    on(ShareholderActions.loadall, (state): AssetState => ({
        ...state,
        selectedShareholders: {
            ...state.selectedShareholders,
            status: "LOADING"
        }
    })),
    on(ShareholderActions.loadallsuccess, (state, {shareholders}): AssetState => ({
        ...state,
        selectedShareholders: {
            ...state.selectedShareholders,
            status: "LOADED",
            data: shareholders,
            errorMessage: ""
        }
    })),
    on(ShareholderActions.save, (state): AssetState => ({
        ...state,
        selectedShareholdersForDate: {
            ...state.selectedShareholdersForDate,
            status: "LOADING"
        },
        assetForms: {
            ...state.assetForms,
            shareholderStructureIsEditable: false
        }
    })),
    on(ShareholderActions.saveloadsuccess, (state, {shareholders}): AssetState => ({
        ...state,
        selectedShareholdersForDate: {
            ...state.selectedShareholdersForDate,
            status: "SAVED",
            data: shareholders,
            errorMessage: ""
        }
    })),
    on(ShareholderActions.saveerror, (state, {errorMsg}): AssetState => ({
        ...state,
        selectedShareholdersForDate: {
            ...state.selectedShareholdersForDate,
            status: "ERROR",
            errorMessage: errorMsg
        }
    })),
    on(ShareholderActions.edit, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            shareholderStructureIsEditable: true
        }
    })),
    on(ShareholderActions.cancel, (state): AssetState => ({
        ...state,
        assetForms: {
            ...state.assetForms,
            shareholderStructureIsEditable: false
        }
    }))
);

const loadingFinancialPerformanceRecords = (state: AssetState, scenarioType: ScenarioType): AssetState => ({
    ...state,
    financialOperationalValuation: {
        ...state.financialOperationalValuation,
        selectedFinancialPerformanceRecords: {
            ...state.financialOperationalValuation.selectedFinancialPerformanceRecords,
            [scenarioType]: {
                ...state.financialOperationalValuation.selectedFinancialPerformanceRecords[scenarioType],
                status: "LOADING",
            }
        }
    }
});

const loadedFinancialPerformanceRecords = (state: AssetState, data: FinancialPerformanceRecord[], scenarioType: ScenarioType): AssetState => ({
    ...state,
    financialOperationalValuation: {
        ...state.financialOperationalValuation,
        selectedFinancialPerformanceRecords: {
            ...state.financialOperationalValuation.selectedFinancialPerformanceRecords,
            [scenarioType]: {
                data,
                status: "LOADED",
                errorMessage: ""
            }
        }
    }
});

const errorFinancialPerformanceRecords = (state: AssetState, error: string, scenarioType: ScenarioType): AssetState => ({
    ...state,
    financialOperationalValuation: {
        ...state.financialOperationalValuation,
        selectedFinancialPerformanceRecords: {
            ...state.financialOperationalValuation.selectedFinancialPerformanceRecords,
            [scenarioType]: {
                data: [],
                status: "ERROR",
                errorMessage: error
            }
        }
    }
});

const calcErrorFinancialPerformanceRecords = (state: AssetState, error: string, scenarioType: ScenarioType): AssetState => ({
    ...state,
    financialOperationalValuation: {
        ...state.financialOperationalValuation,
        selectedFinancialPerformanceRecords: {
            ...state.financialOperationalValuation.selectedFinancialPerformanceRecords,
            [scenarioType]: {
                ...state.financialOperationalValuation.selectedFinancialPerformanceRecords[scenarioType],
                status: "ERROR",
                errorMessage: error
            }
        }
    }
});

const saveFinancialPerformanceRecords = (state: AssetState, scenarioType: ScenarioType): AssetState => ({
    ...state,
    financialOperationalValuation: {
        ...state.financialOperationalValuation,
        selectedFinancialPerformanceRecords: {
            ...state.financialOperationalValuation.selectedFinancialPerformanceRecords,
            [scenarioType]: {
                ...state.financialOperationalValuation.selectedFinancialPerformanceRecords[scenarioType],
                status: "LOADING"
            }
        }
    }
});

const savedLoadedFinancialPerformanceRecords = (state: AssetState, data: FinancialPerformanceRecord[], scenarioType: ScenarioType): AssetState => ({
    ...state,
    financialOperationalValuation: {
        ...state.financialOperationalValuation,
        selectedFinancialPerformanceRecords: {
            ...state.financialOperationalValuation.selectedFinancialPerformanceRecords,
            [scenarioType]: {
                data,
                status: "SAVED",
                errorMessage: ""
            }
        }
    }
});

const setScenarioEditability = (
    state: AssetState,
    scenarioType: ScenarioType,
    isEditable: boolean,
    property: "recordsAreEditable" | "scenarioIsEditable"
) => ({
    ...state,
    assetForms: {
        ...state.assetForms,
        financialOperationalValuationIsEditable: {
            ...state.assetForms.financialOperationalValuationIsEditable,
            [scenarioType]: {
                ...state.assetForms.financialOperationalValuationIsEditable[scenarioType],
                [property]: isEditable
            }
        }
    }
});
