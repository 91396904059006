import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WINDOW} from "../../model/login/window-token";
import {ApiUtil} from "../../utils/api-util";

@Injectable({
    providedIn: "root"
})
export class AuthorizePathService {

    constructor(private readonly httpClient: HttpClient, @Inject(WINDOW) private readonly window: Window) {
    }

    signPath(path: string): Observable<any> {
        const requestUrl = ApiUtil.toApiPath(`authorizePath?path=${encodeURIComponent(this.getAbsoluteRequestUrl(path))}`);
        return this.httpClient.post(requestUrl, null);
    }

    private getAbsoluteRequestUrl(path: string): string {
        return new URL(path, this.window.document.baseURI).pathname;
    }
}
