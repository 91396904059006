<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Fund Overview</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <table mat-table [dataSource]="fundDataSource" matSort #fundSort="matSort">

            <ng-container matColumnDef="fundName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{element.name | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                <td mat-cell *matCellDef="let element">{{element.country.code | codeFormat: codeTableCountry | async}}</td>
            </ng-container>

            <ng-container matColumnDef="currencyIso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Currency</th>
                <td mat-cell *matCellDef="let element">{{element.currencyIso.code | codeFormat: codeTableCurrency | async}}</td>
            </ng-container>

            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>This Fund Size</th>
                <td mat-cell *matCellDef="let element">{{element.size | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="mainFundSize">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Main Fund Size</th>
                <td mat-cell *matCellDef="let element">{{element.mainFundSize | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="vintageYear">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vintage Year</th>
                <td mat-cell *matCellDef="let element">{{element.vintageYear | traceableFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="endYear">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fund Ends</th>
                <td mat-cell *matCellDef="let element">{{element.endYear | traceableFormat}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: columns"
                [routerLink]="row.id"></tr>
        </table>

        <mat-paginator
            #fundPaging
            [pageSizeOptions]="[10, 20, 30]"
            [pageSize]="20"
            showFirstLastButtons>
        </mat-paginator>
    </mat-card-content>
    <mat-card-actions class="overview-actions">
        <button mat-stroked-button color="primary" (click)="openNewFundDialog()" data-test="new-fund">
            <mat-icon>add</mat-icon>
            Fund
        </button>
    </mat-card-actions>
</mat-card>
