import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {Deal} from "../models/deal";
import {Seller} from "../models/seller";
import {SellerPosition} from "../models/seller-position";
import {DealFund} from "../models/deal-fund";
import {SellerPositionCalc} from "../models/seller-position-calc";
import {ScenarioType} from "../../shared/model/scenario-type";
import {AggregatedDealCashflows} from "../models/aggregated-deal-cashflows";
import {DealScenario} from "../models/deal-scenario";
import {DealReturnSummary} from "../models/deal-return-summary";
import {DealCompany} from "../models/deal-company";
import {DealWithSeller} from "../models/deal-with-seller";
import {DocumentMetadata} from "../models/document-metadata";

export const DealListActions = createActionGroup({
    source: "Deal List Actions",
    events: {
        open: emptyProps(),
        load: emptyProps(),
        loadSuccess: props<{ deals: Deal[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const DealActions = createActionGroup({
    source: "Deal Actions",
    events: {
        clearDealState: emptyProps(),
        open: props<{ dealId: number }>(),
        create: props<{ dealName: string; closingDate: string; currencyIso: string }>(),
        createSuccess: props<{ dealWithSeller: DealWithSeller }>(),
        navigateToDealSuccess: emptyProps(),
        saveSellerOnDeal: props<{ sellerId: number }>(),
        saveBidPriceAdjustment: props<{ bidPriceAdjustment: number | undefined }>(),
        load: props<{ dealId: number }>(),
        loadSuccess: props<{ dealWithSeller: DealWithSeller }>(),
        loadError: props<{ errorMsg: string }>(),
        loadAll: props<{ dealId: number }>(),
        loadDealForFund: props<{ dealId: number }>(),
        loadDealForFundSuccess: props<{ dealWithSeller: DealWithSeller }>(),
        noDealForFund: emptyProps(),
        loadDealForAsset: props<{ dealId: number }>(),
        loadDealForAssetSuccess: props<{ dealWithSeller: DealWithSeller }>(),
        noDealForAsset: emptyProps(),
    }
});

export const DealNameActions = createActionGroup({
    source: "Deal Name Actions",
    events: {
        edit: emptyProps(),
        save: props<{ dealName: string }>(),
        cancel: emptyProps()
    }
});

export const SellerListActions = createActionGroup({
    source: "Seller List Actions",
    events: {
        load: emptyProps(),
        loadSuccess: props<{ sellers: Seller[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const SellerActions = createActionGroup({
    source: "Seller Actions",
    events: {
        create: props<{ sellerName: string }>(),
        load: props<{ sellerId: number }>(),
        loadSuccess: props<{ seller: Seller }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const SellerPositionActions = createActionGroup({
    source: "Seller Position Actions",
    events: {
        save: props<{ dealId: number; fundValuationId: number; sellerPosition: SellerPosition }>(),
        saveLoadSuccess: props<{ sellerPosition: SellerPosition }>(),
        load: props<{ dealId: number; fundValuationId: number }>(),
        loadSuccess: props<{ sellerPosition: SellerPosition }>(),
        saveAll: props<{ dealId: number; sellerPositions: SellerPosition[] }>(),
        loadAll: props<{ dealId: number }>(),
        loadAllSuccess: props<{ sellerPositions: SellerPosition[] }>(),
        loadError: props<{ errorMsg: string }>(),
        calc: props<{ dealId: number; fundValuationId: number; sellerPosition: SellerPosition }>(),
        calcSuccess: props<{ sellerPositionCalc: SellerPositionCalc }>(),
        calcError: props<{ errorMsg: string }>(),
    }
});

export const DealFundActions = createActionGroup({
    source: "Deal Fund Actions",
    events: {
        load: props<{ dealId: number }>(),
        loadSuccess: props<{ dealFund: DealFund }>(),
        loadError: props<{ errorMsg: string }>(),
        save: props<{ dealId: number; sellerPositions: SellerPosition[]; bidPriceAdjustment: number | undefined }>(),
        edit: emptyProps(),
        cancel: emptyProps()
    }
});

export const DealCompanyActions = createActionGroup({
    source: "Deal Company Actions",
    events: {
        load: props<{ dealId: number }>(),
        loadSuccess: props<{ dealCompany: DealCompany }>(),
        loadError: props<{ errorMsg: string }>(),
    }
});

export const DealScenarioActions = createActionGroup({
    source: "Deal Scenario Actions",
    events: {
        loadAll: props<{ dealId: number }>(),
        loadAllSuccess: props<{ scenarios: DealScenario[] }>(),
        loadError: props<{ errorMsg: string }>()
    }
});

export const DealAggregatedCashflowAction = createActionGroup({
    source: "Deal Aggregated Cashflow Actions",
    events: {
        load: props<{ dealId: number; scenarioId: number; scenarioType: ScenarioType }>(),
        loadSuccess: props<{ aggregatedCashFlows: AggregatedDealCashflows; scenarioType: ScenarioType }>(),
        loadError: props<{ scenarioType: ScenarioType; errorMsg: string }>()
    }
});

export const DealDetailsAction = createActionGroup({
    source: "Deal Details Action",
    events: {
        edit: emptyProps(),
        save: props<{
            deal: Deal;
            secondaryCarry: number | null;
            secondaryHurdle: number | null;
            currencyIso: string;
            source: string | null;
            processType: string;
            dealCaptain: string | null;
        }>(),
        saveSuccess: props<{ deal: Deal }>(),
        cancel: emptyProps()
    }
});

export const DealReturnSummaryActions = createActionGroup({
    source: "Deal Return Summary Actions",
    events: {
        calc: props<{ dealId: number }>(),
        calcSuccess: props<{ returnSummary: DealReturnSummary }>(),
        calcError: props<{ errorMsg: string }>()
    }
});

export const ProjectReportActions = createActionGroup({
    source: "Project Report Actions",
    events: {
        loadAll: emptyProps(),
        loadAllSuccess: props<{ documentMetadata: DocumentMetadata[] }>(),
        loadAllError: props<{ errorMsg: string }>(),
        generate: props<{ projectName: string; projectClosingDate?: string; firstRoundOffersDeadline?: string; deals: Deal[] }>(),
        generateSuccess: props<{ successMsg: string }>(),
        generateError: props<{ errorMsg: string }>()
    }
});
