<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Return Summary</mat-card-title>
    </mat-card-header>
    <mat-card-content class="detail-card-content">
        <form [formGroup]="fundValuationForm">
            <div class="key-value-pair-section">
                <div class="key-value-pair-column">
                    <div class="key-value-pair">
                        <div class="key-value-item">Bid Price Adjustment</div>
                        <div class="key-value-item">
                            <ng-container *ngIf="!isEditable; else editModeTemplate">
                                {{this.partnershipInvestment.bidPriceAdjustment | traceableFormat}}
                            </ng-container>
                            <ng-template #editModeTemplate>
                                <input matInput formControlName="bidPriceAdjustment" type="number"/>
                            </ng-template>
                        </div>
                    </div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Discount to NAV</div>
                        <div class="key-value-item">
                            {{this.partnershipInvestment.totalDiscountToNAV | traceableFormat}}
                        </div>
                    </div>
                </div>
                <div class="key-value-pair-column" *ngIf="isEditable" style="margin-left: 10%">
                    <h3 style="margin-top: 0 !important; margin-bottom: 16px !important">Goal Seek Bid Price Adjustment</h3>
                    <div style="margin-bottom: 8px">
                        Enter the target Net Performance TVPI for the base case. The Bid Price Adjustment needed to achieve this TVPI will be calculated.
                    </div>

                    <div class="key-value-pair">
                        <div class="key-value-item">Target TVPI</div>
                        <div class="key-value-item">
                            <input matInput formControlName="targetTvpi" type="number" placeholder="Enter target TVPI" />
                        </div>
                    </div>
                    <button mat-raised-button color="primary" (click)="calculateBidPriceAdjustment()" [disabled]="fundValuationForm.controls.targetTvpi.invalid">
                        Calculate Bid Price Adjustment
                    </button>
                </div>
            </div>
        </form>
        <section class="table-container">
            <ng-container *ngIf="returnSummary$ | async as returnSummary">
                <table mat-table [dataSource]="returnSummary.tableDatasource">
                    <!-- first header row (column groups) -->
                    <ng-container matColumnDef="groupsCase" sticky>
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1"></th>
                    </ng-container>

                    <ng-container matColumnDef="groupsProbability">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1"></th>
                    </ng-container>

                    <ng-container matColumnDef="groupsNetPerformance">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="2">Net Performance
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="groupsGrossPerformance">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="2">Gross Performance
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="groupsNav">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1">NAV
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="groupsFinalFund">
                        <th mat-header-cell *matHeaderCellDef
                            class="mat-mdc-header-cell, additional-header"
                            [attr.colspan]="1">Final Fund
                        </th>
                    </ng-container>

                    <!-- columns and cells -->
                    <ng-container matColumnDef="case" sticky>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">{{element.scenario.code | codeFormat: codeTableScenarioType | async}}</td>
                        <td mat-footer-cell *matFooterCellDef>CF weighted case</td>
                    </ng-container>

                    <ng-container matColumnDef="probability">
                        <th mat-header-cell *matHeaderCellDef>Probability</th>
                        <td mat-cell *matCellDef="let element">{{element.probability | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.probability | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="netPerformanceTvpi">
                        <th mat-header-cell *matHeaderCellDef>TVPI</th>
                        <td mat-cell *matCellDef="let element">{{element.netPerformanceTvpi | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.netPerformanceTvpi | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="netPerformanceIrr">
                        <th mat-header-cell *matHeaderCellDef>IRR</th>
                        <td mat-cell *matCellDef="let element">{{element.netPerformanceIrr | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.netPerformanceIrr | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="grossPerformanceTvpi">
                        <th mat-header-cell *matHeaderCellDef>TVPI</th>
                        <td mat-cell *matCellDef="let element">{{element.grossPerformanceTvpi | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.grossPerformanceTvpi | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="grossPerformanceIrr">
                        <th mat-header-cell *matHeaderCellDef>IRR</th>
                        <td mat-cell *matCellDef="let element">{{element.grossPerformanceIrr | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.grossPerformanceIrr | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="navUplift">
                        <th mat-header-cell *matHeaderCellDef>Uplift</th>
                        <td mat-cell *matCellDef="let element">{{element.navUplift | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.navUplift | traceableFormat}}</td>
                    </ng-container>

                    <ng-container matColumnDef="finalFundNTvpi">
                        <th mat-header-cell *matHeaderCellDef>nTVPI</th>
                        <td mat-cell *matCellDef="let element">{{element.finalFundNTvpi | traceableFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{returnSummary.cashflowWeightedCase.finalFundNTvpi | traceableFormat}}</td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="['groupsCase', 'groupsProbability', 'groupsNetPerformance', 'groupsGrossPerformance', 'groupsNav', 'groupsFinalFund']"></tr>
                    <tr mat-header-row *matHeaderRowDef="returnSummary.tableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: returnSummary.tableColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="returnSummary.tableColumns" class="row-important row-top-border"></tr>
                </table>
            </ng-container>
        </section>
    </mat-card-content>

    <mat-card-actions class="detail-card-actions">
        <button (click)="editMode()" *ngIf="!isEditable" [disabled]="(isEditDisabled$ | async)!" color="primary" data-test="edit-button" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <button (click)="save()" *ngIf="isEditable" [disabled]="fundValuationForm.invalid" color="primary" data-test="save-button" mat-icon-button>
            <mat-icon>save</mat-icon>
        </button>
        <button (click)="cancel()" *ngIf="isEditable" color="primary" data-test="cancel-button" mat-icon-button>
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>
