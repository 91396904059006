<mat-card class="detail-card" *ngIf="fundReport$ | async as fundReportDetail">
    <ng-container *ngIf="(fundReport$ | async)?.fundReport?.data?.id as fundReportId; else selectReportFirst">
        <mat-card-header class="detail-card-header" *ngIf="reportDate$ | async as reportDate">
            <mat-card-title data-test="title">Fund Report Details as of {{reportDate | traceableFormat}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="detail-card-content">
            <form [formGroup]="fundReportDetailFormGroup">
                <div class="key-value-pair-section">
                    <div class="key-value-pair-column" style="flex: 1;">
                        <h3>Report Details</h3>
                        <valumize-mergeable-number-input
                            [label]="'Total Drawdown'"
                            [isEditable]=fundReportDetail.fundReportDetailsIsEditable
                            [isDataset]=isDataset
                            [value]=fundReportDetail.fundReport.data.totalDrawDowns
                            [baselineValue]=baselineReport.totalDrawDowns
                            [control]=fundReportDetailFormGroup.controls.totalDrawDowns
                            [type]="'currency'">
                        </valumize-mergeable-number-input>
                        <div class="spacer"></div>
                        <valumize-mergeable-number-input
                            [label]="'Reported Undrawn'"
                            [isEditable]=fundReportDetail.fundReportDetailsIsEditable
                            [isDataset]=isDataset
                            [value]=fundReportDetail.fundReport.data.reportedUndrawn
                            [baselineValue]=baselineReport.reportedUndrawn
                            [control]=fundReportDetailFormGroup.controls.reportedUndrawn
                            [type]="'currency'">
                        </valumize-mergeable-number-input>
                        <valumize-mergeable-number-input
                            [label]="'Recallable Distributions'"
                            [isEditable]=fundReportDetail.fundReportDetailsIsEditable
                            [isDataset]=isDataset
                            [value]=fundReportDetail.fundReport.data.recallableDistributions
                            [baselineValue]=baselineReport.recallableDistributions
                            [control]=fundReportDetailFormGroup.controls.recallableDistributions
                            [type]="'currency'"
                            [ngStyle]="{'border-bottom': '1px solid black'}">
                        </valumize-mergeable-number-input>
                        <div class="key-value-pair">
                            <div class="key-value-item">Total Undrawn</div>
                            <div class="key-value-item">
                                {{fundReportDetail.fundReport.data.totalUndrawn | traceableFormat}}</div>
                        </div>
                        <div class="spacer"></div>
                        <valumize-mergeable-number-input
                            [label]="'Total Distributions'"
                            [isEditable]=fundReportDetail.fundReportDetailsIsEditable
                            [isDataset]=isDataset
                            [value]=fundReportDetail.fundReport.data.totalDistributions
                            [baselineValue]=baselineReport.totalDistributions
                            [control]=fundReportDetailFormGroup.controls.totalDistributions
                            [type]="'currency'">
                        </valumize-mergeable-number-input>
                        <div class="key-value-pair">
                            <div class="key-value-item">Current TVPI</div>
                            <div class="key-value-item">{{calculateCurrentTVPI(fundReportDetail.fundReport.data) | decimalFormat: "multiple"}}</div>
                        </div>
                        <valumize-mergeable-number-input
                            [label]="'Carry Paid to Date'"
                            [isEditable]=fundReportDetail.fundReportDetailsIsEditable
                            [isDataset]=isDataset
                            [value]=fundReportDetail.fundReport.data.carryPaidToDate
                            [baselineValue]=baselineReport.carryPaidToDate
                            [control]=fundReportDetailFormGroup.controls.carryPaidToDate
                            [type]="'currency'">
                        </valumize-mergeable-number-input>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions class="detail-card-actions">
            <button (click)="edit()"
                    *ngIf="!fundReportDetail.fundReportDetailsIsEditable"
                    [disabled]="(isEditDisabled$ | async)!"
                    color="primary"
                    data-test="edit-button"
                    mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="save()" [disabled]="fundReportDetailFormGroup.invalid" *ngIf="fundReportDetail.fundReportDetailsIsEditable" color="primary" data-test="save-button"
                    mat-icon-button>
                <mat-icon>save</mat-icon>
            </button>
            <button (click)="cancel()" *ngIf="fundReportDetail.fundReportDetailsIsEditable" color="primary" data-test="cancel-button" mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-card-actions>
    </ng-container>
    <ng-template #selectReportFirst>
        <mat-card-header class="detail-card-header">
            <mat-card-title data-test="title">Fund Report Details</mat-card-title>
        </mat-card-header>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select fund report first!
        </mat-card-content>
    </ng-template>
</mat-card>
