import {Inject, LOCALE_ID, Pipe, PipeTransform} from "@angular/core";
import Big from "big.js";
import {CalculationUtil} from "../../utils/calculation-util";

@Pipe({
    name: "decimalFormat"
})
export class DecimalFormatPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    }

    transform(value: Big | number | undefined, type: "currency" | "multiple" | "percent" | "number", currency?: string, withCurrency?: boolean): string {
        if (value === undefined || value === null) {
            return "-";
        }
        const bigValue = CalculationUtil.toBig(value) as Big;
        return DecimalFormatPipe.transform(bigValue, type, this.locale, currency, withCurrency);
    }

    static transform(value: Big | undefined, type: "currency" | "multiple" | "percent" | "number", locale: string, currency?: string, withCurrency?: boolean): string {
        if (value === undefined || value === null) {
            return "-";
        }

        switch (type) {
            case "currency": {
                const valueInMio = this.displayFormat(value.div(1000000), 1, locale);
                return withCurrency === true && !!currency
                    ? currency + "m\u00A0" + valueInMio
                    : valueInMio;
            }
            case "multiple": {
                return this.displayFormat(value, 2, locale) + "x";
            }
            case "percent": {
                const percentValue = this.displayFormat(value.times(100), 1, locale);
                return percentValue + "%";
            }
            default: {
                return this.displayFormat(value, 2, locale);
            }
        }
    }

    static transformFromMillionsNum(value: number | undefined | null): number | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }else if (value === 0) {
            return 0;
        }else {
            const bigValue = CalculationUtil.toBig(value) as Big;
            return bigValue.div(1000000).toNumber();
        }
    }

    static transformToMillionsNum(value: number | undefined | null): number | undefined {
        if (value === undefined || value === null || value.toString() === "") {
            return undefined;
        }
        const bigValue = CalculationUtil.toBig(value) as Big;
        return bigValue.times(1000000).toNumber();
    }

    static transformFractionToPercent(value: number | undefined): number | undefined {
        if (value === undefined) {
            return;
        }
        const bigValue = CalculationUtil.toBig(value) as Big;
        return bigValue.times(100).toNumber();
    }

    static transformPercentToFraction(value: number | undefined | null): number | undefined {
        if (value === undefined || value === null || value.toString() === "") {
            return;
        }else if(value === 0) {
            return 0;
        }else{
            const bigValue = CalculationUtil.toBig(value) as Big;
            return bigValue.div(100).toNumber();
        }
    }

    private static displayFormat(value: Big, decimals: number, locale: string): string {
        const numberFormat = new Intl.NumberFormat(locale, {minimumFractionDigits: decimals});
        return numberFormat.format(value.round(decimals).toNumber());
    }
}
