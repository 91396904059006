import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AssetValuation} from "../../../models/asset-valuation/asset-valuation";
import {Store} from "@ngrx/store";
import {FormBuilder} from "@angular/forms";
import {EMPTY_ASSET_VALUATION} from "../../../store/asset.reducer";
import {selectIsAnyAssetFormInEditMode} from "../../../store/asset/asset.selectors";

@Component({
    selector: "valumize-asset-valuation-rationale",
    templateUrl: "./asset-valuation-rationale.component.html",
    styleUrls: ["./asset-valuation-rationale.component.scss"]
})
export class AssetValuationRationaleComponent implements OnInit {

    @Input() assetValuation: AssetValuation = EMPTY_ASSET_VALUATION;
    @Input() rationaleIsEditable = false;

    @Output() editRationale = new EventEmitter();
    @Output() cancelRationale = new EventEmitter();
    @Output() saveRationale = new EventEmitter<AssetValuation>();


    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder
    ) {
    }

    rationaleForm = this.formBuilder.group({
        rationale: this.formBuilder.control<string | undefined>("", {nonNullable: true})
    });

    ngOnInit() {
        this.rationaleForm.controls.rationale.patchValue(this.assetValuation.rationale.text ?? undefined);
    }

    edit = () => this.editRationale.emit();

    cancel = () => this.cancelRationale.emit();

    save() {
        if (this.rationaleForm.valid) {
            const assetValuationToSave: AssetValuation = {
                ...this.assetValuation,
                rationale: {
                    ...this.assetValuation.rationale,
                    text: this.rationaleForm.getRawValue().rationale
                }
            };
            this.saveRationale.emit(assetValuationToSave);
        }
    }
}
