import {Component, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectCalculatedBidPriceAdjustment, selectFundReturnSummaryTable, selectPartnershipInvestmentForReturnSummary} from "../../store/fund-valuation/fund-valuation.selectors";
import {CodeTableEnum} from "../../../shared/model/code";
import {FormBuilder, Validators} from "@angular/forms";
import {map, Subscription} from "rxjs";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {ReturnSummaryActions} from "../../store/fund.actions";
import {EMPTY_PARTNERSHIP_INVESTMENT} from "../../store/fund.reducer";
import {selectIsAnyFundFormInEditMode} from "../../store/fund/fund.selectors";

@Component({
    selector: "valumize-fund-return-summary",
    templateUrl: "./fund-return-summary.component.html"
})
export class FundReturnSummaryComponent implements OnInit, OnDestroy{

    subscriptions: Subscription[] = [];

    returnSummary$ = this.store.select(selectFundReturnSummaryTable);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);
    codeTableScenarioType = CodeTableEnum.SHARED_SCENARIOTYPE;
    isEditable = false;
    partnershipInvestment = EMPTY_PARTNERSHIP_INVESTMENT;

    fundValuationForm = this.formBuilder.group({
        bidPriceAdjustment: this.formBuilder.control<number | null>(null, Validators.required),
        targetTvpi: this.formBuilder.control<number | null>(null)
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder,) {
    }

    ngOnInit() {
        this.subscriptions.push(this.store.select(selectPartnershipInvestmentForReturnSummary).pipe(map((partnershipInvestment) => {
            this.partnershipInvestment = partnershipInvestment.partnershipInvestment.data;
            this.isEditable = partnershipInvestment.fundReturnSummaryIsEditable;
            const bidPriceAdjustmentAmount = DecimalFormatPipe.transformFromMillionsNum(partnershipInvestment.partnershipInvestment.data.bidPriceAdjustment.amount ?? undefined);
            this.fundValuationForm.patchValue({ bidPriceAdjustment: bidPriceAdjustmentAmount });
        })).subscribe());

        this.subscriptions.push(this.store.select(selectCalculatedBidPriceAdjustment).pipe(map((adjustment) => {
            if (adjustment) {
                const bidPriceAdjustmentAmount = DecimalFormatPipe.transformFromMillionsNum(adjustment.amount ?? undefined);
                this.fundValuationForm.patchValue({ bidPriceAdjustment: bidPriceAdjustmentAmount });
            }
        })).subscribe());
    }

    save() {
        this.store.dispatch(ReturnSummaryActions.save(
            {
                partnershipInvestment: {
                    ...this.partnershipInvestment,
                    ...{
                        bidPriceAdjustment: {
                            ...this.partnershipInvestment.bidPriceAdjustment,
                            amount: DecimalFormatPipe.transformToMillionsNum(this.fundValuationForm.controls.bidPriceAdjustment.value ?? undefined)
                        }
                    }
                }
            }
        ));
    }

    calculateBidPriceAdjustment() {
        const targetTvpi = this.fundValuationForm.controls.targetTvpi.value;

        if (targetTvpi != null) {
            this.store.dispatch(ReturnSummaryActions.calculategoalseek({
                targetTvpi
            }));
        }
    }

    editMode = () => this.store.dispatch(ReturnSummaryActions.edit());

    cancel = () => this.store.dispatch(ReturnSummaryActions.cancel());

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
