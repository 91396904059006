<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Undrawn Valuation</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="(selectedFundValuation$ | async) as selectedFundValuation">
        <ng-container *ngIf="!!selectedFundValuation.data?.id; else selectFundValuationFirst">
            <mat-card-content class="detail-card-content">
                <mat-card-subtitle>
                    <valumize-info-panel *ngIf="selectedFundValuation.status === 'SAVED'" [appearance]="'SAVED'"
                                         [message]="'Undrawn Valuation successfully saved'"></valumize-info-panel>
                    <mat-progress-spinner *ngIf="selectedFundValuation.status === 'LOADING'"></mat-progress-spinner>
                    <ng-container *ngIf="calculatedFundValuation$ | async as calculatedValuation">
                        <valumize-info-panel *ngIf="calculatedValuation.status === 'ERROR'" [appearance]="'ERROR'"
                                             [message]="calculatedValuation.errorMessage"></valumize-info-panel>
                    </ng-container>
                </mat-card-subtitle>
                <div class="notes-split">
                    <ng-container *ngIf="undrawnValuation$ | async as undrawnValuation">
                        <ng-container *ngIf="fundReport$ | async as fundReport">
                            <form [formGroup]="undrawnValuationForm">
                                <div class="notes-left">
                                    <div class="key-value-pair-section">
                                        <div class="key-value-pair-column">
                                            <div class="key-value-pair">
                                                <div class="key-value-item">Reported Undrawn</div>
                                                <div class="key-value-item">
                                                    {{fundReport.fundReport.data.reportedUndrawn | traceableFormat}}
                                                </div>
                                            </div>
                                            <div class="key-value-pair" style="border-bottom: 1px solid black;">
                                                <div class="key-value-item">Recallable Distribution</div>
                                                <div class="key-value-item">
                                                    {{fundReport.fundReport.data.recallableDistributions | traceableFormat}}
                                                </div>
                                            </div>

                                            <div class="key-value-pair">
                                                <div class="key-value-item">Total Undrawn</div>
                                                <div class="key-value-item">
                                                    {{fundReport.fundReport.data.totalUndrawn | traceableFormat}}
                                                </div>
                                            </div>
                                            <div class="spacer"></div>
                                            <div class="key-value-pair">
                                                <div class="key-value-item required-asterisk">% to be drawn</div>
                                                <div class="key-value-item">
                                                    <ng-container *ngIf="isEditable">
                                                        <div class="d-flex">
                                                            <div>{{undrawnValuation.persistedFundValuation.percentageToBeDrawn | traceableFormat}}</div>
                                                            <div>
                                                                <input matInput
                                                                       type="number"
                                                                       formControlName="percentageToBeDrawnCalc"
                                                                       id="percentageToBeDrawnCalc"
                                                                       (change)="calc('percentageToBeDrawnCalc')">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!isEditable">
                                                        {{undrawnValuation.persistedFundValuation.percentageToBeDrawn | traceableFormat}}
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="key-value-pair">
                                                <div class="key-value-item">Drawdown Value at Specified %</div>
                                                <div class="key-value-item">
                                                    <ng-container *ngIf="isEditable">
                                                        {{calcTotalUndrawnWithPercentage(fundReport.fundReport.data.totalUndrawn, undrawnValuation.calculatedFundValuation.percentageToBeDrawn)}}
                                                    </ng-container>
                                                    <ng-container *ngIf="!isEditable">
                                                        {{calcTotalUndrawnWithPercentage(fundReport.fundReport.data.totalUndrawn, undrawnValuation.persistedFundValuation.percentageToBeDrawn)}}
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <table mat-table [dataSource]="undrawnValuation.tableDatasource">
                                        <ng-container matColumnDef="case" sticky>
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell class="required-asterisk"
                                                *matCellDef="let element">{{element.scenario.code | codeFormat: codeTableScenarioType | async}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="probability">
                                            <th mat-header-cell *matHeaderCellDef>Probability</th>
                                            <td mat-cell *matCellDef="let element">{{element.probability | traceableFormat}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="undrawnMultiple">
                                            <th mat-header-cell *matHeaderCellDef>Undrawn Multiple</th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container *ngIf="isEditable">
                                                    <input matInput
                                                           type="number"
                                                           formControlName="undrawnMultiple{{element.scenario.code}}"
                                                           id="undrawnMultiple{{element.scenario.code}}"
                                                           (change)="calc('undrawnMultiple' + element.scenario.code)">
                                                </ng-container>
                                                <ng-container *ngIf="!isEditable">
                                                    {{element.undrawnMultiple | traceableFormat}}
                                                </ng-container>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="grossIRR">
                                            <th mat-header-cell *matHeaderCellDef>Gross IRR</th>
                                            <td mat-cell *matCellDef="let element">{{element.grossIRR | traceableFormat}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="undrawnValuation.tableColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: undrawnValuation.tableColumns"></tr>
                                    </table>
                                </div>
                            </form>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="notes$ | async as notes">
                        <valumize-notes [notes]="notes.notes" (save)="saveNote(notes.baseContext, $event)"
                                        (delete)="deleteNote($event)" data-test="notes"></valumize-notes>
                    </ng-container>
                </div>
            </mat-card-content>
            <mat-card-actions class="detail-card-actions">
                <button (click)="editMode()"
                        *ngIf="!isEditable"
                        [disabled]="(isEditDisabled$ | async)!"
                        color="primary"
                        data-test="edit-button"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="save()"
                        *ngIf="isEditable"
                        [disabled]="undrawnValuationForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <button (click)="cancel()"
                        *ngIf="isEditable"
                        color="primary"
                        data-test="cancel-button"
                        mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
        </ng-container>
    </ng-container>
    <ng-template #selectFundValuationFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select a fund valuation first!
        </mat-card-content>
    </ng-template>
</mat-card>
