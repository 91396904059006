<h2 mat-dialog-title class="clickable" (click)="openAssetDetails()">
    <a>{{(assetName$ | async)?.name | traceableFormat}}</a>
</h2>
<valumize-info-panel *ngIf="isCloseDisabled" [appearance]="'WARNING'"
                     [message]="'There are unsaved changes. Please exit the edit mode by saving or cancelling to return to the fund overview.'"></valumize-info-panel>
<valumize-info-panel *ngIf="isAssetDetailsDisabledInfo" [appearance]="'WARNING'"
                     [message]="'There are unsaved changes. Please exit the edit mode by saving or cancelling to go to the company details.'"></valumize-info-panel>
<mat-dialog-content>
    <mat-accordion multi>
        <mat-expansion-panel [expanded]="expanded === assetDialogExpandType.GENERAL">
            <mat-expansion-panel-header>
                <mat-panel-title>General Information</mat-panel-title>
            </mat-expansion-panel-header>
            <valumize-asset-general-information></valumize-asset-general-information>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="expanded === assetDialogExpandType.GPVALUATION">
            <mat-expansion-panel-header>
                <mat-panel-title>GP Valuation</mat-panel-title>
            </mat-expansion-panel-header>
            <valumize-asset-gp-valuation></valumize-asset-gp-valuation>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="expanded === assetDialogExpandType.EXITINFO">
            <mat-expansion-panel-header>
                <mat-panel-title>Investment & Exit Dates</mat-panel-title>
            </mat-expansion-panel-header>
            <valumize-asset-exit-information></valumize-asset-exit-information>
        </mat-expansion-panel>
        <ng-container *ngIf="(sourceDataset$ | async) === 'master'">
            <mat-expansion-panel [expanded]="expanded === assetDialogExpandType.VALUATION">
                <mat-expansion-panel-header>
                    <mat-panel-title>Company Valuation (Forecast)</mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngIf="preferredValuationType === valuationTypeEnum.DiscountMultipleValuation; else operationalValuation">
                    <valumize-asset-discount-multiple-valuation></valumize-asset-discount-multiple-valuation>
                </ng-container>
                <ng-template #operationalValuation>
                    <valumize-asset-financial-operational-valuation [assetId]="assetId"></valumize-asset-financial-operational-valuation>
                </ng-template>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="openAssetDetails()"
            data-test="close-dmv-dialog"
            mat-stroked-button>
        <mat-icon>factory</mat-icon>
        Company Details
    </button>
    <button (click)="closeDialog()"
            color="primary"
            data-test="close-dmv-dialog"
            mat-stroked-button>
        <mat-icon>close</mat-icon>
        Close
    </button>
</mat-dialog-actions>
