import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthenticationService} from "../../services/login/authentication.service";
import {LoginService} from "../../services/login/login.service";
import {take} from "rxjs/operators";
import {ChangePasswordComponent} from "../login/change-password/change-password.component";
import {MatDialog} from "@angular/material/dialog";
import {AssetNewDialogComponent} from "../../../asset/asset-new-dialog/asset-new-dialog.component";
import {FundNewDialogComponent} from "../../../fund/fund-new-dialog/fund-new-dialog.component";
import {DealNewDialogComponent} from "../../../deal/deal-new-dialog/deal-new-dialog.component";
import {Store} from "@ngrx/store";
import {selectDatasets, selectSelectedSourceDataset, selectSelectedTargetDataset} from "../../../import/store/import.selectors";
import {map, Subscription} from "rxjs";
import {MatOptionSelectionChange} from "@angular/material/core";
import {DatasetActions} from "../../../import/store/import.actions";
import {Router} from "@angular/router";

@Component({
    selector: "valumize-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    sourceDataset$ = this.store.select(selectSelectedSourceDataset);
    datasets$ = this.store.select(selectDatasets);

    targetDataset?: string;

    constructor(private readonly authService: AuthenticationService,
                private readonly dialog: MatDialog,
                private readonly loginService: LoginService,
                private readonly store: Store,
                private readonly router: Router) {
    }

    ngOnInit() {
        this.subscription = this.store.select(selectSelectedTargetDataset).pipe(map((dataset) => {
            this.targetDataset = dataset;
        })).subscribe();
    }

    async logout(): Promise<void> {
        await this.authService.logout();
        this.loginService.showLoginPage().pipe(take(1)).subscribe();
    }

    changePassword(): void {
        this.dialog.open(ChangePasswordComponent, {
                disableClose: true
            }
        );
    }

    get isAuthenticated(): boolean {
        return !!this.authService.currentUser;
    }

    get userName(): string | undefined {
        return this.authService.currentUser?.userName;
    }

    openNewAssetDialog(): void {
        this.dialog.open(AssetNewDialogComponent);
    }

    openNewFundDialog(): void {
        this.dialog.open(FundNewDialogComponent);
    }

    openNewDealDialog(): void {
        this.dialog.open(DealNewDialogComponent);
    }

    filterSourceDataset(datasets: string[], source: string): string[] {
        return datasets.filter(dataset => dataset !== source);
    }

    updateTargetDataset(source: string, event: MatOptionSelectionChange<string>): void {
        if (event.isUserInput) {
            this.store.dispatch(DatasetActions.setsourceandtarget({source, target: event.source.value}));
        }
    }

    merge(source: string, target?: string) {
        if (!target) {
            return;
        }
        this.store.dispatch(DatasetActions.merge({source, target}));
    }

    closeMergePage(): void {
        this.store.dispatch(DatasetActions.setsourceandtarget({source: "master", target: undefined}));
        this.router.navigate(["/funds"]).then(() => window.location.reload());
    }

    setHeaderBackgroundColor(dataset: string | null): string {
        return dataset === "master" ? "master" : "staging";
    }

    navigateToSearchResult(reference: string) {
        this.router.navigate([reference]);
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
