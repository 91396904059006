<ng-container *ngIf="scenario">
    <mat-expansion-panel class="detail-card" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>{{scenario.scenario.code | codeFormat: codeTableScenarios | async}}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="key-value-pair-section">
            <div class="key-value-pair-column">
                <div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Probability</div>
                        <div class="key-value-item">{{scenario.probability | traceableFormat}}</div>
                    </div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Exit EV/{{earningBase.code | codeFormat: codeTableEarningBase | async}} Multiple</div>
                        <div class="key-value-item">{{scenario.enterpriseValueOverEarningBaseMultiple | traceableFormat}}</div>
                    </div>
                    <div class="key-value-pair">
                        <div class="key-value-item">NAV Multiple</div>
                        <div class="key-value-item">{{scenario.navMultiple | traceableFormat}}</div>
                    </div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Primary TVPI</div>
                        <div class="key-value-item">{{scenario.primaryTVPI | traceableFormat}}</div>
                    </div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Secondary TVPI</div>
                        <div class="key-value-item">{{scenario.secondaryTVPI | traceableFormat}}</div>
                    </div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Secondary IRR</div>
                        <div class="key-value-item">{{scenario.secondaryIRR | traceableFormat}}</div>
                    </div>
                </div>
            </div>
            <div class="key-value-pair-column">
                <div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Revenues Uplift</div>
                        <div class="key-value-item">{{scenario.revenuesUplift | traceableFormat}}</div>
                    </div>
                    <ng-container *ngIf="earningBase.code">
                        <div class="key-value-pair">
                            <div class="key-value-item">{{earningBase.code | codeFormat: codeTableEarningBase | async}} Uplift</div>
                            <div class="key-value-item">{{scenario.earningBaseUplift | traceableFormat}}</div>
                        </div>
                    </ng-container>
                    <div class="key-value-pair">
                        <div class="key-value-item">Debt Repayment</div>
                        <div class="key-value-item">{{scenario.debtRepayment | traceableFormat}}</div>
                    </div>
                </div>
            </div>
            <div class="key-value-pair-column">
                <div>
                    <div class="key-value-pair">
                        <div class="key-value-item">Revenues CAGR</div>
                        <div class="key-value-item">{{scenario.revenuesCAGR | traceableFormat}}</div>
                    </div>
                    <ng-container *ngIf="earningBase.code">
                        <div class="key-value-pair">
                            <div class="key-value-item">{{earningBase.code | codeFormat: codeTableEarningBase | async}} CAGR</div>
                            <div class="key-value-item">{{scenario.earningBaseCAGR | traceableFormat}}</div>
                        </div>
                    </ng-container>
                    <div class="key-value-pair">
                        <div class="key-value-item">Debt Repayment Capacity</div>
                        <div class="key-value-item">{{scenario.debtRepaymentCapacity | traceableFormat}}</div>
                    </div>
                    <br>
                    <br>
                    <div class="key-value-pair">
                        <div class="key-value-item">Exit Cashflow in Fund Currency</div>
                        <div class="key-value-item">{{getCashflow(scenario) | traceableFormat}}</div>
                    </div>
                </div>
            </div>
        </div>
        <valumize-asset-fin-data-history-table
            [selectedAssetFinancialPerformanceData]="financialPerformanceRecords"
            [baselineAssetFinancialPerformanceData]="financialHistoryRecords"
            [isEditable]="recordsAreEditable"
            [isDataset]="false"
            [isEditDisabled]="isEditDisabled$ | async"
            [isForecast]="true"
            [exitDateAssumption]="selectSelectedExitDateAssumptionOperational$ | async"
            (recordsToSave)="onSave($event, assetId, assetValuationId, scenario.id, scenario.scenario.code)"
            (recordsForCalc)="onCalc($event, assetId, assetValuationId, scenario.id, scenario.scenario.code)"
            (triggerEdit)="onEdit($event, assetId, assetValuationId, scenario.id, scenario.scenario.code)"
            (triggerCancel)="onCancel(scenario.scenario.code)">
        </valumize-asset-fin-data-history-table>
    </mat-expansion-panel>
</ng-container>
