import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {selectFinancialOperationalValuation, selectSelectedAssetValuationOperational} from "../../../store/asset-valuation/asset-valuation.selectors";
import {Store} from "@ngrx/store";
import {AssetValuationScenario} from "../../../models/asset-valuation/asset-valuation-scenario";
import {FormBuilder} from "@angular/forms";
import {CodeTableEnum} from "../../../../shared/model/code";
import {selectCodesFromTable} from "../../../../shared/store/shared.selectors";
import {AssetOperationalValuationActions} from "../../../store/asset.actions";
import {selectIsAnyAssetFormInEditMode} from "../../../store/asset/asset.selectors";
import {map, Subscription} from "rxjs";
import {DateUtil} from "../../../../shared/utils/date-util";
import {AssetValuation} from "../../../models/asset-valuation/asset-valuation";
import {EMPTY_ASSET_VALUATION} from "../../../store/asset.reducer";
import {numberNotBiggerThan190000m} from "../../../../shared/utils/form-validators";
import {DecimalFormatPipe} from "../../../../shared/pipes/decimal-format/decimal-format.pipe";
import {selectSelectedFundInvestmentNav} from "../../../../fund/store/fund-investment/fund-investment.selectors";

@Component({
    selector: "valumize-asset-financial-operational-valuation",
    templateUrl: "./asset-financial-operational-valuation.component.html",
    styleUrls: ["./asset-financial-operational-valuation.component.scss"]
})
export class AssetFinancialOperationalValuationComponent implements OnInit, OnDestroy {

    @Input() assetId?: number;

    subscription?: Subscription;

    financialOperationalValuation$ = this.store.select(selectFinancialOperationalValuation);
    isEditDisabled$ = this.store.select(selectIsAnyAssetFormInEditMode);
    fundInvestmentNav$ = this.store.select(selectSelectedFundInvestmentNav);

    persistedAssetValuation: AssetValuation = EMPTY_ASSET_VALUATION;
    codeTableEarningBase = CodeTableEnum.ASSET_EARNING_BASE;
    selectCodesEarningBase$ = this.store.select(selectCodesFromTable(this.codeTableEarningBase));

    operationalValuationForm = this.formBuilder.group({
        earningBase: this.formBuilder.control<string>(""),
        exitDateAssumption: this.formBuilder.control<Date | null>(null),
        requiredGrossIRR: this.formBuilder.control<number | null>(null, {validators: numberNotBiggerThan190000m()}),
        fxRateFund: this.formBuilder.control<number | null>(null, {validators: numberNotBiggerThan190000m})
    });

    constructor(private readonly store: Store, private readonly formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.subscription = this.store.select(selectSelectedAssetValuationOperational).pipe(map((assetValuation) => {
            this.persistedAssetValuation = assetValuation.data;

            this.operationalValuationForm.patchValue({
                earningBase: assetValuation.data.earningBase.code,
                exitDateAssumption: DateUtil.toJsDate(assetValuation.data.exitDateAssumption.date),
                requiredGrossIRR: DecimalFormatPipe.transformFractionToPercent(assetValuation.data.requiredGrossIRR.fraction ?? undefined),
                fxRateFund: assetValuation.data.fxRateFund.fraction
            });
        })).subscribe();
    }

    save = () => {
        const exitDateAssumption = DateUtil.toIsoDate(this.operationalValuationForm.controls.exitDateAssumption.value)
            ?? this.persistedAssetValuation.exitDateAssumption.date;
        const requiredGrossIrr = DecimalFormatPipe.transformPercentToFraction(this.operationalValuationForm.controls.requiredGrossIRR.value)
            ?? this.persistedAssetValuation.requiredGrossIRR.fraction;
        const earningBase = this.operationalValuationForm.controls.earningBase.value ?? this.persistedAssetValuation.earningBase.code;
        const fxRateFund = this.operationalValuationForm.controls.fxRateFund.value ?? this.persistedAssetValuation.fxRateFund.fraction;

        const assetValuationToSave: AssetValuation = {
            ...this.persistedAssetValuation,
            exitDateAssumption: {
                ...this.persistedAssetValuation.exitDateAssumption,
                date: exitDateAssumption
            },
            earningBase: {
                ...this.persistedAssetValuation.earningBase,
                code: earningBase
            },
            requiredGrossIRR: {
                ...this.persistedAssetValuation.requiredGrossIRR,
                fraction: requiredGrossIrr
            },
            fxRateFund: {
                ...this.persistedAssetValuation.fxRateFund,
                fraction: fxRateFund
            }
        };

        this.store.dispatch(AssetOperationalValuationActions.save({assetValuation: assetValuationToSave}));
    };

    editMode = () => this.store.dispatch(AssetOperationalValuationActions.edit());

    cancel = () => this.store.dispatch(AssetOperationalValuationActions.cancel());

    findScenario(scenarios: AssetValuationScenario[], scenarioCode: string): AssetValuationScenario | undefined {
        return scenarios.find(scenario => scenario.scenario.code === scenarioCode);
    }

    validateRequiredFields(assetValuation: AssetValuation) {
        if (!assetValuation.earningBase.code || !assetValuation.exitDateAssumption.date || !assetValuation.requiredGrossIRR.fraction || !assetValuation.fxRateFund.fraction) {
            return false;
        }
        return true;
    }

    onEditRationale = () => this.store.dispatch(AssetOperationalValuationActions.editrationale());

    onCancelRationale = () => this.store.dispatch(AssetOperationalValuationActions.cancelrationale());

    onSaveRationale = (assetValuationToSave: AssetValuation) => this.store.dispatch(AssetOperationalValuationActions.saverationale({assetValuation: assetValuationToSave}));

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
