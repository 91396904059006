<mat-card-actions class="detail-card-actions">
    <div class="last-modified" data-test="modification-date">
        Last modified: {{getLastModified() | timestamp}}
    </div>
    <button (click)="edit()"
            *ngIf="!isEditable"
            [disabled]="isEditDisabled!"
            color="primary"
            data-test="edit-button"
            mat-icon-button>
        <mat-icon>edit</mat-icon>
    </button>

    <button (click)="save()"
            *ngIf="isEditable"
            color="primary"
            data-test="save-button"
            mat-icon-button>
        <mat-icon>save</mat-icon>
    </button>
    <button (click)="cancel()"
            *ngIf="isEditable"
            color="primary"
            data-test="cancel-button"
            mat-icon-button>
        <mat-icon>cancel</mat-icon>
    </button>
</mat-card-actions>

<mat-card-content class="detail-card-content">
    <section class="table-container" tabindex="0">
        <div *ngIf="selectedAssetFinancialPerformanceData.length === 0 && !isEditable" style="color: grey">
            No financial performance records available. Start editing to add a new one.
        </div>

        <div *ngIf="selectedAssetFinancialPerformanceData.length > 0">
            <mat-chip-listbox aria-label="Metrics selection" multiple>
                <mat-chip-option *ngFor="let metric of metrics" (click)="toggleMetric(metric)" [selected]="isSelected(metric)">
                    {{metric.displayName}}
                </mat-chip-option>
            </mat-chip-listbox>

            <table mat-table [dataSource]="fieldNames">
                <ng-container matColumnDef="rowName" sticky>
                    <th mat-header-cell *matHeaderCellDef>
                        FYE {{ this.fiscalYearEndMonth | codeFormat: codeTableFiscalYearEnd | async }}
                    </th>
                    <td mat-cell *matCellDef="let fieldName">{{fieldDisplayNames[fieldName] || fieldName}}</td>
                </ng-container>

                <ng-container *ngFor="let record of selectedAssetFinancialPerformanceData; let i = index" matColumnDef="{{record.period.keyDate}}{{record.period.type}}">
                    <th mat-header-cell [ngClass]="{'exitYearForecastRecord': isExitYearForecastRecord(record)}" *matHeaderCellDef>
                        <div style="display: flex; align-items: center;">
                            <div *ngIf="!isEditable">
                                {{record.period | traceableFormat}}
                            </div>
                            <div *ngIf="isEditable" (click)="openPeriodSelectorDialog(actionType.Edit, i)" style="cursor: pointer; color: #4949CB;">
                                {{getPeriodFromFormGroup(i) | traceableFormat}}
                            </div>
                            <div *ngIf="(validatePeriodDate(record.period.keyDate, record.period.type) | async) === false">&nbsp;
                                <mat-icon [matTooltip]="'The period end date ' + formatDate(record.period.keyDate) + ' is inconsistent with the current fiscal year end month.'">error_outlined</mat-icon>
                            </div>
                        </div>
                    </th>

                    <td mat-cell *matCellDef="let fieldName" [ngClass]="{'exitYearForecastRecord': isExitYearForecastRecord(record)}">
                        <ng-container *ngIf="isFieldEditable(fieldName)">
                            <ng-container *ngFor="let baselineRecord of baselineAssetFinancialPerformanceData">
                                <ng-container
                                    *ngIf="record.period.type === baselineRecord.period.type && record.period.keyDate === baselineRecord.period.keyDate && isDataset">
                                    <mat-chip class="chip"
                                              (click)="overwriteValue(
                                                    isEditable,
                                                    getFormControl(record.period.keyDate, record.period.type, fieldName),
                                                    getFieldValue(fieldName,true,baselineRecord.period.keyDate,baselineRecord.period.type))"
                                              [ngClass]="getChipColor(
                                                    getFormControl(record.period.keyDate, record.period.type, fieldName),
                                                    getFieldValue(fieldName,true,baselineRecord.period.keyDate,baselineRecord.period.type),
                                                    getFieldValue(fieldName,false,record.period.keyDate,record.period.type))">
                                        {{getFieldValue(fieldName, true, baselineRecord.period.keyDate, baselineRecord.period.type) | traceableFormat}}
                                    </mat-chip>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <span *ngIf="!isEditable">{{getFieldValue(fieldName, false, record.period.keyDate, record.period.type) | traceableFormat}}</span>
                        <ng-container *ngIf="isEditable">
                            <input *ngIf="isFieldEditable(fieldName)" class="input-field" type="text"
                                   [formControl]="getFormControl(record.period.keyDate, record.period.type, fieldName)"
                                   (change)="calc(record.period.keyDate, record.period.type, fieldName)">
                            <span *ngIf="!isFieldEditable(fieldName)">{{getFieldValue(fieldName, false, record.period.keyDate, record.period.type) | traceableFormat}}</span>
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let fieldName; columns: displayedColumns;" [class]="styleRowImportant(fieldName)"></tr>
            </table>
            <div class="info-left">(!) calculation of growth metrics from one period to another.</div>
        </div>

        <div class="add-record-button" *ngIf="isEditable">
            <button (click)="openPeriodSelectorDialog(actionType.Add)" mat-raised-button color="primary">Add New Record</button>
        </div>
    </section>
</mat-card-content>

