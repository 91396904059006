/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {catchError, exhaustMap, map, of, switchMap, withLatestFrom} from "rxjs";
import {SellerPositionService} from "../../services/seller-position.service";
import {DealActions, SellerPositionActions} from "../deal.actions";
import {SellerPosition} from "../../models/seller-position";
import {selectSelectedDealId} from "../deal/deal.selectors";

export const loadSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loadall),
            exhaustMap((action) =>
                sellerPositionService.getSellerPositionsForDealAndFundValuation(action.dealId).pipe(
                    map((sellerPositions) => SellerPositionActions.loadallsuccess({sellerPositions})),
                    catchError((error: { message: string }) =>
                        of(SellerPositionActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const saveSellerPositions = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.saveall),
            exhaustMap((action) => {
                if (action.dealId) {
                    return sellerPositionService.updateSellerPositions(
                        action.dealId,
                        action.sellerPositions
                    ).pipe(
                        map((sellerPositions) => SellerPositionActions.loadallsuccess({sellerPositions})),
                        catchError((error: { message: string }) =>
                            of(SellerPositionActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Deal could not be found"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadDealAfterSaveSellerPosition = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.loadallsuccess),
            withLatestFrom(store$.select(selectSelectedDealId)),
            map(([action, dealId]) => dealId
                ? DealActions.load({dealId})
                : DealActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);


export const loadSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.load),
            exhaustMap((action) => {
                return sellerPositionService.getSellerPositionsForDealAndFundValuation(action.dealId, action.fundValuationId).pipe(
                    switchMap((sellerPositions) => of(
                        sellerPositions.length !== 0
                            ? SellerPositionActions.loadsuccess({sellerPosition: sellerPositions.at(0) as SellerPosition})
                            : SellerPositionActions.loaderror({errorMsg: `No seller position for this deal and fund valuation available`}),
                        sellerPositions.length !== 0
                            ? SellerPositionActions.calc({dealId: action.dealId, fundValuationId: action.fundValuationId, sellerPosition: sellerPositions.at(0) as SellerPosition})
                            : SellerPositionActions.calcerror({errorMsg: `No seller position for this deal and fund valuation available`})
                    )),
                    catchError((error: { message: string }) =>
                        of(SellerPositionActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.save),
            exhaustMap((action) => {
                if (!!action.dealId && !!action.fundValuationId) {
                    return sellerPositionService.save(
                        action.dealId,
                        action.fundValuationId,
                        action.sellerPosition
                    ).pipe(
                        switchMap((sellerPosition) => of(
                            SellerPositionActions.saveloadsuccess({sellerPosition}),
                            SellerPositionActions.calc({dealId: action.dealId, fundValuationId: action.fundValuationId, sellerPosition})
                        )),
                        catchError((error: { message: string }) =>
                            of(SellerPositionActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "Error while loading deal and/or fund valuation, please try again"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcSellerPosition = createEffect(
    (actions$ = inject(Actions), sellerPositionService = inject(SellerPositionService)) => {
        return actions$.pipe(
            ofType(SellerPositionActions.calc),
            exhaustMap((action) => {
                if (!!action.dealId && !!action.fundValuationId && !!action.sellerPosition.id) {
                    return sellerPositionService.calculateSellerPosition(
                        action.dealId,
                        action.fundValuationId,
                        action.sellerPosition.id,
                        action.sellerPosition
                    ).pipe(
                        map((sellerPositionCalc) => SellerPositionActions.calcsuccess({sellerPositionCalc})),
                        catchError((error: { message: string }) =>
                            of(SellerPositionActions.calcerror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(SellerPositionActions.loaderror({errorMsg: "No seller position for this deal and fund valuation available"}));
                }
            })
        );
    },
    {functional: true}
);
