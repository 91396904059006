<mat-card class="detail-card">
    <valumize-info-panel *ngIf="(fundInvestmentNav$ | async) === 0 || (fundInvestmentNav$ | async) === null || (fundInvestmentNav$ | async) === undefined"
                         [appearance]="'WARNING'" [message]="'GP NAV is missing or zero. Please add a valid amount for NAV first.'"></valumize-info-panel>
    <ng-container *ngIf="(financialOperationalValuation$ | async) as financialOperationalValuation">
        <ng-container *ngIf="financialOperationalValuation.assetValuation.data?.id; else selectValuationFirst">
            <mat-card-subtitle>
                <valumize-info-panel *ngIf="financialOperationalValuation.assetValuation.status === 'ERROR'" [appearance]="'ERROR'"
                                     [message]="financialOperationalValuation.assetValuation.errorMessage"></valumize-info-panel>
                <valumize-info-panel *ngIf="financialOperationalValuation.assetValuation.status === 'SAVED'" [appearance]="'SAVED'"
                                     [message]="'Financial Operational Valuation successfully saved'"></valumize-info-panel>
                <mat-progress-spinner *ngIf="financialOperationalValuation.assetValuation.status === 'LOADING'"></mat-progress-spinner>
            </mat-card-subtitle>
            <mat-card-content class="detail-card-content">
                <form [formGroup]="operationalValuationForm">
                    <div class="key-value-pair-section">
                        <div class="key-value-pair-column">
                            <div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Earning Base</div>
                                    <div class="key-value-item" [ngClass]="{'bg-warning': !financialOperationalValuation.assetValuation.data.earningBase.code}">
                                        <div *ngIf="!financialOperationalValuation.isEditable.valuationIsEditable">
                                            {{financialOperationalValuation.assetValuation.data.earningBase.code | codeFormat: codeTableEarningBase | async}}
                                        </div>
                                        <div *ngIf="financialOperationalValuation.isEditable.valuationIsEditable">
                                            <select formControlName="earningBase">
                                                <option *ngFor="let earningBase of (selectCodesEarningBase$ | async)"
                                                        [value]="earningBase.code">{{ earningBase.translations.en }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Exit Date Assumption</div>
                                    <div class="key-value-item" [ngClass]="{'bg-warning': !financialOperationalValuation.assetValuation.data.exitDateAssumption.date}">
                                        <div *ngIf="!financialOperationalValuation.isEditable.valuationIsEditable">
                                            {{financialOperationalValuation.assetValuation.data.exitDateAssumption | traceableFormat}}
                                        </div>
                                        <mat-form-field *ngIf="financialOperationalValuation.isEditable.valuationIsEditable" class="date-form-field">
                                            <input matInput
                                                   formControlName="exitDateAssumption"
                                                   id="exitDateAssumption"
                                                   [matDatepicker]="exitDatePicker">
                                            <mat-hint>dd/MM/yyyy</mat-hint>
                                            <mat-datepicker-toggle matIconSuffix [for]="exitDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #exitDatePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="key-value-pair-column">
                            <div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Required gIRR</div>
                                    <div class="key-value-item" [ngClass]="{'bg-warning': !financialOperationalValuation.assetValuation.data.requiredGrossIRR.fraction}">
                                        <div *ngIf="!financialOperationalValuation.isEditable.valuationIsEditable">
                                            {{financialOperationalValuation.assetValuation.data.requiredGrossIRR | traceableFormat}}
                                        </div>
                                        <div *ngIf="financialOperationalValuation.isEditable.valuationIsEditable">
                                            <input matInput
                                                   type="number"
                                                   formControlName="requiredGrossIRR"
                                                   id="requiredGrossIRR">
                                        </div>
                                    </div>
                                </div>
                                <div class="key-value-pair">
                                    <div class="key-value-item">Exchange Rate to Fund</div>
                                    <div class="key-value-item" [ngClass]="{'bg-warning': !financialOperationalValuation.assetValuation.data.fxRateFund.fraction}">
                                        <div *ngIf="!financialOperationalValuation.isEditable.valuationIsEditable">
                                            {{financialOperationalValuation.assetValuation.data.fxRateFund.fraction | decimalFormat: "number"}}
                                        </div>
                                        <div *ngIf="financialOperationalValuation.isEditable.valuationIsEditable">
                                            <input matInput
                                                   type="number"
                                                   formControlName="fxRateFund"
                                                   id="fxRateFund">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="key-value-pair-section">
                    <div class="key-value-pair-column">
                        <div>
                            <h3 data-test="subtitle-dcf">DCF Valuation Analysis</h3>
                            <div class="key-value-pair">
                                <div class="key-value-item">Wgt Holding Period (yrs)</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.weightedHoldingPeriod}}</div>
                            </div>
                            <div class="key-value-pair">
                                <div class="key-value-item">Exit Proceeds</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.exitProceeds | traceableFormat}}</div>
                            </div>
                            <div class="key-value-pair">
                                <div class="key-value-item">Exit Proceeds - Fund Currency</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.exitProceedsFundCurrency | traceableFormat}}</div>
                            </div>
                            <div class="key-value-pair">
                                <div class="key-value-item">Bid Price</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.finalBidPrice | traceableFormat}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="key-value-pair-column">
                        <div>
                            <h3 data-test="subtitle-purchase-multiples">Purchase Multiples</h3>
                            <ng-container *ngIf="financialOperationalValuation.assetValuation.data.earningBase.code as earningBase">
                                <div class="key-value-pair">
                                    <div class="key-value-item">
                                        EV/{{earningBase | codeFormat: codeTableEarningBase | async}}</div>
                                    <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.enterpriseValueOverEarningBaseMultiple | traceableFormat}}</div>
                                </div>
                            </ng-container>
                            <div class="key-value-pair">
                                <div class="key-value-item">EV/Revenues</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.enterpriseValueOverRevenueMultiple | traceableFormat}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="key-value-pair-column">
                        <div>
                            <h3 data-test="subtitle-purchase-multiples">Performance Summary</h3>
                            <div class="key-value-pair">
                                <div class="key-value-item">NAV Multiple</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.navMultiple | traceableFormat}}</div>
                            </div>
                            <div class="key-value-pair">
                                <div class="key-value-item">Secondary TVPI</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.secondaryIRR | traceableFormat}}</div>
                            </div>
                            <div class="key-value-pair">
                                <div class="key-value-item">Secondary IRR</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.secondaryIRR | traceableFormat}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="key-value-pair-column">
                        <div>
                            <h3 data-test="subtitle-purchase-multiples">Pricing Summary</h3>
                            <div class="key-value-pair">
                                <div class="key-value-item">Discount to GP NAV</div>
                                <div class="key-value-item">{{financialOperationalValuation.assetValuation.data.discountToGpNAV | traceableFormat}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="validateRequiredFields(financialOperationalValuation.assetValuation.data)">
                    <mat-accordion multi>
                        <ng-container *ngIf="findScenario(financialOperationalValuation.scenarios, 'LOW') as scenarioLow">
                            <valumize-asset-financial-operational-valuation-scenario
                                [assetId]="assetId"
                                [assetValuationId]="financialOperationalValuation.assetValuation.data.id"
                                [scenario]="scenarioLow"
                                [recordsAreEditable]="financialOperationalValuation.isEditable.lowCaseIsEditable.recordsAreEditable"
                                [earningBase]="financialOperationalValuation.assetValuation.data.earningBase">
                            </valumize-asset-financial-operational-valuation-scenario>
                        </ng-container>
                        <ng-container *ngIf="findScenario(financialOperationalValuation.scenarios, 'BASE') as scenarioBase">
                            <valumize-asset-financial-operational-valuation-scenario
                                [assetId]="assetId"
                                [assetValuationId]="financialOperationalValuation.assetValuation.data.id"
                                [scenario]="scenarioBase"
                                [recordsAreEditable]="financialOperationalValuation.isEditable.baseCaseIsEditable.recordsAreEditable"
                                [earningBase]="financialOperationalValuation.assetValuation.data.earningBase">
                            </valumize-asset-financial-operational-valuation-scenario>
                        </ng-container>
                        <ng-container *ngIf="findScenario(financialOperationalValuation.scenarios, 'HIGH') as scenarioHigh">
                            <valumize-asset-financial-operational-valuation-scenario
                                [assetId]="assetId"
                                [assetValuationId]="financialOperationalValuation.assetValuation.data.id"
                                [scenario]="scenarioHigh"
                                [recordsAreEditable]="financialOperationalValuation.isEditable.highCaseIsEditable.recordsAreEditable"
                                [earningBase]="financialOperationalValuation.assetValuation.data.earningBase">
                            </valumize-asset-financial-operational-valuation-scenario>
                        </ng-container>
                    </mat-accordion>
                </ng-container>
            </mat-card-content>
            <mat-card-actions class="detail-card-actions">
                <button (click)="editMode()"
                        *ngIf="!financialOperationalValuation.isEditable.valuationIsEditable"
                        [disabled]="(isEditDisabled$ | async)!"
                        [hidden]="(fundInvestmentNav$ | async) === 0 || (fundInvestmentNav$ | async) === null || (fundInvestmentNav$ | async) === undefined"
                        color="primary"
                        data-test="edit-button"
                        mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="save()"
                        *ngIf="financialOperationalValuation.isEditable.valuationIsEditable"
                        [disabled]="operationalValuationForm.invalid"
                        color="primary"
                        data-test="save-button"
                        mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <button (click)="cancel()"
                        *ngIf="financialOperationalValuation.isEditable.valuationIsEditable"
                        color="primary"
                        data-test="cancel-button"
                        mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-card-actions>
            <valumize-asset-valuation-rationale
                [assetValuation]="financialOperationalValuation.assetValuation.data"
                [rationaleIsEditable]="financialOperationalValuation.isEditable.operationalRationaleIsEditable"
                (saveRationale)="onSaveRationale($event)"
                (editRationale)="onEditRationale()"
                (cancelRationale)="onCancelRationale()">
            </valumize-asset-valuation-rationale>
        </ng-container>
    </ng-container>
    <ng-template #selectValuationFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select valuation first!
        </mat-card-content>
    </ng-template>
</mat-card>
