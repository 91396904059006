/* eslint-disable arrow-body-style */
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, concatMap, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {DealService} from "../../services/deal.service";
import {
    DealActions,
    DealAggregatedCashflowAction,
    DealCompanyActions,
    DealDetailsAction,
    DealFundActions,
    DealListActions,
    DealNameActions,
    DealReturnSummaryActions,
    DealScenarioActions,
    SellerPositionActions
} from "../deal.actions";
import {Store} from "@ngrx/store";
import {selectSelectedSeller} from "../seller/seller.selectors";
import {EMPTY_DEAL} from "../deal.reducer";
import {selectDealScenarioIdFromType, selectSelectedDeal} from "./deal.selectors";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";


// #############################################################################################################
// DEAL LIST EFFECTS - START
// #############################################################################################################

export const initDealListOverview = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealListActions.open),
            map(() => DealListActions.load()));
    },
    {functional: true}
);

export const loadDealList = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealListActions.load),
            exhaustMap(() =>
                dealService.getDeals().pipe(
                    map((deals) => DealListActions.loadsuccess({deals})),
                    catchError((error: { message: string }) =>
                        of(DealListActions.loaderror({errorMsg: error.message}))
                    )
                )
            )
        );
    },
    {functional: true}
);

export const createDeal = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.create),
            exhaustMap((action) => {
                return dealService.save({
                        ...EMPTY_DEAL,
                        ...{
                            projectName: {
                                ...EMPTY_DEAL.projectName,
                                text: action.dealName
                            },
                            closingDate: {
                                ...EMPTY_DEAL.closingDate,
                                date: action.closingDate
                            },
                            currencyIso: {
                                ...EMPTY_DEAL.currencyIso,
                                code: action.currencyIso
                            }
                        }
                    }
                ).pipe(
                    map((deal) => DealActions.createsuccess({dealWithSeller: {deal}})),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const navigateToDealDetailsPageAfterCreate = createEffect(
    (actions$ = inject(Actions), router$ = inject(Router)) => {
        return actions$.pipe(
            ofType(DealActions.createsuccess),
            tap((action) => {
                router$.navigate(["/deals", action.dealWithSeller.deal.id]);
            }),
            map(() => DealActions.navigatetodealsuccess())
        );
    },
    {functional: true}
);


// #############################################################################################################
// DEAL LIST EFFECTS - END
// #############################################################################################################

export const openDealDetailsPage = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealActions.open),
            map((action) => DealActions.load({dealId: action.dealId}))
        );
    },
    {functional: true}
);

export const loadDeal = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(
                DealActions.load,
                DealActions.loaddealforfund,
                DealActions.loaddealforasset
            ),
            exhaustMap((action) => {
                const dealId = action.dealId;
                return dealService.getDeal(dealId).pipe(
                    map((dealWithSeller) =>
                        (action.type === DealActions.load.type) ?
                            DealActions.loadsuccess({dealWithSeller}) :
                            (action.type === DealActions.loaddealforfund.type) ?
                                DealActions.loaddealforfundsuccess({dealWithSeller}) :
                                DealActions.loaddealforassetsuccess({dealWithSeller})
                    ),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadFundAfterDeal = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealActions.loadsuccess),
            map((action) => action.dealWithSeller.deal.id
                ? DealFundActions.load({dealId: action.dealWithSeller.deal.id})
                : DealFundActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const loadCompaniesAfterDeal = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealActions.loadsuccess),
            map((action) => action.dealWithSeller.deal.id
                ? DealCompanyActions.load({dealId: action.dealWithSeller.deal.id})
                : DealCompanyActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const loadDealScenarioAfterDeal = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealActions.loadsuccess),
            map((action) => action.dealWithSeller.deal.id
                ? DealScenarioActions.loadall({dealId: action.dealWithSeller.deal.id})
                : DealScenarioActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const calcReturnSummaryAfterDeal = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealActions.loadsuccess),
            map((action) => action.dealWithSeller.deal.id
                ? DealReturnSummaryActions.calc({dealId: action.dealWithSeller.deal.id})
                : DealReturnSummaryActions.calcerror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const saveDealName = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealNameActions.save),
            withLatestFrom(store$.select(selectSelectedDeal), store$.select(selectSelectedSeller)),
            exhaustMap(([action, selectedDeal, selectedSeller]) => {
                return dealService.save({
                        ...selectedDeal,
                        ...{
                            projectName: {
                                ...selectedDeal.projectName,
                                text: action.dealName
                            }
                        }
                    },
                    selectedSeller.id
                ).pipe(
                    map((deal) => DealActions.loadsuccess({
                            dealWithSeller: {
                                deal,
                                seller: selectedSeller
                            }
                        })
                    ),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveSellerOnDeal = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.savesellerondeal),
            withLatestFrom(store$.select(selectSelectedDeal)),
            exhaustMap(([action, selectedDeal]) => {
                return dealService.save(
                    selectedDeal,
                    action.sellerId
                ).pipe(
                    map((deal) =>
                        deal.id
                            ? DealActions.load({dealId: deal.id})
                            : DealActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"})
                    ),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDealFunds = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealFundActions.load),
            exhaustMap((action) => {
                return dealService.getDealFunds(action.dealId).pipe(
                    map((dealFund) => DealFundActions.loadsuccess({dealFund})),
                    catchError((error: { message: string }) =>
                        of(DealFundActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDealCompanies = createEffect(
    (actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealCompanyActions.load),
            exhaustMap((action) => {
                return dealService.getDealCompanies(action.dealId).pipe(
                    map((dealCompany) => DealCompanyActions.loadsuccess({dealCompany})),
                    catchError((error: { message: string }) =>
                        of(DealCompanyActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const saveDealFunds = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealFundActions.save),
            exhaustMap((action) => of(
                DealActions.savebidpriceadjustment({bidPriceAdjustment: action.bidPriceAdjustment}),
                SellerPositionActions.saveall({dealId: action.dealId, sellerPositions: action.sellerPositions})
            ))
        );
    },
    {functional: true}
);

export const saveBidPriceAdjustment = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealActions.savebidpriceadjustment),
            withLatestFrom(store$.select(selectSelectedDeal)),
            exhaustMap(([action, selectedDeal]) => {
                const updatedDeal = {
                    ...selectedDeal,
                    bidPriceAdjustment: {
                        ...selectedDeal.bidPriceAdjustment,
                        amount: action.bidPriceAdjustment
                    }
                };
                return dealService.save(updatedDeal).pipe(
                    map((deal) => DealActions.loadsuccess({dealWithSeller: {deal}})),
                    catchError((error: { message: string }) =>
                        of(DealActions.loaderror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);

export const loadDealScenarios = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealScenarioActions.loadall),
            exhaustMap((action) => {
                if (action.dealId) {
                    return dealService.getScenariosForDeal(action.dealId).pipe(
                        map((scenarios) => DealScenarioActions.loadallsuccess({scenarios})),
                        catchError((error: { message: string }) =>
                            of(DealScenarioActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(DealScenarioActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const loadDealAggregatedCashflow = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealAggregatedCashflowAction.load),
            concatMap((action) => {
                if (action.dealId) {
                    return dealService.getAggregatedDealCashflows(action.dealId, action.scenarioId).pipe(
                        map((aggregatedCashflows) =>
                            DealAggregatedCashflowAction.loadsuccess({aggregatedCashFlows: aggregatedCashflows, scenarioType: action.scenarioType})
                        ),
                        catchError((error: any) =>
                            of(DealAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: error.error.expectedException?.message ?? error.message}))
                        )
                    );
                } else {
                    return of(DealAggregatedCashflowAction.loaderror({scenarioType: action.scenarioType, errorMsg: "Error while loading the deal, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const saveDealDetails = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealDetailsAction.save),
            withLatestFrom(store$.select(selectSelectedSeller)),
            exhaustMap(([action, selectedSeller]) => {
                if (action.deal.id) {
                    const selectedDeal = action.deal;
                    return dealService.save({
                        ...selectedDeal,
                        ...{
                            secondaryCarry: {
                                ...selectedDeal.secondaryCarry,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryCarry ?? undefined)
                            },
                            secondaryHurdle: {
                                ...selectedDeal.secondaryHurdle,
                                fraction: DecimalFormatPipe.transformPercentToFraction(action.secondaryHurdle ?? undefined)
                            },
                            currencyIso: {
                                ...selectedDeal.currencyIso,
                                code: action.currencyIso
                            },
                            source: {
                                ...selectedDeal.source,
                                text: action.source ?? undefined
                            },
                            processType: {
                                ...selectedDeal.processType,
                                code: action.processType
                            },
                            dealCaptain: {
                                ...selectedDeal.dealCaptain,
                                text: action.dealCaptain ?? undefined
                            },
                        }
                    }, selectedSeller.id).pipe(
                        map((deal) => DealDetailsAction.savesuccess({deal})),
                        catchError((error: { message: string }) =>
                            of(DealActions.loaderror({errorMsg: error.message}))
                        )
                    );
                } else {
                    return of(DealActions.loaderror({errorMsg: "Error while loading the deal, please refresh the page"}));
                }
            })
        );
    },
    {functional: true}
);

export const calcReturnSummaryAfterDealDetailSaved = createEffect(
    (actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealDetailsAction.savesuccess),
            map((action) => action.deal.id
                ? DealReturnSummaryActions.calc({dealId: action.deal.id})
                : DealReturnSummaryActions.calcerror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const loadLowAggregatedCashflowAfterDealDetailSaved = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealDetailsAction.savesuccess),
            withLatestFrom(store$.select(selectDealScenarioIdFromType("LOW"))),
            map(([action, scenarioId]) => action.deal.id && scenarioId
                ? DealAggregatedCashflowAction.load({dealId: action.deal.id, scenarioId, scenarioType: "LOW"})
                : DealReturnSummaryActions.calcerror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const loadBaseAggregatedCashflowAfterDealDetailSaved = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealDetailsAction.savesuccess),
            withLatestFrom(store$.select(selectDealScenarioIdFromType("BASE"))),
            map(([action, scenarioId]) => action.deal.id && scenarioId
                ? DealAggregatedCashflowAction.load({dealId: action.deal.id, scenarioId, scenarioType: "BASE"})
                : DealReturnSummaryActions.calcerror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const loadHighAggregatedCashflowAfterDealDetailSaved = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions)) => {
        return actions$.pipe(
            ofType(DealDetailsAction.savesuccess),
            withLatestFrom(store$.select(selectDealScenarioIdFromType("HIGH"))),
            map(([action, scenarioId]) => action.deal.id && scenarioId
                ? DealAggregatedCashflowAction.load({dealId: action.deal.id, scenarioId, scenarioType: "HIGH"})
                : DealReturnSummaryActions.calcerror({errorMsg: "Error while loading the deal, please refresh the page"}))
        );
    },
    {functional: true}
);

export const calcDealReturnSummary = createEffect(
    (store$ = inject(Store), actions$ = inject(Actions), dealService = inject(DealService)) => {
        return actions$.pipe(
            ofType(DealReturnSummaryActions.calc),
            exhaustMap((action) => {
                if (action.dealId === undefined) {
                    return of(DealReturnSummaryActions.calcerror({errorMsg: "Error while loading the deal, please refresh the page"}));
                }
                return dealService.calcDealReturnSummary(action.dealId).pipe(
                    map((returnSummary) => DealReturnSummaryActions.calcsuccess({returnSummary})),
                    catchError((error: { message: string }) =>
                        of(DealReturnSummaryActions.calcerror({errorMsg: error.message}))
                    )
                );
            })
        );
    },
    {functional: true}
);
