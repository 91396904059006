import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {FinancialPerformanceRecord} from "../../../models/asset/financial-performance-record";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ActionType, AssetHistoryPeriodDialogComponent} from "../asset-history-period-dialog/asset-history-period-dialog.component";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {DecimalFormatPipe} from "../../../../shared/pipes/decimal-format/decimal-format.pipe";
import {TraceableDate, TraceableMoney, TraceablePeriod} from "../../../../shared/model/traceable";
import {EMPTY_FINANCIAL_PERFORMANCE_RECORD} from "../../../store/asset.reducer";
import {PeriodTypeEnum} from "../../../models/asset/period-type.enum";
import {AssetFinancialHistoryActions} from "../../../store/asset.actions";
import {DateTime} from "luxon";
import {DateUtil} from "../../../../shared/utils/date-util";
import {selectGeneratedAvailablePeriodDates, selectSelectedAsset, selectValidateAvailablePeriodDates} from "../../../store/asset/asset.selectors";
import {map, Observable, of} from "rxjs";
import {AvailablePeriodDates, Period} from "../../../../shared/model/period";
import {CodeTableEnum} from "../../../../shared/model/code";

@Component({
    selector: "valumize-asset-fin-data-history-table",
    templateUrl: "./asset-fin-data-history-table.component.html",
    styleUrls: ["./asset-fin-data-history-table.component.scss"]
})
export class AssetFinDataHistoryTableComponent implements OnInit, OnChanges {

    @Input() selectedAssetFinancialPerformanceData: FinancialPerformanceRecord[] = [];
    @Input() baselineAssetFinancialPerformanceData: FinancialPerformanceRecord[] = [];
    @Input() isEditable = false;
    @Input() isDataset = false;
    @Input() isEditDisabled: boolean | null = null;
    @Input() isForecast = false;
    @Input() exitDateAssumption: TraceableDate | null = null;

    @Output() recordsToSave = new EventEmitter<FinancialPerformanceRecord[]>();
    @Output() recordsForCalc = new EventEmitter<FinancialPerformanceRecord[]>();
    @Output() triggerEdit = new EventEmitter<FinancialPerformanceRecord[]>();
    @Output() triggerCancel = new EventEmitter();

    codeTableFiscalYearEnd = CodeTableEnum.SHARED_FISCALYEAREND;

    fiscalYearEndMonth: string | undefined;

    financialDataForms?: FormGroup;
    displayedColumns: string[] = [];
    selectedMetrics: string[] = [];
    alwaysDisplayRows = [
        "revenue",
        "revenueGrowth",
        "debtRepaymentCapacity",
        "netDebt",
        "enterpriseValue",
        "commonEquity"
    ];
    rowOrder: string[] = [
        "revenue",
        "revenueGrowth",
        "ebitdar",
        "ebitdarMargin",
        "ebitdarGrowth",
        "enterpriseValueOverEbitdarMultiple",
        "ebitda",
        "ebitdaMargin",
        "ebitdaGrowth",
        "enterpriseValueOverEbitdaMultiple",
        "ebita",
        "ebitaMargin",
        "ebitaGrowth",
        "enterpriseValueOverEbitaMultiple",
        "ebit",
        "ebitMargin",
        "ebitGrowth",
        "enterpriseValueOverEbitMultiple",
        "ebt",
        "ebtMargin",
        "ebtGrowth",
        "priceOverEarningsMultiple",
        "debtRepaymentCapacity",
        "netDebt",
        "enterpriseValue",
        "commonEquity"
    ];
    fieldDisplayNames: { [key: string]: string } = {
        revenue: "Revenue",
        revenueGrowth: "Revenue Growth",
        debtRepaymentCapacity: "Debt Repayment Capacity",
        netDebt: "Net Debt",
        enterpriseValue: "Enterprise Value",
        commonEquity: "Common Equity",
        ebt: "EBT",
        ebtMargin: "EBT Margin",
        ebtGrowth: "EBT Growth",
        ebit: "EBIT",
        ebitMargin: "EBIT Margin",
        ebitGrowth: "EBIT Growth",
        ebita: "EBITA",
        ebitaMargin: "EBITA Margin",
        ebitaGrowth: "EBITA Growth",
        ebitda: "EBITDA",
        ebitdaMargin: "EBITDA Margin",
        ebitdaGrowth: "EBITDA Growth",
        ebitdar: "EBITDAR",
        ebitdarMargin: "EBITDAR Margin",
        ebitdarGrowth: "EBITDAR Growth",
        priceOverEarningsMultiple: "P/E",
        enterpriseValueOverEbitMultiple: "EV/EBIT",
        enterpriseValueOverEbitaMultiple: "EV/EBITA",
        enterpriseValueOverEbitdaMultiple: "EV/EBITDA",
        enterpriseValueOverEbitdarMultiple: "EV/EBITDAR",
        enterpriseValueOverRevenueMultiple: "EV/Revenue"
    };
    fieldNames = Object.keys(this.fieldDisplayNames);

    // Metrics definitions for dynamic display in the table
    metrics = [
        {
            key: "ebt",
            displayName: "EBT",
            associatedRows: ["ebt", "ebtMargin", "ebtGrowth", "priceOverEarningsMultiple"]
        },
        {
            key: "ebit",
            displayName: "EBIT",
            associatedRows: ["ebit", "ebitMargin", "ebitGrowth", "enterpriseValueOverEbitMultiple"]
        },
        {
            key: "ebita",
            displayName: "EBITA",
            associatedRows: ["ebita", "ebitaMargin", "ebitaGrowth", "enterpriseValueOverEbitaMultiple"]
        },
        {
            key: "ebitda",
            displayName: "EBITDA",
            associatedRows: ["ebitda", "ebitdaMargin", "ebitdaGrowth", "enterpriseValueOverEbitdaMultiple"]
        },
        {
            key: "ebitdar",
            displayName: "EBITDAR",
            associatedRows: ["ebitdar", "ebitdarMargin", "ebitdarGrowth", "enterpriseValueOverEbitdarMultiple"]
        }
    ];

    protected readonly actionType = ActionType;

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        public dialog: MatDialog
    ) {
    }

    availablePeriodDates: AvailablePeriodDates = {
        QUARTER: [],
        SEMESTER: [],
        FULL_FISCAL_YEAR: []
    };

    ngOnInit() {
        const formGroups = this.selectedAssetFinancialPerformanceData.length !== 0
            ? this.selectedAssetFinancialPerformanceData.map(record =>
                !this.isForecast
                    ? this.formBuilder.group({
                        periodType: [record.period.type ?? ""],
                        periodKeyDate: [record.period.keyDate ?? ""],
                        revenue: [DecimalFormatPipe.transformFromMillionsNum(record.revenue.amount ?? undefined)],
                        netDebt: [DecimalFormatPipe.transformFromMillionsNum(record.netDebt.amount ?? undefined)],
                        ebt: [DecimalFormatPipe.transformFromMillionsNum(record.ebt.amount ?? undefined)],
                        ebit: [DecimalFormatPipe.transformFromMillionsNum(record.ebit.amount ?? undefined)],
                        ebita: [DecimalFormatPipe.transformFromMillionsNum(record.ebita.amount ?? undefined)],
                        ebitda: [DecimalFormatPipe.transformFromMillionsNum(record.ebitda.amount ?? undefined)],
                        ebitdar: [DecimalFormatPipe.transformFromMillionsNum(record.ebitdar.amount ?? undefined)],
                        enterpriseValue: [DecimalFormatPipe.transformFromMillionsNum(record.enterpriseValue.amount ?? undefined)],
                    })
                    : this.formBuilder.group({
                        periodType: [record.period.type ?? ""],
                        periodKeyDate: [record.period.keyDate ?? ""],
                        revenueGrowth: [DecimalFormatPipe.transformFractionToPercent(record.revenueGrowth.fraction ?? undefined)],
                        debtRepaymentCapacity: [DecimalFormatPipe.transformFractionToPercent(record.debtRepaymentCapacity.fraction ?? undefined)],
                        ebtMargin: [DecimalFormatPipe.transformFractionToPercent(record.ebtMargin.fraction ?? undefined)],
                        priceOverEarningsMultiple: [record.priceOverEarningsMultiple.factor],
                        ebitMargin: [DecimalFormatPipe.transformFractionToPercent(record.ebitMargin.fraction ?? undefined)],
                        enterpriseValueOverEbitMultiple: [record.enterpriseValueOverEbitMultiple.factor],
                        ebitaMargin: [DecimalFormatPipe.transformFractionToPercent(record.ebitaMargin.fraction ?? undefined)],
                        enterpriseValueOverEbitaMultiple: [record.enterpriseValueOverEbitaMultiple.factor],
                        ebitdaMargin: [DecimalFormatPipe.transformFractionToPercent(record.ebitdaMargin.fraction ?? undefined)],
                        enterpriseValueOverEbitdaMultiple: [record.enterpriseValueOverEbitdaMultiple.factor],
                        ebitdarMargin: [DecimalFormatPipe.transformFractionToPercent(record.ebitdarMargin.fraction ?? undefined)],
                        enterpriseValueOverEbitdarMultiple: [record.enterpriseValueOverEbitdarMultiple.factor],
                    }))
            : [];

        // Initialize the form group for financial data forms with an array of the created form groups
        this.financialDataForms = this.formBuilder.group({records: this.formBuilder.array(formGroups)});

        if (this.isEditable) {
            this.financialDataForms?.enable();
        } else {
            this.financialDataForms?.disable();
        }

        // Determine the columns to be displayed based on the key dates available in the data
        this.displayedColumns = ["rowName"].concat(this.selectedAssetFinancialPerformanceData
            .filter(record => record.period.keyDate !== undefined && record.period.type !== undefined)
            .map(record => `${record.period.keyDate}${record.period.type}`));

        // Update the selected metrics based on the content of the metrics
        this.selectedMetrics = this.metrics
            .filter(metric => this.metricHasContent(metric.key))
            .map(metric => metric.key);

        // Call the method to update the rows that will be displayed
        this.updateDisplayedRows();

        this.store.select(selectSelectedAsset).pipe(map( asset =>
            this.fiscalYearEndMonth = asset.data.fiscalYearEnd.code
        )).subscribe();

        this.store.select(selectGeneratedAvailablePeriodDates).pipe(map(availablePeriodDates =>
            this.availablePeriodDates = availablePeriodDates
        )).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.selectedAssetFinancialPerformanceData = changes["selectedAssetFinancialPerformanceData"]?.currentValue ?? this.selectedAssetFinancialPerformanceData;
        this.baselineAssetFinancialPerformanceData = changes["baselineAssetFinancialPerformanceData"]?.currentValue ?? this.baselineAssetFinancialPerformanceData;
        this.isEditable = changes["isEditable"]?.currentValue ?? this.isEditable;
        this.isDataset = changes["isDataset"]?.currentValue ?? this.isDataset;
        this.isEditDisabled = changes["isEditDisabled"]?.currentValue ?? this.isEditDisabled;

        this.ngOnInit();
    }

    save() {
        if (this.financialDataForms?.valid) {
            const formRecords = (this.financialDataForms?.get("records") as FormArray).controls;

            // Ensure there are no duplicate key dates
            const keyDates = formRecords.map(group => group.get("periodKeyDate")?.value + group.get("periodType")?.value);
            const uniqueKeyDates = new Set(keyDates);
            if (uniqueKeyDates.size !== keyDates.length) {
                this.store.dispatch(AssetFinancialHistoryActions.saveerror({errorMsg: "Duplicate key dates are not allowed."}));
                return;
            }

            const transformAmount = (field: string, group: AbstractControl) => DecimalFormatPipe.transformToMillionsNum(group.get(field)?.value);
            const transformFraction = (field: string, group: AbstractControl) => DecimalFormatPipe.transformPercentToFraction(group.get(field)?.value);
            const transformFactor = (field: string, group: AbstractControl) => group.get(field)?.value;

            const mapPerformanceData = (record: FinancialPerformanceRecord, group: AbstractControl, isForecast: boolean) => {
                if (!isForecast) {
                    return {
                        revenue: {...record.revenue, amount: transformAmount("revenue", group)},
                        netDebt: {...record.netDebt, amount: transformAmount("netDebt", group)},
                        ebt: {...record.ebt, amount: transformAmount("ebt", group)},
                        ebit: {...record.ebit, amount: transformAmount("ebit", group)},
                        ebita: {...record.ebita, amount: transformAmount("ebita", group)},
                        ebitda: {...record.ebitda, amount: transformAmount("ebitda", group)},
                        ebitdar: {...record.ebitdar, amount: transformAmount("ebitdar", group)},
                        enterpriseValue: {...record.enterpriseValue, amount: transformAmount("enterpriseValue", group)},
                    };
                } else {
                    return {
                        revenueGrowth: {...record.revenueGrowth, fraction: transformFraction("revenueGrowth", group)},
                        debtRepaymentCapacity: {...record.debtRepaymentCapacity, fraction: transformFraction("debtRepaymentCapacity", group)},
                        ebtMargin: {...record.ebtMargin, fraction: transformFraction("ebtMargin", group)},
                        priceOverEarningsMultiple: {...record.priceOverEarningsMultiple, factor: transformFactor("priceOverEarningsMultiple", group)},
                        ebitMargin: {...record.ebitMargin, fraction: transformFraction("ebitMargin", group)},
                        enterpriseValueOverEbitMultiple: {...record.enterpriseValueOverEbitMultiple, factor: transformFactor("enterpriseValueOverEbitMultiple", group)},
                        ebitaMargin: {...record.ebitaMargin, fraction: transformFraction("ebitaMargin", group)},
                        enterpriseValueOverEbitaMultiple: {...record.enterpriseValueOverEbitaMultiple, factor: transformFactor("enterpriseValueOverEbitaMultiple", group)},
                        ebitdaMargin: {...record.ebitdaMargin, fraction: transformFraction("ebitdaMargin", group)},
                        enterpriseValueOverEbitdaMultiple: {...record.enterpriseValueOverEbitdaMultiple, factor: transformFactor("enterpriseValueOverEbitdaMultiple", group)},
                        ebitdarMargin: {...record.ebitdarMargin, fraction: transformFraction("ebitdarMargin", group)},
                        enterpriseValueOverEbitdarMultiple: {...record.enterpriseValueOverEbitdarMultiple, factor: transformFactor("enterpriseValueOverEbitdarMultiple", group)}
                    };
                }
            };

            const recordsToSave: FinancialPerformanceRecord[] = formRecords.map((group, index) => {
                const record = this.selectedAssetFinancialPerformanceData[index];
                return {
                    ...record,
                    period: {
                        ...record.period,
                        type: group.get("periodType")?.value,
                        keyDate: group.get("periodKeyDate")?.value
                    },
                    ...mapPerformanceData(record, group, this.isForecast)
                };
            });
            this.recordsToSave.emit(recordsToSave);
        }
    }

    edit = () => this.triggerEdit.emit(this.selectedAssetFinancialPerformanceData);

    cancel = () => this.triggerCancel.emit();

    // retrieves the value of a field for a given key date
    getFieldValue(fieldName: string, isBaseline: boolean, keyDate?: string, type?: string): any {
        const financialPerformanceRecords = isBaseline ? this.baselineAssetFinancialPerformanceData : this.selectedAssetFinancialPerformanceData;
        const record = financialPerformanceRecords.find(r => r.period.keyDate === keyDate && r.period.type === type);
        return record ? (record as any)[fieldName] : null;
    }

    // calculates and updates a record based on a given key date and field name
    calc(keyDate?: string, type?: string, fieldName?: string) {
        if (fieldName && keyDate && type) {
            // Find the financial record matching the given key date
            const recordToUpdate = this.selectedAssetFinancialPerformanceData.find(r => r.period.keyDate === keyDate && r.period.type === type);
            if (!recordToUpdate) {
                console.error(`No record found for keyDate: ${keyDate}`);
                return;
            }

            // Update the financial record with the new value for the specified field
            const updatedRecord = this.updateFinancialHistoryRecord(recordToUpdate, fieldName, this.getFormControl(keyDate, type, fieldName).value);
            // Map over the financial history data and replace the record with the updated one
            const updatedRecords = this.selectedAssetFinancialPerformanceData.map(r =>
                r.period.keyDate === keyDate && r.period.type === type ? updatedRecord : r
            );

            this.recordsForCalc.emit(updatedRecords);
        } else {
            this.recordsForCalc.emit(this.selectedAssetFinancialPerformanceData);
        }
    }

    updateFinancialHistoryRecord(record: FinancialPerformanceRecord, fieldName: string, newValue: any): FinancialPerformanceRecord {
        if (["revenue", "ebitdar", "ebitda", "ebita", "ebit", "ebt", "netDebt", "enterpriseValue"].includes(fieldName)) {
            return {
                ...(record),
                [fieldName as keyof FinancialPerformanceRecord]: {
                    ...(record as any)[fieldName as keyof FinancialPerformanceRecord],
                    amount: DecimalFormatPipe.transformToMillionsNum(newValue)
                }
            };
        } else if (["revenueGrowth", "ebitdarMargin", "ebitdaMargin", "ebitaMargin", "ebitMargin", "ebtMargin", "debtRepaymentCapacity"].includes(fieldName)) {
            return {
                ...(record),
                [fieldName as keyof FinancialPerformanceRecord]: {
                    ...(record as any)[fieldName as keyof FinancialPerformanceRecord],
                    fraction: DecimalFormatPipe.transformPercentToFraction(newValue)
                }
            };
        } else if ([
            "enterpriseValueOverEbitdarMultiple", "enterpriseValueOverEbitdaMultiple", "enterpriseValueOverEbitaMultiple",
            "enterpriseValueOverEbitMultiple", "priceOverEarningsMultiple"
        ].includes(fieldName)) {
            return {
                ...(record),
                [fieldName as keyof FinancialPerformanceRecord]: {
                    ...(record as any)[fieldName as keyof FinancialPerformanceRecord],
                    factor: newValue
                }
            };
        } else {
            return record;
        }
    }

    getFormControl(keyDate: string | undefined, type: string | undefined, fieldName: string): FormControl {
        const index = this.selectedAssetFinancialPerformanceData.findIndex(r => r.period.keyDate === keyDate && r.period.type === type);
        const formArray = this.financialDataForms?.get("records") as FormArray;
        const control = (formArray?.at(index) as FormGroup)?.get(fieldName);

        if (!control) {
            throw new Error(`FormControl not found for keyDate: ${keyDate} and fieldName: ${fieldName}`);
        }
        return control as FormControl;
    }

    validatePeriodDate(date?: string, type?: Period["type"]): Observable<boolean> {
        if(!!date && !!type){
            return this.store.select(selectValidateAvailablePeriodDates(date, type));
        }
        return of(false);
    }

    openPeriodSelectorDialog(action: ActionType, index?: number): void {
        let periodKeyDate: string | undefined;
        let periodType: PeriodTypeEnum | undefined;
        let existingPeriods: { periodType: string | undefined; periodKeyDate: string | undefined }[] = this.getExistingPeriodsFromHistoryData();

        if (action === ActionType.Edit && index != null && index >= 0) {
            const recordFormGroup = (this.financialDataForms?.get("records") as FormArray)?.at(index) as FormGroup;
            periodType = recordFormGroup.get("periodType")?.getRawValue();
            periodKeyDate = recordFormGroup.get("periodKeyDate")?.getRawValue();
            existingPeriods = existingPeriods.filter(p => p.periodKeyDate !== periodKeyDate);
        }

        const filteredAvailablePeriodDates: AvailablePeriodDates = {
            QUARTER: this.availablePeriodDates.QUARTER.filter(date =>
                !existingPeriods.some(period => period.periodKeyDate === date && period.periodType === "QUARTER")),
            SEMESTER: this.availablePeriodDates.SEMESTER.filter(date =>
                !existingPeriods.some(period => period.periodKeyDate === date && period.periodType === "SEMESTER")),
            FULL_FISCAL_YEAR: this.availablePeriodDates.FULL_FISCAL_YEAR.filter(date =>
                !existingPeriods.some(period => period.periodKeyDate === date && period.periodType === "FULL_FISCAL_YEAR"))
        };

        const finalAvailablePeriodDates: AvailablePeriodDates = {
            QUARTER: this.isForecast
                ? filteredAvailablePeriodDates.QUARTER
                : filteredAvailablePeriodDates.QUARTER.filter(date => new Date(date) <= new Date()),
            SEMESTER: this.isForecast
                ? filteredAvailablePeriodDates.SEMESTER
                : filteredAvailablePeriodDates.SEMESTER.filter(date => new Date(date) <= new Date()),
            FULL_FISCAL_YEAR: this.isForecast
                ? filteredAvailablePeriodDates.FULL_FISCAL_YEAR
                : filteredAvailablePeriodDates.FULL_FISCAL_YEAR.filter(date => new Date(date) <= new Date())
        };

        const dialogRef = this.dialog.open(AssetHistoryPeriodDialogComponent, {
            data: {
                action,
                periodType,
                periodKeyDate,
                availablePeriodDates: finalAvailablePeriodDates,
                isFullFiscalYearOnly: this.isForecast
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.action === ActionType.Edit) {
                    this.selectedAssetFinancialPerformanceData = this.selectedAssetFinancialPerformanceData.map(r =>
                        r.period.keyDate === result.originPeriodKeyDate && r.period.type === result.originPeriodType
                            ? {
                                ...r,
                                period: {
                                    ...r.period,
                                    keyDate: result.periodKeyDate,
                                    type: result.periodType
                                }
                            }
                            : r
                    );
                    this.calc();

                } else if (result.action === ActionType.Add) {
                    const newRecord: FinancialPerformanceRecord = {
                        ...EMPTY_FINANCIAL_PERFORMANCE_RECORD,
                        period: {
                            ...EMPTY_FINANCIAL_PERFORMANCE_RECORD.period,
                            type: result.periodType,
                            keyDate: result.periodKeyDate
                        }
                    };
                    this.selectedAssetFinancialPerformanceData.push(newRecord);
                    this.calc();

                } else if (result.action === ActionType.Delete) {
                    const recIndex = this.selectedAssetFinancialPerformanceData.findIndex(r => r.period.keyDate === result.originPeriodKeyDate);
                    if (recIndex !== -1) {
                        this.selectedAssetFinancialPerformanceData.splice(recIndex, 1);
                    }
                    this.calc();
                }
            }
        });
    }

    getExistingPeriodsFromHistoryData(): { periodType: string | undefined; periodKeyDate: string | undefined }[] {
        return this.selectedAssetFinancialPerformanceData.map(record => ({periodType: record.period.type, periodKeyDate: record.period.keyDate}));
    }

    getPeriodFromFormGroup(index: number): TraceablePeriod {
        const recordsArray = this.financialDataForms?.get("records") as FormArray;
        const recordFormGroup = recordsArray?.at(index) as FormGroup;
        return {type: recordFormGroup.get("periodType")?.value, keyDate: recordFormGroup.get("periodKeyDate")?.value};
    }

    isFieldEditable(fieldName: string): boolean {
        const editableFields = this.isForecast
            ? ["revenueGrowth", "debtRepaymentCapacity", "ebtMargin", "ebitMargin", "ebitaMargin", "ebitdaMargin", "ebitdarMargin", "enterpriseValueOverRevenueMultiple",
                "enterpriseValueOverEbitMultiple", "enterpriseValueOverEbitaMultiple", "enterpriseValueOverEbitdaMultiple", "enterpriseValueOverEbitdarMultiple"]
            : ["revenue", "netDebt", "ebt", "ebit", "ebita", "ebitda", "ebitdar", "enterpriseValue"];
        return editableFields.includes(fieldName);
    }

    styleRowImportant(fieldName: string): string {
        const importantRowTypes = ["revenue", "netDebt", "ebt", "ebit", "ebita", "ebitda", "ebitdar"];
        return importantRowTypes.includes(fieldName) ? "row-important" : "";
    }

    isExitYearForecastRecord(financialPerformanceRecord: FinancialPerformanceRecord) {
        return this.isForecast && DateUtil.fromIsoDate(financialPerformanceRecord.period.keyDate)?.year === DateUtil.fromIsoDate(this.exitDateAssumption?.date)?.year;
    }

    toggleMetric(metric: { key: string }) {
        const index = this.selectedMetrics.indexOf(metric.key);
        if (index > -1) {
            this.selectedMetrics.splice(index, 1);
        } else {
            this.selectedMetrics.push(metric.key);
        }
        this.updateDisplayedRows();
    }

    isSelected(metric: { key: string }): boolean {
        return this.selectedMetrics.includes(metric.key);
    }

    updateDisplayedRows() {
        let rowsToShow = [...this.alwaysDisplayRows];

        for (const metricKey of this.selectedMetrics) {
            const metric = this.metrics.find(m => m.key === metricKey);
            if (metric) {
                rowsToShow = rowsToShow.concat(metric.associatedRows);
            }
        }
        rowsToShow = this.sortBy(rowsToShow);
        this.fieldNames = rowsToShow;
    }

    sortBy(data: string[]): any {
        const order = this.rowOrder;
        const ordering: any = {};
        for (let i = 0; i < order.length; i++) {
            ordering[order[i]] = i;
        }
        return data.sort((a: any, b: any) =>
            (ordering[a] - ordering[b]) || a.localeCompare(b)
        );
    }

    metricHasContent(metricKey: string): boolean {
        return this.selectedAssetFinancialPerformanceData.some(record => {
            const value = (record as any)[metricKey]?.amount;
            return value !== null && value !== undefined;
        });
    }

    overwriteValue(isEditable: boolean, control: FormControl, baselineValue: TraceableMoney) {
        if (isEditable) {
            control.setValue(DecimalFormatPipe.transformFromMillionsNum(baselineValue.amount ?? undefined));
        }
    }

    getChipColor(control: FormControl, baselineValue: TraceableMoney, value: TraceableMoney) {
        const baselineAmount = DecimalFormatPipe.transformFromMillionsNum(baselineValue.amount ?? undefined);
        const amount = DecimalFormatPipe.transformFromMillionsNum(value.amount ?? undefined);

        if (control.value === baselineAmount && baselineAmount !== amount) {
            return "chip-overwritten-value";
        } else if (baselineAmount === amount) {
            return "chip-same-value";
        } else {
            return "chip-diff-value";
        }
    }

    getLastModified(): string | null {
        let mostRecentDate: DateTime | null = null;

        for (const record of this.selectedAssetFinancialPerformanceData) {
            for (const field of this.fieldNames) {
                const currentField = (record as any)[field];

                if (currentField?.modDate) {
                    const currentModDate = DateTime.fromISO(currentField.modDate);

                    if (!mostRecentDate || currentModDate > mostRecentDate) {
                        mostRecentDate = currentModDate;
                    }
                }
            }
        }
        return mostRecentDate ? mostRecentDate.toISO() : null;
    }

    formatDate(keyDate: string | undefined): string {
        if (!keyDate) {
            return "";
        }
        return DateTime.fromISO(keyDate).toFormat("dd/MM/yyyy");
    }
}
