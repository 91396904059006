import {Component, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {Store} from "@ngrx/store";
import {Note} from "../../../shared/model/note";
import {FundNoteActions, UndrawnValuationActions} from "../../store/fund.actions";
import {selectIsAnyFundFormInEditMode, selectUndrawnValuationNotes} from "../../store/fund/fund.selectors";
import {selectCalculatedFundValuation, selectSelectedFundValuation, selectUndrawnValuation} from "../../store/fund-valuation/fund-valuation.selectors";
import {FundValuationScenario} from "../../models/fund-valuation-scenario";
import {FundValuation} from "../../models/fund-valuation";
import {EMPTY_FUND_VALUATION} from "../../store/fund.reducer";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {filter, map, Subscription} from "rxjs";
import {CodeTableEnum} from "../../../shared/model/code";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {take} from "rxjs/operators";
import {selectSelectedFundReport} from "../../store/fund-report/fund-report.selectors";
import {TraceableMoney, TraceablePercent} from "../../../shared/model/traceable";

@Component({
    selector: "valumize-fund-undrawn-valuation",
    templateUrl: "./fund-undrawn-valuation.component.html",
    styleUrls: ["./fund-undrawn-valuation.component.scss"]
})
export class FundUndrawnValuationComponent implements OnInit, OnDestroy {

    subscription?: Subscription;

    undrawnValuation$ = this.store.select(selectUndrawnValuation);
    selectedFundValuation$ = this.store.select(selectSelectedFundValuation);
    calculatedFundValuation$ = this.store.select(selectCalculatedFundValuation);
    notes$ = this.store.select(selectUndrawnValuationNotes);
    isEditDisabled$ = this.store.select(selectIsAnyFundFormInEditMode);
    fundReport$ = this.store.select(selectSelectedFundReport);

    persistedScenarios: FundValuationScenario[] = [];
    persistedFundValuation: FundValuation = EMPTY_FUND_VALUATION;
    calculatedScenarios: FundValuationScenario[] = [];
    calculatedFundValuation?: FundValuation;

    isEditable = false;
    codeTableScenarioType = CodeTableEnum.SHARED_SCENARIOTYPE;

    constructor(
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        private readonly renderer: Renderer2
    ) {
    }

    undrawnValuationForm = this.formBuilder.group({
        percentageToBeDrawnCalc: this.formBuilder.control<number | null>(null, {nonNullable: true, validators: [Validators.required, numberNotBiggerThan190000m()]}),
        undrawnMultipleLOW: this.formBuilder.control<number | null>(null, {nonNullable: true, validators: [Validators.required, numberNotBiggerThan190000m()]}),
        undrawnMultipleBASE: this.formBuilder.control<number | null>(null, {nonNullable: true, validators: [Validators.required, numberNotBiggerThan190000m()]}),
        undrawnMultipleHIGH: this.formBuilder.control<number | null>(null, {nonNullable: true, validators: [Validators.required, numberNotBiggerThan190000m()]}),
    });

    nextFocus?: string;
    controlNamesForFocus = ["percentageToBeDrawnCalc", "undrawnMultipleLOW", "undrawnMultipleBASE", "undrawnMultipleHIGH"];

    ngOnInit() {
        this.subscription = this.store.select(selectUndrawnValuation).pipe(map((undrawnValuation) => {
            this.isEditable = undrawnValuation.undrawnValuationIsEditable;

            this.persistedFundValuation = undrawnValuation.persistedFundValuation;
            this.persistedScenarios = undrawnValuation.persistedScenarios;
            this.calculatedFundValuation = undrawnValuation.calculatedFundValuation;
            this.calculatedScenarios = undrawnValuation.calculatedScenarios;

            const lowScenarioCalc = this.calculatedScenarios.find(s => s.scenario.code === "LOW");
            const baseScenarioCalc = this.calculatedScenarios.find(s => s.scenario.code === "BASE");
            const highScenarioCalc = this.calculatedScenarios.find(s => s.scenario.code === "HIGH");

            this.undrawnValuationForm.patchValue({
                percentageToBeDrawnCalc: DecimalFormatPipe.transformFractionToPercent(undrawnValuation.calculatedFundValuation.percentageToBeDrawn.fraction ?? undefined),
                undrawnMultipleLOW: lowScenarioCalc?.undrawnMultiple.factor,
                undrawnMultipleBASE: baseScenarioCalc?.undrawnMultiple.factor,
                undrawnMultipleHIGH: highScenarioCalc?.undrawnMultiple.factor
            });

            if (this.isEditable) {
                // Use a setTimeout to refocus on the next Javascript event loop tick,
                // giving Angular a chance to update the DOM first
                setTimeout(() => {
                    if (!!this.nextFocus) {
                        this.renderer.selectRootElement("#" + this.nextFocus).focus();
                    }
                }, 0);
                this.undrawnValuationForm.enable();
            } else {
                this.undrawnValuationForm.disable();
            }
        })).subscribe();
    }

    save() {
        if (this.undrawnValuationForm.valid) {
            // TODO: maybe we shouldn't allways create a subscription on a save action
            //  (And if yes, please check to unsubscribe, without overloading an array)
            this.store.select(selectCalculatedFundValuation).pipe(
                filter(val => val?.status === "LOADED"),
                take(1)
            ).subscribe((calculatedFundValuation) => {
                const scenarios = this.calculatedScenarios;

                if (calculatedFundValuation && scenarios) {
                    this.store.dispatch(UndrawnValuationActions.save({ fundValuation: calculatedFundValuation.data, scenarios }));
                }
            });
        }
    }

    calc(focus: string) {
        const currentIndex = this.controlNamesForFocus.findIndex(control => control === focus);
        this.nextFocus = this.controlNamesForFocus[currentIndex + 1] || this.controlNamesForFocus[0];
        if (this.undrawnValuationForm.valid) {
            const updatedFundValuation = this.fundValuationForCalc();
            const updatedScenarios = this.scenarioForCalc(this.persistedScenarios, this.undrawnValuationForm);
            this.store.dispatch(UndrawnValuationActions.calc({fundValuation: updatedFundValuation, scenarios: updatedScenarios}));
        }
    }

    editMode = () => this.store.dispatch(UndrawnValuationActions.edit());

    cancel = () => this.store.dispatch(UndrawnValuationActions.cancel());

    fundValuationForCalc(): FundValuation {
        const percentageToBeDrawn =
            DecimalFormatPipe.transformPercentToFraction(this.undrawnValuationForm.controls.percentageToBeDrawnCalc.value)
            ?? this.persistedFundValuation.percentageToBeDrawn.fraction;

        return {
            ...this.persistedFundValuation,
            percentageToBeDrawn: {
                ...this.persistedFundValuation.percentageToBeDrawn,
                fraction: percentageToBeDrawn
            }
        };
    }

    scenarioForCalc(
        oldScenarios: FundValuationScenario[],
        form: FormGroup<{
            percentageToBeDrawnCalc: FormControl<number | null>;
            undrawnMultipleLOW: FormControl<number | null>;
            undrawnMultipleBASE: FormControl<number | null>;
            undrawnMultipleHIGH: FormControl<number | null>;
        }>
    ): FundValuationScenario[] {
        const lowScenario = oldScenarios.find(s => s.scenario.code === "LOW");
        const baseScenario = oldScenarios.find(s => s.scenario.code === "BASE");
        const highScenario = oldScenarios.find(s => s.scenario.code === "HIGH");

        const updatedFormValues = form.getRawValue();
        const updatedScenarios: FundValuationScenario[] = [];

        if (!!lowScenario) {
            const updatedLowScenario = this.createScenario(lowScenario, updatedFormValues.undrawnMultipleLOW ?? undefined);
            updatedScenarios.push(updatedLowScenario);
        }
        if (!!baseScenario) {
            const updatedBaseScenario = this.createScenario(baseScenario, updatedFormValues.undrawnMultipleBASE ?? undefined);
            updatedScenarios.push(updatedBaseScenario);
        }
        if (!!highScenario) {
            const updatedHighScenario = this.createScenario(highScenario, updatedFormValues.undrawnMultipleHIGH ?? undefined);
            updatedScenarios.push(updatedHighScenario);
        }
        return updatedScenarios;
    }

    private createScenario(scenario: FundValuationScenario, undrawnMultiple?: number): FundValuationScenario {
        return {
            ...scenario,
            undrawnMultiple: {
                ...scenario.undrawnMultiple,
                factor: undrawnMultiple
            }
        };
    }

    calcTotalUndrawnWithPercentage(totalUndrawn: TraceableMoney, percentageToBeDrawn: TraceablePercent): string {
        const percentage = percentageToBeDrawn.fraction ?? 0;
        const undrawnAmount = totalUndrawn.amount ?? 0;

        const result = undrawnAmount * percentage;
        const resultInMillions = DecimalFormatPipe.transformFromMillionsNum(result) ?? 0;
        return resultInMillions.toFixed(1);
    }

    saveNote(context: string, note: Note): void {
        this.store.dispatch(FundNoteActions.save({context, note}));
    }

    deleteNote(note: Note): void {
        this.store.dispatch(FundNoteActions.delete({note}));
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
