import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {FundListActions, FundValuationActions} from "../../../fund/store/fund.actions";
import {map, Subscription} from "rxjs";
import {selectFunds} from "../../../fund/store/fund/fund.selectors";
import {selectSelectedFundValuationsForFund} from "../../../fund/store/fund-valuation/fund-valuation.selectors";
import {numberNotBiggerThan190000m} from "../../../shared/utils/form-validators";
import {FundValuation} from "../../../fund/models/fund-valuation";
import {DecimalFormatPipe} from "../../../shared/pipes/decimal-format/decimal-format.pipe";
import {EMPTY_MONEY} from "../../../shared/model/traceable";
import {SellerPosition} from "../../models/seller-position";
import {EMPTY_SELLER_POSITION} from "../../store/deal.reducer";
import {SellerPositionActions} from "../../store/deal.actions";
import {selectSellerPositionsWithFundNames} from "../../store/seller-position/seller-position.selectors";
import {selectSelectedDealClosingDate} from "../../store/deal/deal.selectors";
import {DateUtil} from "../../../shared/utils/date-util";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatStepper} from "@angular/material/stepper";
import {CodeTableEnum} from "../../../shared/model/code";

@Component({
    selector: "valumize-deal-funds-manage-dialog",
    templateUrl: "./deal-funds-manage-dialog.component.html",
    styleUrls: ["./deal-funds-manage-dialog.component.scss"]
})
export class DealFundsManageDialogComponent implements OnInit, OnDestroy {

    @Input() dealId?: number;

    subscriptions: Subscription[] = [];

    fundValuationColumns = ["fundName", "id", "reportDate", "closingDate"];
    dealFundsColumns = ["fundName", "id", "reportDate", "closingDate", "sellerCommitment", "remove"];

    fundColumns = ["fundName", "country", "currencyIso", "size", "mainFundSize", "vintageYear", "endYear"];
    fundDataSource: MatTableDataSource<any> = new MatTableDataSource();
    codeTableCountry = CodeTableEnum.SHARED_COUNTRY;
    codeTableCurrency = CodeTableEnum.SHARED_CURRENCY;

    @ViewChild("fundPaging") fundPaging!: MatPaginator;
    @ViewChild("fundSort") fundSort = new MatSort();
    @ViewChild("stepper") stepper!: MatStepper;

    fundValuationsForFund$ = this.store.select(selectSelectedFundValuationsForFund);

    dealClosingDate?: string;
    selectedFundName?: string;
    selectedFundValuation?: FundValuation;
    sellerPositionsForDeal: SellerPosition[] = [];

    sellerPositionForm = this.formBuilder.group({
        sellerCommitment: this.formBuilder.control<number | null>(null, {nonNullable: true, validators: [Validators.required, numberNotBiggerThan190000m()]})
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly formBuilder: FormBuilder,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.store.dispatch(FundListActions.loadfordeal());
    }

    ngOnInit() {
        if (!!this.dealId) {
            this.store.dispatch(SellerPositionActions.loadall({dealId: this.dealId}));
        }

        this.subscriptions.push(this.store.select(selectSellerPositionsWithFundNames).pipe(
            map(sellerPositionsWithFundNames => {
                this.sellerPositionsForDeal = sellerPositionsWithFundNames;
            })
        ).subscribe());

        this.subscriptions.push(this.store.select(selectFunds).pipe(
            map(funds => {
                this.fundDataSource.data = funds;
                this.fundDataSource.paginator = this.fundPaging;
                this.fundDataSource.sort = this.fundSort;
                this.fundDataSource.filterPredicate = (data, filter) => {
                    const transformedFilter = filter.trim().toLowerCase();
                    return data.name.text.toLowerCase().includes(transformedFilter);
                };
                this.fundDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case "fundName":
                            return item.name.text;
                        case "country":
                            return item.country.code;
                        case "currencyIso":
                            return item.currencyIso.code;
                        case "size":
                            return item.size.amount;
                        case "mainFundSize":
                            return item.mainFundSize.amount;
                        case "vintageYear":
                            return item.vintageYear.date;
                        case "endYear":
                            return item.endYear.date;
                        default:
                            return item[property];
                    }
                };
                this.fundDataSource.sort.sort({
                    id: "fundName",
                    start: "asc",
                    disableClear: true
                });
            })
        ).subscribe());

        this.subscriptions.push(this.store.select(selectSelectedDealClosingDate).pipe(map((closingDate) => {
            this.dealClosingDate = closingDate.date;
        })).subscribe());
    }

    save() {
        if (!this.dealId) {
            return;
        }
        let sellerPositionsToSave: SellerPosition[] = [];
        this.sellerPositionsForDeal.forEach(sellerPosition => {
            sellerPositionsToSave = [...sellerPositionsToSave, sellerPosition];
        });

        this.store.dispatch(SellerPositionActions.saveall({dealId: this.dealId, sellerPositions: sellerPositionsToSave}));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.fundDataSource.filter = filterValue.trim().toLowerCase();
    }

    selectFundAndLoadValuations(fundId: number, fundName: string) {
        this.selectedFundName = fundName;
        this.store.dispatch(FundValuationActions.loadall({fundId}));

        this.cdr.detectChanges();
        this.stepper.next();
    }

    selectFundValuation(fundValuation: FundValuation) {
        this.selectedFundValuation = fundValuation;
        this.cdr.detectChanges();
        this.stepper.next();
    }

    addFundValuationToDeal() {
        if (!this.selectedFundValuation?.id) {
            return;
        }
        const sellerPosition = {
            ...EMPTY_SELLER_POSITION,
            fundValuationId: this.selectedFundValuation.id,
            fundName: this.selectedFundName,
            reportDate: this.selectedFundValuation.reportDate,
            closingDate: this.selectedFundValuation.closingDate,
            sellerCommitment: {
                ...EMPTY_MONEY,
                amount: DecimalFormatPipe.transformToMillionsNum(this.sellerPositionForm.getRawValue().sellerCommitment ?? undefined)
            }
        };
        this.sellerPositionsForDeal = [...this.sellerPositionsForDeal, sellerPosition];
        this.selectedFundName = undefined;
        this.selectedFundValuation = undefined;
    }

    removeFundValuationFromDeal(sellerPositionToRemove: SellerPosition) {
        this.sellerPositionsForDeal = this.sellerPositionsForDeal.filter((sellerPosition) => sellerPosition !== sellerPositionToRemove);
    }

    filterFundValuations(fundValuations: FundValuation[]): FundValuation[] {
        const dealClosingDate = DateUtil.fromIsoDate(this.dealClosingDate);

        return fundValuations.filter(fundValuation => {
            const alreadyAdded = this.sellerPositionsForDeal.some(sellerPosition => sellerPosition.fundValuationId === (fundValuation.id ?? -1));
            const fundValuationClosingDate = DateUtil.fromIsoDate(fundValuation.closingDate.date);

            return !!fundValuationClosingDate && !!dealClosingDate && dealClosingDate <= fundValuationClosingDate && !alreadyAdded;
        });
    }

    goBackToList(): void {
        this.selectedFundName = undefined;
        this.clearFilter();
        this.stepper.previous();
    }

    goBackToValuations(): void {
        this.selectedFundValuation = undefined;
        this.stepper.previous();
    }

    resetStepper() {
        this.selectedFundName = undefined;
        this.selectedFundValuation = undefined;
        this.clearFilter();
        this.stepper.reset();
    }

    clearFilter() {
        const input = document.getElementById("search-input") as HTMLInputElement;
        if (input) {
            input.value = "";
        }
        this.applyFilter({ target: { value: "" } } as any);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
