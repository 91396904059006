import {AfterViewChecked, AfterViewInit, Component, OnDestroy} from "@angular/core";
import {Chart, registerables} from "chart.js";
import {Store} from "@ngrx/store";
import {map, Subscription} from "rxjs";
import {selectDealCompanies} from "../../../store/deal/deal.selectors";
import {DateTime} from "luxon";
import {DecimalFormatPipe} from "../../../../shared/pipes/decimal-format/decimal-format.pipe";
import "chartjs-adapter-date-fns";
import {TraceableFormatPipe} from "../../../../shared/pipes/traceable-format/traceable-format.pipe";
import {DealCompanyRecord} from "../../../models/deal-company-record";
import {TraceableMoney, TraceableMultiple} from "../../../../shared/model/traceable";

@Component({
    selector: "valumize-deal-bubble-chart",
    templateUrl: "./deal-bubble-chart.component.html",
    styleUrls: ["./deal-bubble-chart.component.scss"]
})
export class DealBubbleChartComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    dealCompanies$ = this.store.select(selectDealCompanies);

    subscriptions: Subscription[] = [];
    private lowChart: Chart | undefined;
    private baseChart: Chart | undefined;
    private highChart: Chart | undefined;

    constructor(private readonly store: Store, private readonly traceableFormatPipe: TraceableFormatPipe) {
    }

    ngAfterViewInit() {
        Chart.register(...registerables);
    }

    ngAfterViewChecked() {
        const lowCtx = document.getElementById("LowScenarioDealBubbleChart") as HTMLCanvasElement;
        const baseCtx = document.getElementById("BaseScenarioDealBubbleChart") as HTMLCanvasElement;
        const highCtx = document.getElementById("HighScenarioDealBubbleChart") as HTMLCanvasElement;

        if (lowCtx && !this.lowChart) {
            this.setupBubbleChart("Low", lowCtx, chart => this.lowChart = chart);
        }
        if (baseCtx && !this.baseChart) {
            this.setupBubbleChart("Base", baseCtx, chart => this.baseChart = chart);
        }
        if (highCtx && !this.highChart) {
            this.setupBubbleChart("High", highCtx, chart => this.highChart = chart);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.destroyCharts();
    }

    prepareChartData(dealCompanyRecords: DealCompanyRecord[], scenario: "Low" | "Base" | "High") {
        const SCALE_FACTOR = 30;
        const colorArray = [
            "rgba(70, 130, 180, 0.2)", // Steel Blue
            "rgba(46, 139, 87, 0.2)", // Sea Green
            "rgba(255, 140, 0, 0.2)", // Dark Orange
            "rgba(138, 43, 226, 0.2)", // Blue Violet
            "rgba(32, 178, 170, 0.2)", // Light Sea Green
            "rgba(255, 105, 180, 0.2)", // Hot Pink
            "rgba(96, 125, 139, 0.2)", // Blue Grey
            "rgba(255, 205, 86, 0.2)", // Light Yellow
            "rgba(128, 0, 128, 0.2)" // Purple
        ];

        const fundColorMap = new Map<string, string>();
        const datasetsMap = new Map<string, any[]>();
        let colorIndex = 0;

        dealCompanyRecords.forEach(record => {
            const exitDate = record.exitDateAssumption?.date
                ? DateTime.fromISO(record.exitDateAssumption.date)
                : DateTime.now();

            const fundName = record.fundName.text ?? "";
            if (!fundColorMap.has(fundName)) {
                fundColorMap.set(fundName, colorArray[colorIndex % colorArray.length]);
                colorIndex++;
            }

            const backgroundColor = fundColorMap.get(fundName) as string;

            if (!datasetsMap.has(fundName)) {
                datasetsMap.set(fundName, []);
            }

                datasetsMap.get(fundName)?.push({
                    x: exitDate.toMillis(),
                    y: (record[`navMultiple${scenario}` as keyof DealCompanyRecord] as TraceableMultiple).factor ?? 0,
                    r: (DecimalFormatPipe.transformFromMillionsNum((record[`cashflow${scenario}` as keyof DealCompanyRecord] as TraceableMoney).amount) ?? 0) * SCALE_FACTOR,
                    backgroundColor,
                    record
                });
        });

        const dates = Array.from(datasetsMap.values()).flat().map(d => d.x);
        const minDate = Math.min(...dates);
        const maxDate = Math.max(...dates);
        const minDateWithBuffer = DateTime.fromMillis(minDate).minus({months: 3}).toMillis();
        const maxDateWithBuffer = DateTime.fromMillis(maxDate).plus({months: 3}).toMillis();

        const datasets = Array.from(datasetsMap.entries()).map(([fundName, data]) => ({
            label: `${fundName}`,
            data,
            backgroundColor: data[0].backgroundColor,
            borderColor: data[0].backgroundColor.replace("0.2", "1"),
            borderWidth: 1
        }));

        return {datasets, minDate: minDateWithBuffer, maxDate: maxDateWithBuffer};
    }

    private setupBubbleChart(scenario: "Low" | "Base" | "High", canvas: HTMLCanvasElement, setChartInstance: (chart: Chart) => void) {
        this.subscriptions.push(
            this.dealCompanies$.pipe(map(dealCompanies => {
                if (dealCompanies.dealCompanyRecords.length > 0) {

                    if (canvas?.getContext("2d")) {
                        if (scenario === "Low" && this.lowChart) {
                            this.lowChart.destroy();
                        }
                        if (scenario === "Base" && this.baseChart) {
                            this.baseChart.destroy();
                        }
                        if (scenario === "High" && this.highChart) {
                            this.highChart.destroy();
                        }

                        const {datasets, minDate, maxDate} = this.prepareChartData(dealCompanies.dealCompanyRecords, scenario);

                        if (datasets.length > 0) {
                            const newChart = new Chart(canvas, {
                                type: "bubble",
                                data: {
                                    datasets
                                },
                                options: {
                                    scales: {
                                        x: {
                                            type: "time",
                                            time: {
                                                round: "quarter",
                                                unit: "quarter"
                                            },
                                            title: {
                                                display: true,
                                                text: "Exit Date Assumption",
                                            },
                                            min: minDate,
                                            max: maxDate,
                                            ticks: {
                                                source: "auto",
                                                autoSkip: false,
                                                callback: (value) => {
                                                    if (typeof value === "number") {
                                                        const date = DateTime.fromMillis(value);
                                                        return date.endOf("quarter").toFormat("MMM yyyy");
                                                    }
                                                    return value;
                                                }
                                            }
                                        },
                                        y: {
                                            title: {
                                                display: true,
                                                text: "NAV Multiple",
                                            },
                                        },
                                    },
                                    plugins: {
                                        tooltip: {
                                            callbacks: {
                                                label: context => {
                                                    const raw = context.raw as any;
                                                    const record = raw.record as DealCompanyRecord;
                                                    const exitDate = this.traceableFormatPipe.transform(record.exitDateAssumption);
                                                    const navMultiple = this.traceableFormatPipe.transform(
                                                        record[`navMultiple${scenario}` as keyof DealCompanyRecord] as TraceableMultiple);
                                                    const cashflow = this.traceableFormatPipe.transform(record[`cashflow${scenario}` as keyof DealCompanyRecord] as TraceableMoney);
                                                    return [
                                                        `Company: ${record.company.text}`,
                                                        `Fund: ${record.fundName.text}`,
                                                        `Exit Date: ${exitDate}`,
                                                        `NAV Multiple: ${navMultiple}`,
                                                        `Cashflow: ${cashflow}`
                                                    ];
                                                }
                                            }
                                        }
                                    },
                                },
                            });
                            setChartInstance(newChart);
                        }
                    }
                }
            })).subscribe()
        );
    }

    private destroyCharts() {
        if (this.lowChart) {
            this.lowChart.destroy();
            this.lowChart = undefined;
        }
        if (this.baseChart) {
            this.baseChart.destroy();
            this.baseChart = undefined;
        }
        if (this.highChart) {
            this.highChart.destroy();
            this.highChart = undefined;
        }
    }
}
